<div id="reset-password">
    <div class="reset-password-content">
        <div class="row">
            <div class="head_container d-flex justify-content-center align-items-center">
                <label>Reset Password</label>
            </div>
        </div>
        <div class="row px-2" id="change_password">
            <div class="w-100 mb-5">
                <ng-container *ngIf="!isSubmited">
                    <form id="passwordForm" [formGroup]="resetForm" (ngSubmit)="onResetPassword()">
                        <div class="form-group grp">
                            <label for="password1">New password</label>
                            <div class="d-flex align-items-center">
                                <input mat-input [type]="hideNewPassword ? 'password' : 'text'" class="form-control" name="password1" id="password1" formControlName="newPassword" placeholder="*********" autocomplete="off">
                                <mat-icon matSuffix class="icon-hide-position" (click)="hideNewPassword = !hideNewPassword">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </div>
                        </div>
                        <div class="form-group grp">
                            <label for="password2">Confirm password</label>
                            <div class="d-flex align-items-center">
                                <input mat-input [type]="hideConfirmPassword ? 'password' : 'text'" class="form-control" name="password2" id="password2" formControlName="validationPassword" placeholder="*********" autocomplete="off">
                                <mat-icon matSuffix class="icon-hide-position" (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </div>
                            <label class="text-danger" *ngIf="resetForm.value.validationPassword != '' && resetForm.controls['validationPassword'].hasError('NoPassswordMatch')">
                              Password do not match
                            </label>
                        </div>
                        <div class="row grp">
                            <div class="col-sm">
                                <p>Your password must have:</p>
                                <div class="row">
                                    <div class="col-sm">

                                        <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                            resetForm.controls['newPassword'].hasError('minlength') ? 'close' :
                                            'check' }}</i>
                                          Must be at least 8 characters!
                                        </label>
                                        <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                            resetForm.controls['newPassword'].hasError('hasNumber') ? 'close' :
                                            'check' }}</i>
                                          Must contain at least 1 number!
                                        </label>
                                        <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                            resetForm.controls['newPassword'].hasError('hasCapitalCase') ? 'close' :
                                            'check' }}</i>
                                          Must contain at least 1 in Capital Case!
                                        </label>
                                        <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">{{ resetForm.controls['newPassword'].hasError('required') ||
                                            resetForm.controls['newPassword'].hasError('hasSmallCase') ? 'close' :
                                            'check' }}</i>
                                          Must contain at least 1 Letter in Small Case!
                                        </label>
                                        <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">
                                            {{ resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSpecialCharacters') ? 'close' : 'check' }}</i>
                                          Must contain at least 1 Special Character!
                                        </label>
                                        <!-- <label class="col d-flex align-items-center" [ngClass]="resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSpace') ? 'text-danger' : 'text-success'">
                                          <i class="material-icons">
                                            {{ resetForm.controls['newPassword'].hasError('required') || resetForm.controls['newPassword'].hasError('hasSpace') ? 'close' : 'check' }}</i>
                                          Has Space!
                                        </label> -->
                                        <div class="row">
                                            <div class="col-sm">
                                                Note: Spaces are not allowed
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <input type="submit" class="col-sm btn btn-primary btn-load btn-lg btn-reset" data-loading-text="Reseting Password..." value="Reset Password" [disabled]="resetForm?.invalid">
                    </form>
                </ng-container>
                <ng-container *ngIf="isSubmited">
                    <div class="text-center my-5">
                        <h3>{{successMessage}}</h3>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
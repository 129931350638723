import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SignupService } from '../services/signup/signup.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbdPopupmodelDocContent } from '../listmodal/popupmodeldoc/popupmodeldoc.component';
import { optionsObject } from 'src/app/Models/ObjectType';
import { ObjectInformation } from 'src/app/Models/ObjectInformations';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-object-scanned',
  templateUrl: './object-scanned.component.html',
  styleUrls: ['./object-scanned.component.scss']
})
export class ObjectScannedComponent implements OnInit {
  @Output() emitClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  formulaire: FormGroup;
  addObjectForms:FormGroup;
  showPassword = false;
  password: string;
  confirmpassword: string;
  serialNumber: string;
  id_member: any;
  tag: any;
  id_groupe: string;
  id_language: string = localStorage.getItem('languageId');
  id_user: string;
  categorieObject:optionsObject=new optionsObject();
  selectedOption: any;
  objectInfo :ObjectInformation=new ObjectInformation();
  isLoading:boolean=false;
  constructor(private signUpService: SignupService,private modalService:NgbModal, private safeUrl: DomSanitizer) {}

  ngOnInit(): void {
    this.formulaire = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]),
      lastname: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]),
      email: new FormControl('', [Validators.required,  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      code_phone: new FormControl(''),
      mobile: new FormControl('',[Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$")]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern(
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
        ),
      ]),
      confirmpassword:new FormControl('',Validators.required),
    });
    this.addObjectForms = new FormGroup({
      description: new FormControl('', Validators.required),
      categorie: new FormControl('', Validators.required),
    });


   //recuperer liste des object
   this.signUpService.getAllTypeOfObject().subscribe(
    (objectType) => {
      this.categorieObject = objectType;

    },
    (error) => {
      console.log(error);
    }
  );
  }

  getSafeUrl(url: string): SafeUrl{
    return this.safeUrl.bypassSecurityTrustResourceUrl(url);
  }

  open(content,type,text,color) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "myCustomModalClass",

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.texte=text;
    modeldoc.componentInstance.color=color;
    modeldoc.componentInstance.type=type;
    modeldoc.dismissed.subscribe((result)=>{
      modeldoc.close();
      this.emitClose.emit(true);
    })
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
      this.emitClose.emit(true);
    });
  }



  arePasswordsEqual() {
    return this.password === this.confirmpassword;
  }
  get getPassword() {
    return this.formulaire.get('password');
  }
  getDescription(){return this.addObjectForms.get('description');}



  onsubmit() {
    this.isLoading = true;
    if (this.formulaire.valid) {
      this.signUpService.signUp(this.formulaire).subscribe((res) => {
        this.id_groupe = res.id_group;
        this.id_member = res.id_member_md5;
        this.serialNumber=localStorage.getItem("SerialNumber");
        // this.serialNumber = 'JYGNATY';
        this.id_user = res.id_user;
        this.signUpService
        .getScanTagObject(
          this.serialNumber,
          this.id_user,
          this.id_member,
          this.id_language,
          this.id_groupe
        )
        .subscribe((res) => {
          this.tag = res.new_tag[0];
          this.objectInfo.currency=this.tag.currency;
          this.objectInfo.emergency_contact_user=this.tag.emergency_contact_user;
          this.objectInfo.other_info=this.tag.other_info;
          this.objectInfo.tag_info=this.tag.tag_info;
          this.objectInfo.tag_info.tag_label=this.addObjectForms.get('description').value;
          this.objectInfo.tag_info.id_type=this.addObjectForms.get('categorie').value.id;
          this.objectInfo.tag_user_info=this.tag.tag_user_info;
          this.objectInfo.preference_user=this.tag.preference_user;



          if (this.addObjectForms.valid) {
            this.signUpService
              .addObject(
                this.id_groupe,

                this.id_user,
                this.id_language,
                this.serialNumber,
                this.objectInfo,

              )
              .subscribe((res) => {
                this.addObjectForms.reset();
                this.formulaire.reset();
                this.objectInfo = new ObjectInformation();
                this.open("succeslandingpage_object_v1","success","success_registration_title","#04a6bc");
                localStorage.removeItem('landing_page')

                this.isLoading = false;
              },(error)=>{
                this.open("erreurgeneralelandingpage","error","error_popup_text1","#f4c809");
                this.isLoading = false;
              } );}

        },(error)=>{
          this.open("serial_number_already_activated_text_app","error","sn_err1_title","#f4c809");
          this.isLoading = false;
        });



      },(error)=>{
        this.open("login_problem_active_text_app","error","login_problem_active_title_app","#f4c809");
        this.isLoading = false;
      });
    }
  }



}

import { switchMap } from 'rxjs/operators';
import { SharedService } from './../../../services/shared-service/shared.service';
import { SocketService, JoinRoomMessage, LeaveRoomMessage, OtherIncommingMessage } from './../../../services/socket/socket.service';
import {
  Component, ContentChild, ElementRef, EventEmitter,OnDestroy, Inject, Input, OnInit, Output, TemplateRef,
  ViewChild,
  AfterViewInit,} from '@angular/core';
import { BehaviorSubject, Observable, Subject, SubscriptionLike, interval } from 'rxjs';
import {DataService} from '../../../services/chat/data.service';
import {scan} from 'rxjs/internal/operators';
import {ESendBy} from '../../../models/EsendBy';
import { HttpClient} from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Message } from 'src/app/models/message';
import { ChatService } from 'src/app/services/chat/chat.service';
import { ActivatedRoute, Event } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from "@angular/core";
import { ChatMsgemerComponent } from '../chat-msgemer/chat-msgemer.component';
import { Generic, Option, Value } from 'src/app/models/chatbotmessage';
import { Socket } from 'ngx-socket-io';

class EmerencyContact {
  id_user:  string;
  sn:       string;
  user_type:string;
  username: string;
  room:     string;
  type_user:string;
  name:     string;
  zulip_id: string;
}

@Component({
  selector: 'Chat-botemer',
  templateUrl: './chat-windowemer.component.html',
  styleUrls: ['./chat-windowemer.component.scss']
})
export class ChatWindowemerComponent implements OnInit , OnDestroy, AfterViewInit{
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() msgTemplate: TemplateRef<any>;
  public chatview :boolean = false ;
  @Input() inputTemplate: TemplateRef<any>;
  @Input() msg: Subject<any>;
  nummsg: number = 0;
  subscription: SubscriptionLike;
  @Output() countChanged:  EventEmitter<any>= new EventEmitter();
  @ViewChild(ChatMsgemerComponent) child:ChatMsgemerComponent;
  ownerrepley : boolean = false ;
  public collapsedUser:boolean =false ;
  @Output() onMsgReceive = new EventEmitter();
  @ViewChild('msgArea') msgArea: ElementRef;
  @ViewChild('defaultMsgTemplate') defaultMsgTemplate: TemplateRef<any>;
  @ViewChild('defaultInputTemplate') defaultInputTemplate: TemplateRef<any>;
  test:boolean = false ;
  sender :string;
  mesg :Message ;
  container: HTMLElement;
  message :string ;
  target: any =Event;
  sessionid:string;
  idroom:number =-2 ;
   conteur:number=0 ;
 // public currentUser: User;
   data : any ;
   loading:boolean=false ;
   chatvalie :any ;
   windowheight : any ;
  @Input() collapsed:boolean =false ;
  private ngUnsubscribe = new Subject();
  allMessages: Observable<Message[]>;
  nbMessages: number = 0;
  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;

  emergencyContact: EmerencyContact = new EmerencyContact();
  socketMessage: any;

  @Input() isMedicaleReleased: boolean;
  scrollContainer: any;
  collapseIfScrolled: boolean = true;
  @Output() scrollEmergencyChange = new EventEmitter<boolean>();
  interval: any;
  nbJoin: number = 0;
  listEventRoom: string[] = []

  constructor(  private activatedRoute :ActivatedRoute, @Inject(DOCUMENT) private document: Document,private chatService :ChatService, public dataService: DataService,private http: HttpClient , private authService : AuthService,
  private socketService: SocketService, private sharedService: SharedService, private datePipe: DatePipe, private socket: Socket) {
    this.onResize();
    this.dataService.listenMessages().subscribe((m: any) => {
      this.loading=false ;
    });

    this.interval = interval(3000).subscribe(()=>{
      const joinMessage: JoinRoomMessage = {
        id_user: this.emergencyContact.id_user,
        user_type: this.emergencyContact.type_user,
        username: this.emergencyContact.name,
        room: this.emergencyContact.sn,
      };
      var socket = this.socket.connect();
      if(!socket.connected && this.nbJoin == 1) {
        this.nbJoin++;
        //this.rejoinRoom();
      }

      this.socketService.isInRoom(joinMessage)
    });

    this.interval = interval(10000).subscribe(()=>{
      //this.verifyDiscussionMessage();
    });

    this.socketService.onCheckUserInRoom().subscribe((room: string) => {
      console.log(JSON.parse(room));
      const data = JSON.parse(room)
      if(data.message == 'user not in the room' && data.room == this.emergencyContact.sn.toUpperCase()){
        this.emergencyContact = JSON.parse(localStorage.getItem('emergency_contact'));
        this.rejoinRoom();
      }

    })


      /**
     * if join room error recall to join
     */
    this.socketService.onErrorEvent().subscribe((data: any) => {
      console.log(data  as OtherIncommingMessage );
      let dataJson = JSON.parse(data);
      if(dataJson)
      if(dataJson?.message?.includes('You are not in the room')  || dataJson?.message?.includes('Join request not valid') || dataJson?.message?.includes('User Info is not valid') || dataJson?.message?.includes('Message request is not valid')){
        this.rejoinRoom();
      }
    });


    this.socketService.onRoomEvent().subscribe((data) => {
      //this.socketService.eventRoom(JSON.parse(data as string));
      if(this.listEventRoom.includes((JSON.parse(data as string)).message))
      this.listEventRoom.push((JSON.parse(data as string)).message)
      else{
        this.listEventRoom.push((JSON.parse(data as string)).message)
        this.getMessageEvent(JSON.parse(data as string))
      }
      //console.log(JSON.parse(data as string));
    });

   /*  this.interval = interval(3000).subscribe(()=>{
      this.sharedService.detectMessageSent.next(true)
      this.emergencyContact = JSON.parse(localStorage.getItem('emergency_contact'));
      this.verifyDiscussionMessage();
    }); */

    this.socketService.onMessageEvent().subscribe((data) => {
      const messageContent = JSON.parse(data as string)
      if(messageContent?.user?.user_type !== 'emergency_contact'){
        this.getMessageEvent(messageContent)
      }


    });
  }

  rejoinRoom(): void {
    const joinMessage: JoinRoomMessage = {
      id_user: this.emergencyContact.id_user,
      user_type: this.emergencyContact.type_user,
      username: this.emergencyContact.name,
      room: this.emergencyContact.sn,
    };

    this.socketService.joinRoom(joinMessage);
   this.verifyDiscussionMessage();
  }

  verifyDiscussionMessage(): void{
    if(this.emergencyContact?.id_user && this.emergencyContact.sn){
    const requestListMessages = this.dataService.GetAllMessage(this.emergencyContact.id_user, this.emergencyContact.sn, this.emergencyContact.type_user).toPromise();
    requestListMessages.then((response: any)=>{
      if((response?.dicussion?.length-1) > this.dataService.numberDiscussionMessages){
        for (let index = (this.dataService.numberDiscussionMessages+1); index < response?.dicussion?.length; index++) {
          if(!this.dataService.searchMessageOnConversation(response?.dicussion[index].content, this.dataService.allConversation)){
            let message = response?.dicussion[index];
            /* let saveListMessages: any[] = JSON.parse(localStorage.getItem('lastMessageSent')) ? JSON.parse(localStorage.getItem('lastMessageSent')) : []
              if(message?.sender_full_name == 'Found.me')
              if(saveListMessages.includes((message?.content))) {
                continue;
              }else{
                saveListMessages.push((message?.content));
                localStorage.setItem('lastMessageSent', JSON.stringify(saveListMessages))
              } */
              if(this.dataService.searchMessageOnConversation(message?.content, this.dataService.lastMessageSent)) {
                continue;
              }
              this.pushMessageToConversation(response, index);
              this.beep()
          }
        }
      }
    });
  }
  }

  pushMessageToConversation(response: any,index: number): void{
    let message = response?.dicussion[index];
    if ( message.sender_email!== "welcome-bot@zulip.com")
      {
      let messagedata = new Generic();
      let sender;
      let userMessage: Message;
      let botnum = 0;
      messagedata.typing=false;
      messagedata.text=message.content.replace('<p>','').replace('</p>','')
      messagedata.title=message.content.replace('<p>','').replace('</p>','')
      messagedata.response_type = 'text';
      var theDate = new Date(message.timestamp * 1000);
      let dateString = this.datePipe.transform(theDate, 'hh:mm a');
        if ( message.sender_full_name==="Found.me" || (message.sender_full_name==='Finder' && this.dataService.chaturl))
        {
          if(message.sender_full_name==="Found.me")
          sender= ESendBy.bot ;
          else if (message.sender_full_name==="Finder")
          sender= ESendBy.finder ;

            if ( message.content.includes("[photo]:"))
            {
            userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null ,  botnum,dateString);

            }
            else if ( message.content.includes("[url]:")  )
            userMessage = new Message(null, sender,  message.content.replace("[url]:","") ,null, message.content.replace("[url]:",""),null,null, botnum,dateString);
              else if ( message.content.includes("[video]:")  )
              {
              message.content = message.content.replace('[video]: ','')
            userMessage = new Message(null, sender,null,null, null,null, message.content, botnum,dateString);

              }
                else if ( message.content.includes("[pincode]:"))
            {
              let serial = localStorage.getItem("SerialNumber")
              var pincode =message.content.replace("[pincode]:","")
              localStorage.setItem("pincode",pincode)
                    this.authService.Getemergency(serial,pincode)
                    .subscribe((data :any)=>{

              });
            }
            else if  ( message.content.includes("[position]:"))
            {
              //if(this.isPositionAutorised){
                userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null ,  botnum,dateString);
              //}
            }
            else if  ( message.content.includes("[title]"))
            {
              messagedata =new Generic;
              let substrings = message.content.split('[option]');
              messagedata.title = substrings[0].replace('[title]','').replace('<p>','').replace('</p>','');
              messagedata.response_type="option";
              messagedata.options = [];

              for ( let j=1 ; j<substrings?.length ;j++)
              {
              let option = new Option ();
              let substr = substrings[j].split('[label]');
              option.value = new Value ;
              option.value.input = new Input ;
              option.value.input.text =substr[0].replace('<p>','').replace('</p>','')
              option.label =substr[1].replace('<p>','').replace('</p>','')
              messagedata.options.push (option);

              }

              userMessage = new Message(message.content, ESendBy.bot, null,messagedata, null,null, null, botnum ,dateString );

            }
            else if  ( message.content.includes("[link]:"))
            userMessage= new Message(null, message.sender_full_name, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);

            else if(message.content.includes("[option]")){
              let content =  message.content;

              let listSplit: any[]=[];
              let options: Option[]=[];


              content = content.replace('<p>', '')
              content = content.replace('</p>', '')
              listSplit = content.split('[option]')
              for (let index = 0; index < listSplit?.length; index++) {
                if(listSplit[index].includes('[label]')){
                  let option = new Option();
                  let newValue = new Value();
                  let newInput = new Input();
                  let label = '';
                  let value = '';

                  label = listSplit[index].substring(0, listSplit[index].indexOf('[label]'))
                  value = listSplit[index].substring(listSplit[index].indexOf('[label]')+7)

                  option.label = value;

                  newInput.text = label;

                  newValue.input = newInput;

                  option.value = newValue;

                  options.push(option);
                }
              }
              let newGeneric: Generic = new Generic();
              newGeneric.response_type = 'option';
              newGeneric.title = listSplit[0];
              newGeneric.options = options;

              userMessage= new Message(null, sender, null ,newGeneric, null ,null,null, botnum,dateString);
            }
            else{
              if( !message.content.includes('cmd_open_medical_informations')){
                userMessage= new Message(messagedata.text,sender, null, messagedata, null,null, null ,  botnum,dateString);
                }
            }

          this.dataService.update(userMessage);
          botnum++;
      }
        else if ( message.sender_full_name===('Finder') && !this.dataService.chaturl)
      {
        botnum=0;
        sender= ESendBy.user  ;

        if ( message.content.includes("[photo]:")){
          userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
          this.dataService.update(userMessage)
        }
        else if ( message.content.includes("[pincode]:"))
        {
          var pincode = message.content.replace("[pincode]:","")
          let serial = localStorage.getItem("SerialNumber")
          this.authService.Getemergency(serial,pincode)
                .subscribe((data :any)=>{

          });
        }
        else if ( message.content.includes("[newdiscussion]:"))
        {
          var pincode = message.content.replace("[pincode]:","")
          let serial = localStorage.getItem("SerialNumber")
          this.authService.Getemergency(serial,pincode)
                .subscribe((data :any)=>{

          });
        }
        else if ( message.content.includes("[url]:")){
          userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
          this.dataService.update(userMessage)
        }
        else if ( message.content.includes("[video]:"))
        userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);
        else if  ( message.content.includes("[position]:"))
        {
          //if (this.isPositionAutorised) {
            userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null , null,dateString);
          //}
        }
        else if  ( message.content.includes("[link]:")){
          userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);
          this.dataService.update(userMessage);
        }

        else
        userMessage= new Message(messagedata.text,sender, null, null, null,null, null , null,dateString);

        if(userMessage.content || userMessage.data || userMessage.location)
        this.dataService.update(userMessage);


        }

        else
        {

        botnum=0;
        if(message.sender_email.includes("@emer") && this.emergencyContact.zulip_id == message.recipient_id?.toString())
        sender= 'user';
        else
        sender= message.sender_full_name;

        /* if(saveListMessages.includes((message?.content) && !message?.content.includes('[position]:'))) {
          index++;
          continue;
        }else{
          saveListMessages.push((message?.content));
          localStorage.setItem('lastMessageSent', JSON.stringify(saveListMessages))
        } */

        let typeUser: string ='';
        if(message.sender_email.includes("@emer")){
          typeUser = "emergency_contact";
        }
        else if(message.sender_email.includes("@user")){
          typeUser = "owner";
        } else if(message.sender_email.includes("@finder")){
          typeUser = "finder";
        }

        if ( message.content.includes("[photo]:")){
          userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString, null, null, null, typeUser);
          this.dataService.update(userMessage);
        }
        else if ( message.content.includes("[url]:")){
          userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString, null, null, null,typeUser);
          this.dataService.update(userMessage);
        }
        else if  ( message.content.includes("[position]:"))
        {
          //if (this.isPositionAutorised ) {
            userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null , null,dateString, null, null, null,typeUser);
          //}
        }
        else if ( message.content.includes("[video]:"))
        userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString, null, null, null,typeUser);

        else if  ( message.content.includes("[link]:")){
          userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString, null, null, null,typeUser);
          this.dataService.update(userMessage);
        }
        else{
          if( !message.content.includes('cmd_open_medical_informations')){
            userMessage = new Message(messagedata.text,sender, null, null, null,null, null ,  null,dateString , null, null, null,typeUser);
          }
        }
        if(userMessage.content || userMessage.data || userMessage.location)
        this.dataService.update(userMessage);
        }

      }
  }

  getMessageEvent(socketMessage){
    //console.log(socketMessage)
    if(socketMessage?.message != null){
      //if(socketMessage?.user?.user_type == 'finder' || socketMessage?.user?.user_type == 'chatbot' || socketMessage?.user?.user_type == 'users'){
        this.Realtimeevent(socketMessage)
     // }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.windowheight =window.screen.height -100;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      localStorage.setItem("ischatUrl",'ischatUrl');
      this.dataService.chaturl = true;
      this.loading = true;
      this.dataService.chatUrlService(params.get('idParam'))
      .subscribe((response)=>{
        this.emergencyContact = response;
          const joinMessage: JoinRoomMessage = {
            id_user: this.emergencyContact.id_user,
            user_type: this.emergencyContact.type_user,
            username: this.emergencyContact.name,
            room: this.emergencyContact.sn,
          };
          this.socketService.joinRoom(joinMessage)
        localStorage.setItem("emergency_contact",JSON.stringify(response));
        this.sharedService.discussionChange.next(true);
        localStorage.setItem("is_medical",response.is_medical);
        this.dataService.getmessages(this.emergencyContact?.id_user, this.emergencyContact?.sn, this.emergencyContact?.type_user);
        this.chatview = this.dataService.chatview;
        this.allMessages = null;
        this.loading = false;

        this.msgTemplate = this.msgTemplate ? this.msgTemplate : this.defaultMsgTemplate;
        this.inputTemplate = this.inputTemplate ? this.inputTemplate : this.defaultInputTemplate;
        this.allMessages = this.dataService.conversation.asObservable()
        .pipe(
          scan((acc, val) => {
            this.nbMessages = acc?.length
            setTimeout(() => {
              this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
            });

            if (ESendBy.bot === val[0]?.sendBy && !this.dataService.testbot) {
              this.collapsed=this.dataService.collapsedUser;
              if ( val[0]?.content != "undefined")
              this.onMsgReceive.emit(val[0]?.content);
            }
            return acc.concat(val);
          }))
      },(error)=>{
        this.loading = false;
      })
    });
  }


  refreshDiscussionEmergency(): void{
    this.dataService.getmessages(this.emergencyContact?.id_user, this.emergencyContact?.sn, this.emergencyContact?.type_user);
    this.chatview = this.dataService.chatview;
//    this.allMessages = null;
    this.loading = false;

    this.msgTemplate = this.msgTemplate ? this.msgTemplate : this.defaultMsgTemplate;
    this.inputTemplate = this.inputTemplate ? this.inputTemplate : this.defaultInputTemplate;
    this.allMessages = this.dataService.conversation.asObservable()
    .pipe(
      scan((acc, val) => {
        this.nbMessages = acc?.length
        setTimeout(() => {
          this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
        });

        if (ESendBy.bot === val[0]?.sendBy && !this.dataService.testbot) {
          this.collapsed=this.dataService.collapsedUser;
          if ( val[0]?.content != "undefined")
          this.onMsgReceive.emit(val[0]?.content);
        }
        return acc.concat(val);
      }))
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.msgArea.nativeElement.scrollTop = window.innerHeight ;
    let a = this.document.getElementById('scrollrow');
        if(a)
        {
          a.scrollTop = a.scrollHeight;
        }

  }

  Realtimeevent(messageEvent){
    this.dataService.chatbegin=true;
    var image ;
    if(messageEvent?.message.includes(':[bot]')){
      messageEvent.message = messageEvent?.message?.replace(':[bot]', '');
      this.sender = "bot";
    }else{
      this.sender = messageEvent?.user?.username;
    }
    //this.sender = messageEvent?.user?.username;

        if (messageEvent != null)
        {
            if ( messageEvent?.message?.includes("[photo]:") )
            {
                    this.message= null;
                    this.dataService.pushcount( 1);
                    image =  messageEvent?.message?.replace("[photo]:","") ;
                    this.target.value = image;
                    this.target.type = "image";

            }
            else if ( messageEvent?.message?.includes("[url]:") )
                {
                    this.message= null;
                    this.nummsg = this.nummsg+1;
                    this.dataService.pushcount( 1);
                    image =  messageEvent?.message?.replace("[url]:","")  ;
                    this.target.value = image;
                    this.target.type ='url';
            }
            else if ( messageEvent?.message?.includes("[link]:") )
            {
                this.message= null;
                this.nummsg = this.nummsg+1;
                this.dataService.pushcount(1);
                image =  messageEvent?.message?.replace("[link]:","") ;
                this.target.value = image;
                this.target.type ='url';


            }
            else if ( messageEvent?.message?.includes("[position]:") )
            {
                this.message= null;
                image =  messageEvent?.message?.replace("[position]:","") ;
                this.target.value = image;
                this.target.type ='location';
                this.nummsg = this.nummsg+1;
                this.dataService.pushcount(1);

            }

            else
            {
              if(messageEvent.message == "share_localisation") return;
                image= null ;
                this.nummsg = this.nummsg+1;
                this.dataService.pushcount(1);
                this.countChanged.emit(1);
                this.target.value = messageEvent.message;
                this.target.type = 'text'


            }

            if(!messageEvent.message.includes('chatbot_script_user_join'))
            this.beep()
            if ( this.target != null && this.sender != "pincode")
            this.dataService.converseOwner(this.target,  this.sender );
            this.dataService.testbot = false ;
            /* if (this.dataService.ownerrepley == false)
            {
              this.dataService.ownerrepley=true;
              this.dataService.chatfirst("/Send_user_Response" ,"owner_did_reply",1)
              this.dataService.collapsedUser=false;
              localStorage.setItem('isOwnerReply',"true")
            } */
        }
    }

    /**
       * Helper function to emit a beep sound in the browser using the Web Audio API.
       *
       * @param {number} duration - The duration of the beep sound in milliseconds.
       * @param {number} frequency - The frequency of the beep sound.
       * @param {number} volume - The volume of the beep sound.
       *
       * @returns {Promise} - A promise that resolves when the beep sound is finished.
       */
    beep(duration?, frequency?, volume?){
      const myAudioContext = new AudioContext();
      return new Promise((resolve, reject) => {
          // Set default duration if not provided
          duration = duration || 200;
          frequency = frequency || 440;
          volume = volume || 100;

          try{
              let oscillatorNode = myAudioContext.createOscillator();
              let gainNode = myAudioContext.createGain();
              oscillatorNode.connect(gainNode);

              // Set the oscillator frequency in hertz
              oscillatorNode.frequency.value = frequency;

              // Set the type of oscillator
              oscillatorNode.type= "sine";
              gainNode.connect(myAudioContext.destination);

              // Set the gain to the volume
              gainNode.gain.value = volume * 0.01;

              // Start audio with the desired duration
              oscillatorNode.start(myAudioContext.currentTime);
              oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

              // Resolve the promise when the sound is finished
              oscillatorNode.onended = () => {
              };
          }catch(error){
              reject(error);
          }
      });
    }

   childFunc(num:number){
     this.countChanged.emit(num);

   }

  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }

  ngOnDestroy(){
    this.dataService.conversation=new BehaviorSubject<Message[]>([]); ;
    if(this.subscription)
    this.subscription?.unsubscribe();
    if(this.ngUnsubscribe){
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }

    const leaveRoom: LeaveRoomMessage = {
      'room': this.emergencyContact.sn
    }
    //this.socketService.leaveRoom(leaveRoom)
    localStorage.setItem('isOwnerReply',"false")
    localStorage.removeItem("emergency_contact");
  }

  @HostListener('window:hashchange', ['$event'])
  onHashChange(event) {

    if (this.subscription) {
      this.subscription?.unsubscribe();
      }
  }

   @HostListener('window:beforeunload', ['$event'])
    canLeavePage($event: any): Observable<void> {

      return null;
    }

  @HostListener('window:beforeunload')
  unsubscribemessage()
  {

    if (this.subscription) {
    this.subscription?.unsubscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {

    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    return null ;

  }


  lastScrollTopValue: number = 0;
  onScroll(event){
    this.scrollContainer = this.scrollFrame.nativeElement;

    let pos = this.scrollContainer?.scrollTop  + this.scrollContainer?.offsetHeight >= this.scrollContainer?.offsetHeight + (this.lastScrollTopValue);
    if(pos)
    this.lastScrollTopValue += 1.3
    else
    this.lastScrollTopValue -= 1.3
    this.collapseIfScrolled = pos;
    this.scrollEmergencyChange.emit(pos);
   }
}

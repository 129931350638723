import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
const ROOM_EVENT = 'room_event';
const ERROR_EVENT = 'error_event';
const SERVER_EVENT = 'server_event';
const ROOM_USERS_EVENT = 'room_users_event';
const MESSAGE_EVENT = 'message_event';
const OUTGOINGMESSAGE_EVENT = 'sendMessage';
const JOIN_EVENT = 'join';
const LEAVE_EVENT = 'leave';
const CHECK_USER_IN_ROOM = 'checkUserInRoom';
const IS_USER_IN_ROOM = 'check_user_presence_event';

export const eventTypes = {
  ROOM_EVENT,
  ROOM_USERS_EVENT,
  ERROR_EVENT,
  SERVER_EVENT,
  MESSAGE_EVENT,
  OUTGOINGMESSAGE_EVENT,
  JOIN_EVENT,
  LEAVE_EVENT,
  IS_USER_IN_ROOM
};

export type OutGoingMessage = {
  room: string;
  message: string;
};

export type User = {
  sid: number;
  id_user: string;
  user_type: string;
  username: string;
};

export type IncomingMessage = {
  user: User;
  message: string;
  timestamp: string;
  room: string;
};

export type OtherIncommingMessage = {
  data: string;
};

export type RoomMessage = {
  user: User;
  message: string[];
  timestamp: string;
  room: string;
};

export type UserType = 'users' | 'emergency_contact' | 'chatbot' | 'finder';

export type JoinRoomMessage = {
  id_user: string;
  user_type: string;
  username: string;
  room: string;
};

export type LeaveRoomMessage = {
  room: string;
};

@Injectable({
  providedIn: 'root',
})
export class SocketService {

  /**
   * Message events
   */
  onChangeMessage = new BehaviorSubject<string>(null);
  onChangeMessageEmergency = new BehaviorSubject<string>(null);

  /**
   * Left room
   */
  onIsLeftRoom = new BehaviorSubject<string>(null);

  /**
   * Join room
   */
  onIsJoinRoom = new BehaviorSubject<string>(null);

  constructor(private socket: Socket) {}

  onRoomEvent() {
    return this.socket.fromEvent(ROOM_EVENT);
  }

  onErrorEvent() {
    return this.socket.fromEvent(ERROR_EVENT);
  }

  onServerEvent() {
    return this.socket.fromEvent(SERVER_EVENT);
  }

  onMessageEvent() {
    return this.socket.fromEvent(MESSAGE_EVENT);
  }

  onRoomUsersEvent() {
    return this.socket.fromEvent(ROOM_USERS_EVENT);
  }

  onCheckUserInRoom() {
    return this.socket.fromEvent(IS_USER_IN_ROOM);
  }

  sendMessage(message: OutGoingMessage) {
    if(message.message != undefined)
    this.socket.emit(OUTGOINGMESSAGE_EVENT, message);
  }

  joinRoom(joinMessage: JoinRoomMessage) {
    this.socket.emit(JOIN_EVENT, joinMessage);
  }

  checkRoom(joinMessage: JoinRoomMessage) {
    this.socket.emit(JOIN_EVENT, joinMessage);
  }

  leaveRoom(leaveMessage: LeaveRoomMessage) {
    this.socket.emit(LEAVE_EVENT, leaveMessage);
  }

  isInRoom(room: JoinRoomMessage) {
    this.socket.emit(CHECK_USER_IN_ROOM, room);
  }

  newMessageSent(message): void {
    this.onChangeMessage.next(message);
  }

  onChangeMessageEvent(): Observable<any> {
      return this.onChangeMessage.asObservable();
  }

  newMessageEmergencySent(message): void {
    this.onChangeMessageEmergency.next(message);
  }

  onChangeMessageEmergencyEvent(): Observable<any> {
      return this.onChangeMessageEmergency.asObservable();
  }

  eventRoom(message): void {
    this.onIsLeftRoom.next(message);
  }

  onEventRoom(): Observable<any> {
      return this.onIsLeftRoom.asObservable();
  }
}


export class MedicaleObject{
    icon:string;
    id:number;
    id_app:string;
    type_label:string;
}
export class optionMedicale{
    object_types:MedicaleObject[]=[];
}

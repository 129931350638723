import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-more-informations',
  templateUrl: './more-informations.component.html',
  styleUrls: ['./more-informations.component.scss']
})
export class MoreInformationsComponent implements OnInit {
  srcvideo : SafeUrl ;
  constructor(private sanitizer: DomSanitizer) { }
  convertEmbededVideo ( video)
  {
    let source: string = video.replace('https://www.youtube.com/watch?v=' , '')
    if(source.includes('&'))
    source = source.replace(source.substring(source.indexOf('&')),'')
    this.srcvideo =this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+source) ;
  }
  ngOnInit(): void {
    this.convertEmbededVideo("https://www.youtube.com/watch?v=o_pfmPXg0MI&t=26s")
  }

  getSafeUrl(url: string): SafeUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }


}

<div id="contact-info">
    <div class="border-form">
        <h3>Thank you for providing your contact information. the owner will reach out to you shortly.</h3>
        <div class="row mt-md-4 justify-content-center">


        </div>
        <form class="w-100" [formGroup]="contactInfo" (ngSubmit)="navigate()">
            <div class="row container-bold">
                <div class="col-md-12 col-sm-12 form-group pt-1 " style="text-align: start;">
                    <span for="inputEmail p-0"><strong class="p-0">First name</strong>
                  </span>
                    <input name="firstname" type="text" matInput class="form-control style-form-input" aria-describedby="Firstname" placeholder="First name" formControlName="firstName">
                </div>
                <div class="w-100 mt-2">
                    <span for="inputEmail p-0"><strong class="p-0">Last name</strong></span>
                    <input name="lastname" type="text" matInput class="form-control style-form-input" aria-describedby="Lastname" placeholder="Last name" formControlName="lastName">
                </div>
                <div class="w-100 mt-2">
                    <span for="inputEmail p-0"><strong class="p-0">{{'editprofil_general_subtitle_email' | translate}}</strong></span>
                    <input name="email" type="email" matInput class="form-control style-form-input" aria-describedby="email" [placeholder]="'placeholder_email' | translate" formControlName="email">
                    <div *ngIf="(getEmail.invalid && getEmail.touched) || getEmail.dirty">
                        <!--question mark(?) is a safe navigation operator-->
                        <small *ngIf="getEmail.errors?.pattern" class="text-danger">{{'chatbot_script_medical_validemail' | translate}} </small>
                    </div>
                </div>
                <div class="w-100 mt-2">
                    <span for="inputEmail p-0"><strong class="p-0">Phone</strong>
                  </span>
                    <input name="phone" type="text" matInput class="form-control style-form-input" aria-describedby="PhoneNumber" placeholder="Phone number" formControlName="phone">
                </div>
                <div class="col-md-12 col-sm-12 form-group pt-2" style="text-align: start;">
                    <textarea name="Message" type="text" class="form-control style-form-input" matInput rows="2" placeholder="Note" formControlName="note"> </textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm continue">
                    <button type="submit" class="btn btn-lg btn-block" [disabled]="!contactInfo.valid || loading || (!contactInfo.value.email && !contactInfo.value.firstName && !contactInfo.value.lastName && !contactInfo.value.phone && !contactInfo.value.note)">
                      <div class="spinner-border text-light" role="status" *ngIf="loading">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <span>{{'editprofil_label_send' | translate}}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white closebut" aria-hidden="true">×</span>
        </button>
        <div class="modal-body">
            <img *ngIf="allowSharePicture  == '1' && image; else pictureNotAllowed" [src]="image" class="rounded-circle modelimg img-fluid" >
            <ng-template #pictureNotAllowed>
              <img src="/assets/img/no_pic.png" class="rounded-circle modelimg img-fluid" >
            </ng-template>
        </div>
        <div class="modal-footer">
          <div  class="col">
            <div class="row" style="justify-content: center;">
               <p class="card-text-label" *ngIf="allowShareName == '1' && name">{{name}}</p>
            </div>
            <div class="row modeltype"  *ngIf="allowShareEmail == '1' && email">
              <a href="mailto:{{email?.trim()}}" [title]="email" target="_blank">
                  <div class="tel-container email-conatiner mb-2">

                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="15px" viewBox="0 0 20 15" version="1.1">
                        <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;" d="M 18.570312 0 L 1.429688 0 C 1.050781 0 0.6875 0.152344 0.417969 0.429688 C 0.152344 0.703125 0 1.074219 0 1.460938 L 0 13.148438 C 0 13.535156 0.152344 13.910156 0.417969 14.183594 C 0.6875 14.457031 1.050781 14.609375 1.429688 14.609375 L 18.570312 14.609375 C 18.949219 14.609375 19.3125 14.457031 19.582031 14.183594 C 19.847656 13.910156 20 13.535156 20 13.148438 L 20 1.460938 C 20 1.074219 19.847656 0.703125 19.582031 0.429688 C 19.3125 0.152344 18.949219 0 18.570312 0 Z M 17 1.460938 L 10 6.414062 L 3 1.460938 Z M 1.429688 13.148438 L 1.429688 2.125 L 9.59375 7.902344 C 9.710938 7.988281 9.855469 8.035156 10 8.035156 C 10.144531 8.035156 10.289062 7.988281 10.40625 7.902344 L 18.570312 2.125 L 18.570312 13.148438 Z M 1.429688 13.148438 "/>
                        </g>
                      </svg>
                    </span>
                    <span class="tel-size text-white"> {{expandEmail(email)}}</span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="19px" viewBox="0 0 12 18" version="1.1">
                      <g id="surface1">
                      <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 2.000651 2.000434 L 11.999349 11 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                      <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 11.999349 11 L 2.000651 19.999566 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                      </g>
                      </svg>
                    </span>
                  </div>
                </a>
                  <!-- <p > {{email}}</p> -->
            </div>
            <div class="row modeltype mt-1" *ngIf="allowSharePhone == '1' && tel">
            <!--  <a href="https://wa.me/{{tel?.trim()}}"> -->
              <a href="tel://{{tel?.trim()}}" target="_blank">
                <div class="tel-container">

                  <span>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.55822 7.03642L5.5088 7.08585L5.4676 7.14231C5.32307 7.34037 5.18527 7.62292 5.18196 7.97478L5.18085 8.09345L5.21053 8.20835C5.69522 10.0845 7.12281 11.7244 8.28011 12.7862C8.43787 12.9309 8.61191 13.0978 8.79702 13.2753C9.19618 13.6581 9.64686 14.0903 10.0971 14.4564C10.7756 15.0083 11.5899 15.534 12.5611 15.7451C12.7096 15.7843 12.905 15.8194 13.1177 15.8099C13.3423 15.7999 13.6732 15.7346 13.9535 15.4741L13.9686 15.4601L13.983 15.4454L15.1962 14.2098C15.3008 14.1399 15.4181 14.0922 15.5215 14.074C15.6383 14.0535 15.6863 14.0773 15.6934 14.0815L15.903 14.2031H15.9207L19.993 16.607C20.0399 16.6378 20.0646 16.6668 20.0776 16.6868C20.0916 16.7082 20.0972 16.7268 20.0991 16.7416C20.1025 16.7676 20.0969 16.7998 20.0659 16.8335L17.1146 19.7615L17.1093 19.7668L17.1041 19.7721C16.8934 19.9881 16.5861 20.0972 16.1788 20.0999C13.9546 20.0288 11.7847 18.931 9.88788 17.7004C6.78773 15.4421 3.99796 12.6802 2.25646 9.39073C1.54062 7.90789 0.838545 6.26438 0.904172 4.91202L0.904979 4.8954L0.905171 4.87876C0.910734 4.39563 1.04246 4.08883 1.21461 3.93126L1.22929 3.91783L1.24336 3.90376L4.17322 0.973879C4.22274 0.934454 4.2579 0.915474 4.27852 0.90656C4.30165 0.923605 4.34354 0.961561 4.39508 1.03587L6.74251 5.48765C6.80885 5.64279 6.77815 5.81172 6.64464 5.95L5.55822 7.03642Z" stroke="white" stroke-width="1.8"/>
                    </svg>
                  </span>
                  <span class="tel-size text-white"> {{tel | phone}}</span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="19px" viewBox="0 0 12 18" version="1.1">
                    <g id="surface1">
                    <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 2.000651 2.000434 L 11.999349 11 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                    <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 11.999349 11 L 2.000651 19.999566 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                    </g>
                    </svg>
                  </span>
                </div>
              </a>
            </div>
            <div class="row modeltype mt-1">
                <button type="button"  class="skip-button button my-3" (click)="activeModal.dismiss('Cross click')">
                  <span>  {{'finder_label_buttonclose' | translate}}</span>
              </button>
              </div>
          </div>
        </div>
<div>
    <div class="text-center">
        <div class="info-header">
            <img src="/assets/img/Groupe6520.png">

        </div>
        <div class="info-content">
            <div class="custom-padding-info">
                <img class="img-info" src="/assets/img/cancel.png">
            </div>
            <div>
                <div class="marginlist">
                    <h2 *ngIf="code==='INTERNAL_ERROR'">{{'error_registration_server_title' | translate}} {{name}} !</h2>
                    <h2 *ngIf="code==='ALREADYACTIVE'">{{'error_registration_server_title' | translate}} {{name}} !</h2>
                    <h2 *ngIf="code==='INVALIDLINK'">{{'error_registration_userexist_title' | translate}} {{name}} !</h2>
                </div>
                <div class="mt-4 mb-3 offset-lg-3 col-lg-6 offset-md-2 col-md-8 offset-sm-1 col-sm-10 text-left">
                    <p *ngIf="code==='INTERNAL_ERROR'" class="text-center">{{'error_registration_server_subtitle' | translate}}</p><br>
                    <p *ngIf="code==='INTERNAL_ERROR'" class="text-center">{{'error_registration_server_text' | translate}}</p>

                    <p *ngIf="code==='ALREADYACTIVE'" class="text-center">{{'error_registration_active_subtitle' | translate}}</p><br>
                    <p *ngIf="code==='ALREADYACTIVE'" class="text-center">{{'success_registration_text_login' | translate}}</p>

                    <p *ngIf="code==='INVALIDLINK'" class="text-center">{{'error_registration_userexist_subtitle' | translate}}</p><br>
                    <p *ngIf="code==='INVALIDLINK'" class="text-center">{{'error_registration_userexist_text' | translate}}</p>
                </div>

                <div class="marginlist ">
                    <a [href]="instagramLink" target="_blank">
                        <svg class="mr-2 cursur" xmlns="http://www.w3.org/2000/svg" width="34.985" height="34.985" viewBox="0 0 34.985 34.985">
          <rect id="Rectangle_22812" data-name="Rectangle 22812" width="33.985" height="33.985" rx="5" transform="translate(0.5 0.5)" fill="none" stroke="#6b6b6b" stroke-miterlimit="10" stroke-width="1"/>
          <g id="Groupe_11908" data-name="Groupe 11908" transform="translate(5.347 5.346)">
            <rect id="Rectangle_23550" data-name="Rectangle 23550" width="24.293" height="24.293" rx="5" transform="translate(0 0)" fill="#04a6bc"/>
            <rect id="Rectangle_23551" data-name="Rectangle 23551" width="21.348" height="21.348" rx="5" transform="translate(1.472 1.472)" fill="#f5f5f5"/>
            <ellipse id="Ellipse_1202" data-name="Ellipse 1202" cx="5.552" cy="5.552" rx="5.552" ry="5.552" transform="translate(6.547 6.595)" fill="none" stroke="#04a6bc" stroke-miterlimit="10" stroke-width="2"/>
            <ellipse id="Ellipse_1203" data-name="Ellipse 1203" cx="0.992" cy="0.992" rx="0.992" ry="0.992" transform="translate(18.743 3.67)" fill="#04a6bc"/>
            </g>
          </svg>
                    </a>
                    <a [href]="linkedInLink" target="_blank">
                        <svg class="mr-2 cursur" xmlns="http://www.w3.org/2000/svg" width="34.985" height="34.985" viewBox="0 0 34.985 34.985">
            <rect id="Rectangle_22814" data-name="Rectangle 22814" width="33.985" height="33.985" rx="5" transform="translate(0.5 0.5)" fill="none" stroke="#6b6b6b" stroke-miterlimit="10" stroke-width="1"/>
            <g id="Groupe_11909" data-name="Groupe 11909" transform="translate(7.667 6.818)">
            <path id="Tracé_32341" data-name="Tracé 32341" d="M68.946,12.8a1.724,1.724,0,0,1-3.447,0,1.7,1.7,0,0,1,1.739-1.739A1.652,1.652,0,0,1,68.946,12.8Zm-3.1,19.609V17.1H68.63V32.405Z" transform="translate(-65.499 -11.057)" fill="#04a6bc"/>
            <path id="Tracé_32342" data-name="Tracé 32342" d="M75.62,18.841h2.492l.006,2.53h.064a5.628,5.628,0,0,1,5.06-2.878c2.119,0,5.409,1.265,5.409,6.516v9.14H85.867V25.325c0-2.467-.917-4.523-3.542-4.523a3.955,3.955,0,0,0-3.732,2.846,4.008,4.008,0,0,0-.19,1.3v9.2H75.62Z" transform="translate(-67.872 -12.8)" fill="#04a6bc"/>
            </g>
          </svg>
                    </a>
                    <a [href]="facebookLink" target="_blank">
                        <svg class="mr-2 cursur" xmlns="http://www.w3.org/2000/svg" width="34.985" height="34.985" viewBox="0 0 34.985 34.985">
          <rect id="Rectangle_22811" data-name="Rectangle 22811" width="33.985" height="33.985" rx="5" transform="translate(0.5 0.5)" fill="none" stroke="#6b6b6b" stroke-miterlimit="10" stroke-width="1"/>
          <path id="Tracé_21015" data-name="Tracé 21015" d="M130.324,34.2V18.907a.132.132,0,0,0-.132-.132H127.92a.132.132,0,0,1-.132-.132V16.408a.132.132,0,0,1,.132-.132h2.4v-.857c0-2.536.56-4.848,2.088-6.3a6.281,6.281,0,0,1,4.4-1.679,7.673,7.673,0,0,1,2.7.484.13.13,0,0,1,.078.146l-.4,2.266a.128.128,0,0,1-.177.1,5.04,5.04,0,0,0-1.941-.346c-2.8,0-3.506,2.462-3.506,5.222v.969H137.8a.132.132,0,0,1,.132.132v2.235a.132.132,0,0,1-.132.132h-4.1a.132.132,0,0,0-.132.132V34.2a.132.132,0,0,1-.132.132h-2.981A.132.132,0,0,1,130.324,34.2Z" transform="translate(-114.795 -3.39)" fill="#04a6bc"/>
          </svg>
                    </a>
                    <a [href]="youtubeLink" target="_blank">
                        <svg class="mr-2 cursur" xmlns="http://www.w3.org/2000/svg" width="34.985" height="34.985" viewBox="0 0 34.985 34.985">
            <rect id="Rectangle_22813" data-name="Rectangle 22813" width="33.985" height="33.985" rx="5" transform="translate(0.5 0.5)" fill="none" stroke="#6b6b6b" stroke-miterlimit="10" stroke-width="1"/>
            <path id="Tracé_21016" data-name="Tracé 21016" d="M195.512,22.4a.469.469,0,0,1,0,.821l-6.045,3.332-6.095,3.359a.468.468,0,0,1-.694-.41V16.116a.468.468,0,0,1,.694-.41l6.095,3.359Z" transform="translate(-170.195 -5.315)" fill="#04a6bc"/>
          </svg>
                    </a>



                </div>



            </div>


            <footer>
                <div class="mt-4">
                    <label>{{'success_registration_footer_registration1' | translate}}</label><br>
                    <label class="mb-3">{{'success_registration_footer_registration2' | translate}} <a [href]="termOfUseLink" target="_blank" alt="Terms of Use">{{'termsofuse_title' | translate}}</a> {{'success_registration_footer_registrationconcatination' | translate}} <a [href]="privacyPolicyLink" target="_blank" alt="Privacy Policy">{{'registration_href_privacypolicy' | translate}}</a></label><br>
                    <span>{{'success_registration_footer_emailintended' | translate}} <a href="mailto:{{email}}" target="_blank" alt="EMAIL">{{email}}</a></span><br>
                    <span>  {{'success_registration_footer_copyright' | translate}}</span><br>
                    <span>{{'success_registration_footer_datecopyright' | translate}}</span>
                </div>
            </footer>

        </div>
    </div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import icPhoto from '@iconify/icons-ic/photo-camera';
import icPlace from '@iconify/icons-ic/place';
import { Subject } from 'rxjs';
import { RootObject } from 'src/app/models/messagerealtime.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/chat/data.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'chat-inputemer',
  templateUrl: './chat-inputemer.component.html',
  styleUrls: ['./chat-inputemer.component.css']
})
export class ChatInputemerComponent implements OnInit {
  icPhoto = icPhoto;
  icPlace = icPlace;
  textInput: string = '';
  message:string ;
  @Output() sendMessageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() newItemEvent = new EventEmitter<string>();
  @Input() collapsed: any;

  @Input() msg: Subject<any>;
  selectedFile :File = null ;
  fileToUpload: File = null;
   url : string = null ;
   isLoading: Subject<boolean> = this.loaderService.isLoading;

  constructor( private dataservice :DataService, private loaderService: LoaderService) {
  }

  ngOnInit( ) {
  }
  public onChange(target: any) {
    this.msg.next(target.value);
    target.value = '';
  }

  sendMessage() {
    let event: any = {};
    event.value =  this.textInput
    event.type = "text";
    this.sendMessageEvent.emit(event);
    this.textInput = "";
  }

  getFile($event):void{
    this.sendMessageEvent.emit($event.target);
  }

  addNewItem() {
    this.newItemEvent.emit();
  }

}

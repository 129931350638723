import { SharedService } from './../../../services/shared-service/shared.service';

import {   Component, OnInit,  Inject } from '@angular/core';
import {  ActivatedRoute, ParamMap } from '@angular/router';
import { DataService } from 'src/app/services/chat/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'chatUrl',
  templateUrl: './chatUrl.component.html',
  styleUrls: ['./chatUrl.component.css'],

})
export class ChatUrlComponent  implements  OnInit  {

  public chaturl :boolean = false ;
  chatUrl: string ;
  owner_zulip_mail: string ;
  to: string ;
  SN: string ;
  constructor(private activatedRoute: ActivatedRoute,public sanitizer: DomSanitizer,@Inject(DOCUMENT) private document: Document, private sharedService: SharedService,
    private dataservice :DataService)
    {
      this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
        this.chatUrl = params.get('idParam');
        localStorage.setItem("ischatUrl",'ischatUrl');
        this.dataservice.chaturl = true;
      });
   }

  ngOnInit() {
    /* this.dataservice.chatUrlService(this.chatUrl)
    .subscribe((response)=>{
      localStorage.setItem("emergency_contact",JSON.stringify(response));
      localStorage.setItem("is_medical",response.is_medical);
      this.sharedService.getDiscussionEmergency(true)
    }) */
  }

  public  onChange(target: any)    {
  }

  changeuser(){
    setTimeout(() => { const chatinput = document.querySelector('Chat-bot');
    chatinput?.addEventListener('click', this.onClick.bind(this));},5000);

  }
  onClick(event) {

  }

   opencollapsed()
  {

  }
 public onMsgReceive(msg: string) {

          setTimeout(() => { let a = this.document.getElementById('scrollrow');
          if(a)
          {
            a.scrollTop = a.scrollHeight;
          }
         },2000);
  }



  ngOnDestroy() {
   }




  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

   public addItem() {


  }

}






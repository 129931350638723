import { Message } from 'src/app/models/message';
import { SocketService, LeaveRoomMessage, JoinRoomMessage, OtherIncommingMessage } from './../../../services/socket/socket.service';
import { SharedService } from './../../../services/shared-service/shared.service';
import { NgbdocprofilModalContent } from './../chathome/chathome.component';
import { Component, OnInit, Input, ChangeDetectorRef, Output, ViewChild, HostListener, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import icfile from '@iconify/icons-ic/file-download';
import icReward from '@iconify/icons-ic/sharp-card-giftcard';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import icchat from '@iconify/icons-ic/round-message';
import icflech from '@iconify/icons-ic/keyboard-arrow-down';
import icprint from '@iconify/icons-ic/print';
import icmail from '@iconify/icons-ic/mail';
import iclocation from '@iconify/icons-ic/outline-place';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import iccheck from '@iconify/icons-ic/check';
import icoeur from '@iconify/icons-ic/sharp-favorite-border';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user.model';
import { MedicalInfo } from 'src/app/models/MedicalInformation';
import { Observable, Subject,of, BehaviorSubject } from 'rxjs';
import { DataService } from 'src/app/services/chat/data.service';
import { NgbdModalContent } from '../../listmodal/model/model.component';
import {  TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ChathomeComponent } from '../../normalchat/chathome/chathome.component';
import { SharedData } from 'src/app/models/sharedData.model';
import { NgbdPopupmodelContent } from '../../listmodal/popupmodel/popupmodel.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbdPopupmodelDocContent } from '../../listmodal/popupmodeldoc/popupmodeldoc.component';
import { NgbdPopupconfidContent } from '../../listmodal/popupconfid/popupconfid.component';
import { NgbdPopuprewardContent } from '../../listmodal/popupreward/popupreward.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
class Videocall{
  meeting_url ;
}

class printpdf{
  error:boolean;
  message:string;
  url:string;
}

class ShowMedicalInfo {
  allowSharePicture     :string;
  allowShareName :string;
  allowShareEmail       :string;
  allowSharePhone       :string;
  tel                   :string;
  name                  :string;
  email                 :string;
}

@Component({
  selector: 'app-chatview',
  templateUrl: './chatview.component.html',
  styleUrls: ['./chatview.component.css']
})
export class ChatviewComponent implements OnInit, AfterViewInit, OnDestroy{
       public showMe = false;
       windowheight : any ;
       cardheight: any ;
       currentlanguage : any ;
       public loading: boolean = false;
       @ViewChild('scrollcard') scrollcard: ElementRef;
     // @ViewChild("scrolledToElement", { static: false })
      @ViewChild(ChathomeComponent) child:ChathomeComponent;
      public image: string;
      langnum : string ;
      videocall = new Videocall ;
      public birthdate: Date;
      collapsedchamps:boolean=true;
      public chatview : boolean = true;
      collapsedDoc :boolean=false;
      public age: number = null;
      doc : boolean = false ;
      icfile  =icfile;
      pasdedocument:boolean;
      target: any =Event;
      iccheck=iccheck;
      icoeur = icoeur;
      toggle = true;
      chat = true ;
      icprint =icprint;
      icmail = icmail;
      icReward =icReward;
      icchat=icchat;
      icflech= icflech ;
      src:string ;
      public msg: Subject<any> = new Subject();
      public nummsg:number = 0;
      public msgArray: Observable<Array<any>> = new Observable<Array<any>>();
      iclocation =iclocation;
      firstname: string;
      lastname: string;
      public name: string;
      userName : string ;
      blood_type: string ;
      pictureurl:string =null;
      closeResult: string;
      public urlSafe: any;
      collapsed : boolean = true;
      collapsedDocuments  :boolean= false ;
      collapsedInfo :boolean= true ;
      public medicalUser: MedicalInfo = new MedicalInfo();
      public principalMedicalUser: MedicalInfo = new MedicalInfo();
      listSubUsers: MedicalInfo[]=[];
      public currentUser: User;
      chatviewCollabsed : boolean=false;
      chatvideo : boolean=false;
      chatreward: boolean=false;
      chatconf: boolean=false;
      myinfo : Array<string> ;
      emergencycontact  : Array<string> ;
      medicalRecord  :    Array<string> ;
      public document_index = 0  ;
      scrolledToElement: ElementRef;
      currentIndex: number = 0;
      collapseIfScrolled: boolean = false;
      scrollContainer: any;

      showMedicalInfo: ShowMedicalInfo = new ShowMedicalInfo();
      isObjectTag: boolean = false;
      numberMessages: Subject<number> = new Subject<number>();
      exportLoader: boolean = false;
      isDisabledInput: boolean = false;
      isLoading: Subject<boolean> = this.loaderService.isLoading;

      constructor(public sanitizer: DomSanitizer,
        private sharedData :SharedData,
        private cd: ChangeDetectorRef,
        private translate :TranslateService,
        public dataservice :DataService,private authService :AuthService, private router :Router,private modalService: NgbModal,
        private sharedService: SharedService, private socketService: SocketService, private loaderService: LoaderService){

        this.currentUser = this.authService.currentUserValue;
        this.medicalUser = this.authService.medicalUserValue;
        this.myinfo = new Array<string>();
        this.emergencycontact = new Array<string>();
        this.medicalRecord = new Array<string>();
        //<cd.detectChanges()
        this.sharedService.onChangeScrollDetected().subscribe((scroll: boolean) => {
          this.collapseIfScrolled = scroll
        });
         this.authService.listenMedicalInfo().subscribe((m: MedicalInfo) => {
         this.GetMedicalInfo(m, null);
         this.dataservice.listenMessages().subscribe((listner)=>{
          if(listner)
          this.collapseIfScrolled = true
         })
         this.generateListMemeber(m)
         this.medicalUser = m;
         if ( this.medicalUser== null)
         this.router.navigate(['']);
          })
          let pincode = localStorage.getItem('pincode') ? localStorage.getItem('pincode') : null;
          if(localStorage.getItem('SerialNumber') != undefined)
          this.authService.Getemergency(localStorage.getItem('SerialNumber'), pincode).subscribe((m: MedicalInfo)=>{
            this.GetMedicalInfo(m, null);
            this.generateListMemeber(m)

          })
           if (  !this.medicalUser)
           {
              this.medicalUser = this.authService.medicalUserValue;
              this.emergencycontact = [];
              this.GetMedicalInfo( this.medicalUser, null);
           }


        this.dataservice.listen().subscribe((m: any) => {
          this.countMessage(m);
        })

        this.dataservice.onChangeRedirectToMedicalInformation()
        .subscribe((response: string)=>{
          if(response == 'openMedicalInformations')
          this.openMedical();
          else if(response == 'openVideoCall')
          this.openvideocall();
          else this.openchat()
        })
        sharedService.medicalInformationChange().subscribe((status)=>{
          if(status)
          this.openMedical();
        })
        this.sharedService.detectMessagesChange()
        .subscribe((value: boolean)=>{
          this.isDisabledInput = value;
        });
        this.socketService.onMessageEvent()
        .subscribe((data) => {
          const messageContent = JSON.parse(data as string)
          if(messageContent?.user?.user_type !== 'finder'){
            this.getMessageEvent(messageContent)
          }
        });
     }
  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame?.nativeElement;
    if(!localStorage.getItem("currentUser"))
    this.router.navigate(['']);
  }
  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  getMessageEvent(messageEvent){
    this.nummsg++;
    this.scrollTo('openChat')
    if ( messageEvent?.message?.includes("[pincode]:"))
    {
      let serial = localStorage.getItem("SerialNumber")
      var pincode =messageEvent?.message?.replace("[pincode]:","")
      this.authService.Getemergency(serial,pincode)
      .subscribe((data :any)=>{
        localStorage.setItem("pincode",pincode)
      });
    }

    if (messageEvent.user.username != 'Finder' && messageEvent.user.username != 'server' && this.dataservice.ownerrepley == false)
      {
        this.dataservice.ownerrepley=true;
        this.dataservice.chatfirst("/Send_user_Response" ,"owner_did_reply",1)
        this.dataservice.collapsedUser=false;
        localStorage.setItem('isOwnerReply',"true")
      }
  }
      @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
      lastScrollTopValue: number = 0;

      yourFunction(event){
       // this.scrollToBottom()
       this.scrollContainer = this.scrollFrame.nativeElement;

       //const pos = this.scrollContainer?.scrollHeight - this.scrollContainer?.scrollTop  > this.lastScrollTopValue;
       /* this.lastScrollTopValue = this.scrollContainer?.scrollHeight - this.scrollContainer?.scrollTop

       const threshold = 750;
       const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
       const height = this.scrollContainer.scrollHeight;
       const pos = position > height - threshold; */

       let pos = this.scrollContainer?.scrollTop  + this.scrollContainer?.offsetHeight >= this.scrollContainer?.offsetHeight + (this.lastScrollTopValue);
    if(pos)
    this.lastScrollTopValue += 1.3
    else
    this.lastScrollTopValue -= 1.3

    this.collapseIfScrolled = pos;
       this.collapseIfScrolled = pos
      }

     generateListMemeber(medical: MedicalInfo): void{
      this.listSubUsers = [];
      let index: number = 0;
      if(medical.user_general_info?.index)
      medical.user_general_info.index = index;
      this.listSubUsers.push(medical)

      medical.user_general_info?.sub_users?.forEach((element)=>{
        index++;
        element.user_general_info.index =  index;
        this.listSubUsers.push(element);
      })
     }

     GetMedicalInfo(m: MedicalInfo, pictureMemmber: string){
      this.myinfo = [];
      this.medicalUser = m ;
  //    this.pictureurl =  this.currentUser.tag_member?.child_picture_url;

        if (  this.currentUser?.tag_info?.preference_user?.allow_share_picture  ==="1" && this.currentUser?.tag_info.features.medical_share_picture){
            this.pictureurl =  this.currentUser.tag_member?.tag_creator_profile_picture_url
        }
        if(pictureMemmber != null && this.currentUser?.tag_creator?.email !== this.medicalUser?.user_general_info.mail){
          this.pictureurl = pictureMemmber;
        }

       if ( this.medicalUser != null && this.myinfo?.length === 0){
          if (  !this.medicalUser.user_general_info?.first_name)
            this.myinfo.push("editprofil_general_label_firstname");
          if (  !this.medicalUser.user_general_info?.birth_date)
            this.myinfo.push("editprofil_medical_subtitle_birth");
          if (  !this.medicalUser.user_general_info?.mail)
            this.myinfo.push("editprofil_medical_btn_email");
          if (  !this.medicalUser.user_general_info?.mobile)
            this.myinfo.push("mobile_label_app");


          if (   !this.medicalUser?.medical_record?.gender )
            this.myinfo.push("editprofil_medical_subtitle_gender");
          if (   this.medicalUser?.medical_record?.donar !== 1 )
            this.myinfo.push("Not an organ donor");

        if (   !this.medicalUser?.medical_record?.eye_color )
        this.myinfo.push("editprofil_medical_subtitle_eyecolor");
        if (   !this.medicalUser?.medical_record?.heightweight )
        {
          if (  !this.medicalUser?.medical_record?.heightweight?.height_cm )
          {
            this.myinfo.push("editprofil_medical_label_height");
          }
          if (  !this.medicalUser?.medical_record?.heightweight?.weight_kg )
          {
            this.myinfo.push("editprofil_medical_label_weight");
          }

        }
      }


        if (   !this.medicalUser?.medical_record?.pet_at_home  )
          this.myinfo.push("editprofil_medical_subtitle_pet");
        if (   !this.medicalUser?.medical_record?.spoken_languages)
        {
          this.myinfo.push("editprofil_medical_subtitle_speaks");
        }
        if (this.medicalUser?.medical_record?.medical_diseaces && this.medicalRecord?.length === 0)
        {
        if (this.medicalUser?.medical_record?.resuscitate.allow === 0)
          this.medicalRecord.push("editprofil_medical_subtitle_dnr");
        if (this.medicalUser?.medical_record?.immunization?.blocks?.length === 0)
          this.medicalRecord.push("Onboarding_medical_ImmunizationsTitle");
        if ( this.medicalUser?.medical_record?.medical_diseaces['infection disaces']?.blocks?.length === 0)
          this.medicalRecord.push("editprofil_medical_subtitle_diseases");

        if ( this.medicalUser?.medical_record?.medical_diseaces)
          {
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Allergies?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_allergies");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Implants?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_implants");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Medication?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_medication");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Cancer?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_cancer");
            if ( this.medicalUser?.medical_record?.medical_diseaces['Renal (Kenedy)']?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_renal");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.cardiac?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_cardiac");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Psychiatric?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_psychiatric");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Neuroligic?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_neurologic");
            if ( this.medicalUser?.medical_record?.medical_diseaces?.Plumonary?.blocks?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_pulmonary");
            if ( this.medicalUser?.medical_record?.insurance_info?.length === 0)
              this.medicalRecord.push("editprofil_medical_subtitle_insurance");
            if(this.medicalUser?.medical_record.miscilanious?.length === 0)
              this.medicalRecord.push("objecttag_subtitle_miscellaneous");
          }
      }

      if(this.emergencycontact?.length === 0){
        if (   this.medicalUser?.medical_record?.physician_contact?.length === 0 )
          this.emergencycontact.push("editprofil_medical_subtitle_physiciancontact");
        //if (    this.medicalUser?.medical_record?.emerency_contact?.length === 0)
        //this.emergencycontact.push("finder_medicalpage_employercontact");
      }
     }
     countMessage(num : number):void {
       if (!this.chatviewCollabsed){
        //this.nummsg++;
       }
       else{
        this.nummsg = 0 ;
       }
     }
     open(content, type?: string) {
      let customSize = 'sm';
      if(content?.data?.includes('pdf'))
      customSize = 'xl'

      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: customSize,
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,

      };
      if(content?.data?.includes('pdf')){
        window.open(content?.data, '_blank')
      }else{
        const modeldoc =this.modalService.open(NgbdModalContent,options);
        modeldoc.componentInstance.image = content?.data;
        modeldoc.componentInstance.currentUser = this.currentUser?.tag_creator?.tag_creator_id;
        modeldoc.componentInstance.medicalUser = this.medicalUser;
        modeldoc.componentInstance.name = content?.document_name;
        modeldoc.componentInstance.type = type
      }
    }

    public  onChange(target: any)    {
      this.msg.next(target);
      target.value = '';
    }


    openreward(){

      this.chatreward=true;
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
        windowClass : "videoClass"
      };

      const modeldoc =this.modalService.open(NgbdPopuprewardContent,options);

      setTimeout(() => {  this.chatreward = false;},1000);

      modeldoc.componentInstance.event.subscribe((data:any) => {
        this.chatreward=false;

        modeldoc.close();
      });
    }

    openPopupconfid(){

      this.chatconf = true;
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
        windowClass : "videoClass"
      };
      setTimeout(() => {  this.chatconf = false;},1000);
      const modeldoc =this.modalService.open(NgbdPopupconfidContent,options);


      modeldoc.componentInstance.event.subscribe((data:any) => {
        this.chatconf = false;

        modeldoc.close();
      });
    }
    changeuser(){
      setTimeout(() => { const chatinput = document.querySelector('Chat-bot');
      chatinput.addEventListener('click', this.onClick.bind(this));},5000);

    }

    openMedicalsubuser(item :any, index: number, pictureMemmber: string){
      this.currentIndex = index;
      if(this.currentUser?.tag_info.preference_user.allow_share_picture == '1' && this.currentUser?.tag_info.features.medical_share_picture == 1){
        this.showMedicalInfo.allowSharePicture = "1";
      }else{
        this.showMedicalInfo.allowSharePicture = "0";
      }
      if(this.currentUser?.tag_info.preference_user?.allow_share_name == '1' && this.currentUser?.tag_info.features.medical_share_name == 1){
        this.showMedicalInfo.allowShareName = '1';
      }else{
        this.showMedicalInfo.allowShareName = '0'
      }
      if(this.currentUser?.tag_info.preference_user?.allow_share_emails == '1' && this.currentUser?.tag_info.features.medical_share_mail == 1){
        this.showMedicalInfo.allowShareEmail = "1";
      }else{
        this.showMedicalInfo.allowShareEmail = "0";
      }
      if(this.currentUser?.tag_info.preference_user?.allow_share_phone == '1' && this.currentUser?.tag_info.features.medical_share_phone == 1){
        this.showMedicalInfo.allowSharePhone = "1";
      }else{
        this.showMedicalInfo.allowSharePhone = "0";
      }
      this.showMedicalInfo.tel = item.user_general_info.mobile
      this.showMedicalInfo.name = item.user_general_info?.first_name+ ' ' + item.user_general_info?.last_name
      this.showMedicalInfo.email = item.user_general_info.mail
      this.GetMedicalInfo(item, pictureMemmber)

    }


    onClick(event) {
    }

    public opencollapsed()
    {
      if (this.collapsed )
        this.collapsed = false;
      else
        this.collapsed = true;
    }



  ngOnInit() {

    if(localStorage.getItem('currentChatbot') == 'object')
    this.isObjectTag = true;
    else
    this.isObjectTag = false;

    if(localStorage.getItem('jobpage') == 'child')
    this.chatviewCollabsed = true

    /* if(this.msg != null && this.dataservice.chatAlreadyStarted){
      this.nummsg++;
    } */
    /**
     *  join room
     */
    if(!this.isObjectTag){
    const joinMessage: JoinRoomMessage = {
      id_user: this.currentUser?.finder_info?.id,
      user_type: 'finder',
      username: 'Finder',
      room: this.currentUser?.tag_info.tag_info?.serial_number,
    };
    this.socketService.joinRoom(joinMessage)

    /**
     * if join room error recall to join
     */
     this.socketService.onErrorEvent().subscribe((data: any) => {
      let dataJson = JSON.parse(data);
      if(dataJson)
      if(dataJson?.message?.includes('You are not in the room')  || dataJson?.message?.includes('Join request not valid') || dataJson?.message?.includes('User Info is not valid') || dataJson?.message?.includes('Message request is not valid')){
        const joinMessage: JoinRoomMessage = {
          id_user: this.currentUser?.finder_info?.id,
          user_type: 'finder',
          username: 'Finder',
          room: this.currentUser?.tag_info.tag_info?.serial_number,
        };
        this.socketService.joinRoom(joinMessage)
      }
    });
    }

    this.cardheight=window.screen.height-210;
    this.windowheight =window.screen.height-170 ;
    this.currentUser = this.authService.currentUserValue;
    this.medicalUser = this.authService.medicalUserValue;

    if(this.currentUser?.tag_info.preference_user?.allow_share_picture == '1' && this.currentUser?.tag_info.features.medical_share_picture == 1){
      this.showMedicalInfo.allowSharePicture = "1";
    }else{
      this.showMedicalInfo.allowSharePicture = "0";
    }
    if(this.currentUser?.tag_info.preference_user?.allow_share_name == '1' && this.currentUser?.tag_info.features.medical_share_name == 1){
      this.showMedicalInfo.allowShareName = "1";
    }else{
      this.showMedicalInfo.allowShareName = "0";
    }
    if(this.currentUser?.tag_info.preference_user?.allow_share_emails == '1' && this.currentUser?.tag_info.features.medical_share_mail == 1){
      this.showMedicalInfo.allowShareEmail = "1";
    }else{
      this.showMedicalInfo.allowShareEmail = "0";
    }
    if(this.currentUser?.tag_info.preference_user?.allow_share_phone == '1' && this.currentUser?.tag_info.features.medical_share_phone == 1){
      this.showMedicalInfo.allowSharePhone = "1";
    }else{
      this.showMedicalInfo.allowSharePhone = "0";
    }
    this.showMedicalInfo.tel = this.currentUser?.tag_member.tag_creator_mobile;
    this.showMedicalInfo.name = this.currentUser?.tag_member.tag_creator_first_name;
    this.showMedicalInfo.email = this.currentUser?.tag_member.tag_creator_mail1;

    if (  this.currentUser=== null ||  this.currentUser?.tag_info?.tag_info?.active_emergency=== 0 ||  this.currentUser?.tag_info?.tag_info?.emergency=== 0)
    {
      this.router.navigate(['']);
      return ;
    }


    if (  this.currentUser.tag_member?.id_role === 4 &&  this.currentUser.tag_member?.is_medical_tag === 1)
      {
        if (  this.currentUser?.tag_info.preference_user?.allow_share_picture ==="1" && this.currentUser.tag_creator?.tag_creator_profile_picture_url /* && this.currentUser?.tag_info?.features?.medical_share_picture */)
          {
            this.pictureurl =  this.currentUser.tag_member?.child_picture_url;
          }
        if (  this.currentUser?.tag_info?.preference_user?.allow_share_name ==="1" && this.currentUser?.tag_info?.features.medical_share_name)
          {
            this.userName =  this.currentUser.tag_member.tag_creator_first_name;
          }
      }
      else
      {
        if (  this.currentUser?.tag_info?.preference_user?.allow_share_picture  ==="1" && this.currentUser.tag_creator?.tag_creator_profile_picture_url/*  && this.currentUser?.tag_info.features.medical_share_picture */)
          {
                this.pictureurl =  this.currentUser.tag_creator?.tag_creator_profile_picture_url
          }
        if (  this.currentUser?.tag_info?.preference_user?.allow_share_name==="1"&& this.currentUser?.tag_info.features.medical_share_name)
          {
                this.userName =  this.currentUser.tag_creator?.tag_creator_first_name
          }

       }
    this.dataservice.Sendmessage('[sn]:'+ this.currentUser?.tag_info?.tag_info.serial_number);
    this.dataservice.Sendmessage('[tag_label]:'+ this.currentUser?.tag_info?.tag_info.tag_label);

     if (
      this.medicalUser?.medical_record?.medical_diseaces?.Allergies.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Cancer.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Implants.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Medication.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Neuroligic.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Plumonary.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.Psychiatric.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces['Renal (Kenedy)']?.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces?.cardiac.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.medical_diseaces['infection disaces']?.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.other_medical_record_info?.length == 0 &&
      this.medicalUser?.medical_record?.insurance_info?.length == 0 &&
      (this.medicalUser?.medical_record?.organ_donar?.documents?.length == 0 && this.medicalUser?.medical_record?.organ_donar?.donar == 1) &&
      this.medicalUser?.medical_record?.medical_diseaces?.cardiac?.blocks.length == 0 &&
      this.medicalUser?.medical_record?.immunization?.blocks?.length == 0 &&
      this.medicalUser?.medical_record?.blood_info?.diabates?.length == 0 &&
      this.medicalUser?.medical_record?.insurance_info?.length == 0
      )
      {
        this.pasdedocument=true ;
      }
      else  this.pasdedocument=false ;
  }

  scrollTo(el: string) {
    const element = document.getElementById(el);
    element?.scrollIntoView()
    const offset = 160;
    const bodyRect = document.body?.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top;
    const elementPosition = elementRect || 1 - bodyRect || 1;
    const offsetPosition = elementPosition || 1 - offset || 1;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  exporMedicalProfile(): void{
    this.exportLoader = true;
    this.authService.getViewUser(this.currentUser?.tag_creator?.tag_creator_id)
    .subscribe((response)=>{
      this.dataservice.createTemplate(response, this.currentUser?.tag_creator?.tag_creator_id, this.currentUser?.tag_creator?.email)
      .subscribe((exportResponse)=>{
        window.open(exportResponse.url, '_self')
        this.exportLoader = false;
      },(error)=>{
        this.exportLoader = false;
        })
    },(error)=>{
    this.exportLoader = false;
    });
  }

  public onMsgReceive(msg: string) {

  }
  public printPage(){

    this.dataservice.createTemplate(this.medicalUser,this.currentUser?.tag_creator?.tag_creator_id, null).pipe().subscribe((value: any) =>
     { if (value) {
        window.open(value.url)
      }
    },
    error=>
    {

    }
    );
  }
  public printEmail(){
    this.openmail();

  }
  openinfo(pictureurl, medicalUser)
  {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
    };

    this.image=pictureurl;
    const modeldoc =this.modalService.open(NgbdocprofilModalContent,options);
    modeldoc.componentInstance.image = this.image;
    modeldoc.componentInstance.allowSharePicture = this.showMedicalInfo.allowSharePicture;
    modeldoc.componentInstance.allowShareName = this.showMedicalInfo.allowShareName;
    modeldoc.componentInstance.allowShareEmail = this.showMedicalInfo.allowShareEmail;
    modeldoc.componentInstance.allowSharePhone = this.showMedicalInfo.allowSharePhone;
    modeldoc.componentInstance.tel = this.showMedicalInfo?.tel;
    modeldoc.componentInstance.name = this.showMedicalInfo?.name;
    modeldoc.componentInstance.email = this.showMedicalInfo?.email;
  }
  openDocuments()
  {
    this.openMedical();
    this.collapsedDocuments = true

    /* if ( this.chatvideo) return;
    if ( this.chat===false )
    {
      this.opendoc(this.currentUser?.tag_info?.other_info[0]?.documents[this.document_index])

    }

     else
    {
      this.collapsed = true ;
      this.collapsedDocuments = true ;
      this.collapsedInfo = true;
      this.doc =true;
      this.toggle = false ;
      this.collapsedchamps =false ;
    } */
  }
  /* opendoc(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };

    if ( content === undefined)
    {
      this.openPopup('finder_documents_nodocs')
      return;
    }
    this.image = content.data;
    this.name = content.document_name;
    if ( content.data.substr(-3)  === "pdf")
      {
        window.open(content);
        return;
      }
    const modeldoc =this. modalService.open(NgbvdocModalContent,options);
    modeldoc.componentInstance.image = this.image;
    modeldoc.componentInstance.name = this.name;
  }
 */
  openmail() {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };

    const modeldoc =this. modalService.open(NgbPrintModalContent,options);
    modeldoc.componentInstance.iduser = this.currentUser?.tag_creator?.tag_creator_id;
    modeldoc.componentInstance.body = this.medicalUser;
    modeldoc.componentInstance.name = null;

  }
  opendocTag(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };
    if ( content === undefined)
    {
      this.openPopup('finder_documents_nopicture')

    return;
    }
    this.image = content;
    if ( content.substr(-3)  === "pdf")
      {
        window.open(content);
        return;
      }
    const modeldoc =this. modalService.open(NgbdModalContent,options);
    modeldoc.componentInstance.image = this.image;
  }





  openchat(){
    this.isLoading.subscribe((value: boolean)=>{
      if(value)
      return;
    });
    if ( this.showMe)this.showMe = false ;
    if ( !this.chatviewCollabsed || this.chatvideo)
    {
      this.loading = false ;
      this.chatview = true;
      this.doc=false;
      this.dataservice.chatview = true;
      this.toggle=false;
      this.chat =false;
      this.collapsedchamps =true;
      this.chatviewCollabsed = true ;
      this.collapsed = true;
      this.collapsedDocuments=false;
    }
    this.dataservice.numberMessages  = this.nummsg = 0 ;


  }
  ngOnDestroy(){
    if(!this.isObjectTag){
      this.dataservice.conversation=new BehaviorSubject<Message[]>([]);
      const leaveRoom: LeaveRoomMessage = {
        'room': this.currentUser?.tag_info.tag_info?.serial_number
      }
      //this.socketService.leaveRoom(leaveRoom)
    }
    this.chatview = false;
    this.dataservice.conversation  = new BehaviorSubject<Message[]>([]);
  }
  openMedical(){
    this.isLoading.subscribe((value :boolean)=>{
      if(value)
      return;
    })

      this.chatvideo=false;
      this.loading = false ;
      if ( this.showMe)this.showMe = false ;
      this.chatview = true;
      this.doc=false;
      this.chat =true;
      this.collapsedchamps =false;
      this.collapsed = true;
      this.toggle=true;
      this.chatviewCollabsed = false ;
      this.collapsedDocuments=false;


  }

  addlocation() {
    this.openchat();
    setTimeout(() => {
      this.child?.addItem(null);
    }, 1000);

  }

  openvideocall(){
    this.isLoading.subscribe((value :boolean)=>{
      if(value)
      return;
    })

    this.openchat();

      this.chatvideo=true ;
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "videoClass"
    };

    const modeldoc =this.modalService.open(NgbdPopupmodelContent,options);
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number=3;
    modeldoc.componentInstance.color="#04A6BC";
    modeldoc.componentInstance.texte="finder_label_videostart";
    modeldoc.componentInstance.type = "alert";
    modeldoc.componentInstance.typeUser = 'finder';
    modeldoc.componentInstance.tel = this.currentUser?.tag_creator?.tag_creator_mobile;
    modeldoc.componentInstance.allowTel = this.currentUser?.tag_info.preference_user.allow_share_phone
    modeldoc.componentInstance.confirmationBoxTitle = 'finder_label_callstart';
    modeldoc.componentInstance.confirmationMessage = 'notification_canceltag_btn_cancel';
    setTimeout(() => { this.chatvideo=false ;  }, 1000);
    modeldoc.componentInstance.event.subscribe((data:any) => {
      if ( data.chatvideo)
      {

        this.startvideocall();
      }
       this.chatvideo=false ;
      modeldoc.close();
    });

  }
  startvideocall()
  {
     this.dataservice.videocall().subscribe((value: Videocall) => {
      if (value) {
          this.child.addVideo(value)
          window.open( value.meeting_url)
        }
      } ,
      err => {
      }
     );
  }

  click()
  {
    const myModal = document.getElementById('tagModal')
    const myInput = document.getElementById('myInput')

    myModal.addEventListener('shown.mdb.modal', () => {
     myInput.focus()
    } )
  }

  affichedoc(value)
  {
    if (this.collapsedDoc )
    this.collapsedDoc = false;
    else  this.collapsedDoc = true;
  }

  openPopup(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "myCustomModalClass"
    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.texte=content;
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.color="#F4C809";
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });


  }

  expandName(name: string): string {
    if (name?.length < 14) {
      return name;
    } else {
      return name.substring(0, 12) + '...';
    }
  }

  expandFileName(name: string): string {
    if (name?.length < 16) {
      return name;
    } else {
      return name.substring(0, 14) + '...';
    }
  }

  expandEmail(name: string): string {
    if (name?.length < 21) {
      return name;
    } else {
      return name.substring(0, 17) + '...';
    }
  }

  expandLanguageName(name: string): string {
    if(this.pictureurl){
      if (name?.length < 18) {
        return name;
      } else {
        return name.substring(0, 15) + '...';
      }
    }else{
      if (name?.length < 21) {
        return name;
      } else {
        return name.substring(0, 18) + '...';
      }
    }
  }

  getPosition($event){
  }

  public CalculateAge(birth_date): number {
    this.age = null ;
    this.birthdate = new Date(birth_date);
    if (birth_date) {
      var timeDiff = Math.abs(Date.now() - new Date(this.birthdate).getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);}
        return this.age;
      }
}

@Component({
  selector: 'ngbv-modal-content',
  styleUrls: ['./chatview.component.css'],
  template:
  `
        <button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white" aria-hidden="true">×</span>
        </button>
        <div class="modal-body mt-0">
          <img [src]="image" class="img-fluid" alt="Responsive image">
        </div>
        <div  class="modal-footer">
        <div class="col">
          <div class="row  " style="justify-content: center;" >
              <p class="modelname">{{name}}</p>
          </div>
          <div class="row" style="justify-content: space-between;margin: 0px 0px 11px;">
            <button [disabled]="isFirstDoc()" class="icon2"  (click)="Documentavant()">
              <img src="/assets/img/flech.png" style=" transform: rotate(-180deg);" >
            </button>
            <button  [disabled]="isLastDoc()"  class="icon2" (click)="Documentsuivant()">
              <img src="/assets/img/flech.png" >
            </button>
          </div>
         </div>
        </div>
  `
})
export class NgbvdocModalContent {
  @Input() public  image;
  @Input() public name ;
  currentFile: any = {};
  currentUser : User ;
  disabled_btns = false;
  displayedImage :string ;
  @Input() public document_index = 0;
  photoURL: SafeUrl = null;
  document_name :string;
  public otherinfodoc_index = 0;
  collapsedbutton : boolean = false;
  constructor(public activeModal: NgbActiveModal,private authService :AuthService) {
       this.currentUser = this.authService.currentUserValue;

  }
  ngOnInit()
  {

  }
  isFirstDoc() {
    return this.currentFile.index === 0;
  }

  isLastDoc() {
    return this.currentFile.index === this.currentUser?.tag_info.other_info[0]?.documents?.length-1;
  }
  Documentsuivant(){
    if ( this.document_index < this.currentUser?.tag_info.other_info?.length-1 )
    {
        this.disabled_btns = true;
      if ( this.currentUser?.tag_info.other_info[this.document_index]?.documents?.length > 0  ) {
        if ( this.otherinfodoc_index < this.currentUser?.tag_info.other_info[this.document_index]?.documents?.length-1 )
            this.otherinfodoc_index = this.otherinfodoc_index += 1;
            else
            {
            this.otherinfodoc_index = 0 ;
            this.document_index = this.document_index += 1;
            }
      }
      else
      {
        this.otherinfodoc_index = 0 ;
        this.document_index = this.document_index += 1;
      }
        this.image = this.currentUser?.tag_info?.other_info[this.document_index]?.documents[this.otherinfodoc_index]?.data;
        this.name =  this.currentUser?.tag_info?.other_info[this.document_index]?.documents[this.otherinfodoc_index]?.document_name;
    }
}

Documentavant(){
  if ( this.document_index > 0)
  {
          this.disabled_btns = true;
        if ( this.currentUser?.tag_info.other_info[this.document_index]?.documents?.length > 0  ) {
          if ( this.otherinfodoc_index > 0)
              this.otherinfodoc_index = this.otherinfodoc_index -= 1;
              else
              {
              this.otherinfodoc_index = this.currentUser?.tag_info?.other_info[this.document_index]?.documents?.length - 1 ;
              this.document_index = this.document_index -= 1;
              }
        }
        else
        {
          this.otherinfodoc_index = this.currentUser?.tag_info?.other_info[this.document_index]?.documents?.length - 1 ;
          this.document_index = this.document_index -= 1;
        }
          this.image = this.currentUser?.tag_info?.other_info[this.document_index]?.documents[this.otherinfodoc_index]?.data;
          this.name =  this.currentUser?.tag_info?.other_info[this.document_index]?.documents[this.otherinfodoc_index]?.document_name;
    }
  }
}

@Component({
  selector: 'ngb-Printmodal-content',
  styleUrls: ['./chatview.component.css'],
  template:
      `
        <div class="backrow row">
        <h5 class="modal-title textlost" *ngIf="name!==null && name !== ''">Document</h5>

          <h5 class="modal-title textlost" *ngIf="name===null || name ===''">{{'editprofil_general_label_exportrecord' | translate}}</h5>
          <button type="button" class="close-modal-btn closeemail" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
             <span aria-hidden="true" class="text-white closebut">×</span>
          </button>
        </div>
        <div class="modal-body" >
          <div class="row" style="padding:15px">
          <span class="emailstyle" *ngIf="name!==null && name !== ''"> Email {{name}} to :</span>

            <span class="emailstyle text-left" *ngIf="name===null || name ===''">{{'printEmail_poster' | translate}} :</span>

          </div>
          <div class="row" style="padding:20px">
            <div class="col-md-12 col-sm form-group" [formGroup]="form">
                    <input style="background-color:#F7F5F5" type="email" formControlName="mail" matInput required [(ngModel)]="mail"  class="form-control serialNumber"  aria-describedby="SerialNumber" placeholder="name@email.com" onfocus="if (this.value == 'Type some text here') {this.value=''}" >

                     <span *ngIf="(getEmail.invalid && getEmail.touched) || getEmail.dirty">
                     <small matSuffix *ngIf="getEmail.errors?.required" class="text-danger">{{'inputchecker_label_emailrequired' | translate}}</small>
                     <small matSuffix *ngIf="getEmail.errors?.pattern" class="text-danger">{{'chatbot_script_medical_validemail' | translate}} </small>
                 </span>
                    <div class="invalid-feedback" >
                    {{'finder_response_textarea' | translate}}
                    </div>

            </div>
          </div>
            <ng-container *ngIf="loading" style="margin: 40px;">
              <mat-spinner style="stroke: #04A6BC;" [diameter]="40"></mat-spinner>
            </ng-container>
        </div>

        <div style="justify-content: center;" class="modal-footer">
         <div class="row" style="padding: 10px 10px 24px 10px;">
           <div class="col-md-12 col-sm">
              <button style="background-color:#23CE6B; color:white;" type="button" (click)="sendmail()" [disabled]="!form.valid"  class="btn btn-lg btn-block"  role="button">
                <span class="continue"  >{{'finder_medicalpage_btn_print' | translate}}</span>
             </button>
           </div>
         </div>
        </div>
  `
})
export class  NgbPrintModalContent{
@Input() body :any ;
@Input() iduser:string ;
@Input() name:string ;
public loading: boolean = false;
form!: FormGroup;
mail :string ;
  constructor(public activeModal: NgbActiveModal,public sanitizer: DomSanitizer,public dataservice :DataService,private modalService: NgbModal, private fb: FormBuilder) {

  }

  get getEmail(){
    return this.form.get('mail')
    }
  ngOnInit()
  {
    this.form = this.fb.group({
      mail: new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    });



  }
 public sendmail(){
  this.loading = true ;
  this.dataservice.createTemplate(this.body ,this.iduser, this.mail).pipe().subscribe((value: any) => {
    if (value.error === false)
    { this.loading = false ;
      this.openPopup(4,"Your mail was sent!" ,"#23CE6B" ,'Success !', "success");
    }
  },
  error=>{
    this.loading = false ;
    this.openPopup(1,"finder_response_titleerror" ,"#F4C809",'',  "error");

  });
 }




 openPopup(number:number ,content,color:string ,text :string = null, type: string) {
  let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "myCustomModalClass",

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = number;
    modeldoc.componentInstance.texte = text;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.color=color;
    modeldoc.componentInstance.type=type;
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });
}
}

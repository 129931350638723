import { BehaviorSubject } from 'rxjs';
import {ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/chat/data.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SharedData } from 'src/app/models/sharedData.model';
import { User } from 'src/app/models/user.model';
import { Message } from '../chat/chat.service';

class Login {
  error ;
}

@Component({
  selector: 'lost-or-found',
  templateUrl: './lost-or-found.component.html',
  styleUrls: ['./lost-or-found.component.css']
})

export class LostOrFoundComponent {
  userName: string =null ;
  currentlanguage : any ;
  langnum:string ;
  label:string;
  childcase:boolean= false ;
  inputType = 'text';
  pictureurl:string = null ;
  form!: FormGroup;
  public currentUser: User;
  SerialNumberInput: string;
  messages = [];
  finder_lostpage_btn_lost :string ;
  finder_lostpage_btn_medical :string;
  finder_lostpage_title:string ;
  constructor( private sharedData : SharedData ,private cd: ChangeDetectorRef,private dataService : DataService,
   private authService :AuthService, private router :Router, private dataservice :DataService )
  {

    this.currentUser = this.authService.currentUserValue;
      if (  this.currentUser!= null &&  this.currentUser.tag_info?.tag_info.active_emergency=== 1 &&  this.currentUser.tag_info?.tag_info?.emergency=== 1)
      {

            if (  this.currentUser.tag_member.id_role === 4 &&  this.currentUser.tag_member.is_medical_tag === 1)
            {
              if (  this.currentUser.tag_info.preference_user?.allow_share_picture ==="1" && this.currentUser.tag_info?.features?.medical_share_picture == 1)
               {
                 this.pictureurl =  this.currentUser.tag_member.tag_creator_profile_picture_url;
               }
               if (  this.currentUser.tag_info.preference_user?.allow_share_name ==="1"  && this.currentUser.tag_info?.features?.medical_share_name == 1)
                {
                  this.userName =  this.currentUser.tag_member?.tag_creator_first_name;
                }
            }
            else
            {
              if (  this.currentUser.tag_info.preference_user?.allow_share_picture ==="1" && this.currentUser.tag_info?.features?.medical_share_picture == 1)
              {
                this.pictureurl =  this.currentUser.tag_member.tag_creator_profile_picture_url;
              }
              if (  this.currentUser.tag_info.preference_user?.allow_share_name ==="1" && this.currentUser.tag_info.features.medical_share_name == 1)
              {
                this.userName =  this.currentUser.tag_member.tag_creator_first_name;
              }

            }

          if ( this.pictureurl === null)
          this.pictureurl = "/assets/img/no_pic.png";

          this.SerialNumberInput =  this.currentUser.tag_info.tag_info.serial_number;
      }
      else
      this.router.navigate(['']);
  }

  public openchatbot(){
    localStorage.setItem("ischatUrl",'');
    localStorage.setItem('objectjob','object')
    localStorage.setItem('jobpage','lostobject')

    if(localStorage.getItem('oldChatbot') !== null && localStorage.getItem('currentChatbot') !== null){
      localStorage.setItem('currentChatbot','object')
    } else {
      localStorage.setItem('oldChatbot','object')
      localStorage.setItem('currentChatbot','object')
    }
    if(localStorage.getItem('oldChatbot') !== localStorage.getItem('currentChatbot')){
      localStorage.setItem("sessionid","undefined");
    }

    this.dataservice.chatAlreadyStarted = false;
    if (  this.currentUser.tag_info.features?.medical_ai_chat ===1)
    this.router.navigate(['/' + this.SerialNumberInput +'/chathome']);
    else
    this.router.navigate(['/' + this.SerialNumberInput +'/homepage']);

    this.onSendSms("object")

    }
    public openemergency(){
      localStorage.setItem("ischatUrl",'');
      if(localStorage.getItem('oldChatbot') !== null || localStorage.getItem('currentChatbot') !== null){
        localStorage.setItem('currentChatbot','medical')
      } else {
        localStorage.setItem('oldChatbot','medical')
        localStorage.setItem('currentChatbot','medical')
      }
      if(localStorage.getItem('oldChatbot') !== localStorage.getItem('currentChatbot')){
        localStorage.setItem("sessionid","undefined");
      }

      localStorage.setItem('objectjob','medical')
      localStorage.setItem('jobpage','lostmedical')
      let pincode = localStorage.getItem('pincode') ? localStorage.getItem('pincode') : null
      this.authService.Getemergency(this.SerialNumberInput, pincode)
      .subscribe((data :any)=>{
        if ( data?.length != 0)
        {
          /* if(localStorage.getItem('sessionid') === 'undefined' ){
            this.dataService.init();
            //this.dataService.launchEvent();
            this.dataservice.chatAlreadyStarted = true;
          } */
          this.router.navigate(['/' + this.SerialNumberInput +'/chatview']);

        }
      });
      this.onSendSms("medical")
    }

      public openchatbotchild(){
        localStorage.setItem("ischatUrl",'');
      if(localStorage.getItem('oldChatbot') !== null && localStorage.getItem('currentChatbot') !== null){
        localStorage.setItem('currentChatbot','lost')
      }else{
        localStorage.setItem('oldChatbot','lost')
        localStorage.setItem('currentChatbot','lost')
      }
      if(localStorage.getItem('oldChatbot') !== localStorage.getItem('currentChatbot')){
        localStorage.setItem("sessionid","undefined");
      }

      localStorage.setItem('objectjob','medical')
      localStorage.setItem('jobpage','child');
      /* if (localStorage.getItem('sessionid') === 'undefined' ){
        this.dataService.init();
        this.dataservice.chatAlreadyStarted = true;

      } */
      this.router.navigate(['/' + this.SerialNumberInput +'/chatview']);

      /* else
      this.router.navigate(['/' + this.SerialNumberInput +'/homepage']); */

      this.onSendSms("lost_person")
      }

      onSendSms(choice: string): void{
        this.authService.sendsmsmail(this.SerialNumberInput, choice ).subscribe((datasub :any)=>{});
      }
     ngOnInit(){
      //localStorage.setItem("sessionid",undefined);
      this.dataservice.conversation  = new BehaviorSubject<any[]>([]);
      localStorage.setItem('isOwnerReply',"false")
      this.sharedData.jobpage = null ;
      this.dataService.ownerrepley=false;
      const currentUser = this.authService.currentUserValue;
      if ( currentUser=== null || currentUser.tag_info?.tag_info.active_emergency=== 0 || currentUser.tag_info?.tag_info?.emergency=== 0)
      this.router.navigate(['']);

     }



}

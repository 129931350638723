import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()

export class AuthGuard implements CanLoad, CanActivate {
    constructor(private router: Router,
        private authService: AuthService) {
    }
    canLoad(route: Route): boolean {
        return this.canShow(route);
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canShow(route);
    }

    canShow(route: Route | ActivatedRouteSnapshot): boolean {
        const currentUser = this.authService.currentUserValue;
        const pathRoute = <Route>route;
        if (currentUser) {
            if (route === undefined || route.data === undefined) {
                this.router.navigate(['home']);
                return false;
            }
            return true;
        }
        else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['']);
            return false;
        }
    }
} 
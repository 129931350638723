import { DomSanitizer } from '@angular/platform-browser';
import { Component, Input } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap"
import { MedicalInfo } from "src/app/models/MedicalInformation";
import { User } from "src/app/models/user.model";
import { DataService } from "src/app/services/chat/data.service";
import { NgbPrintModalContent } from "../../normalchat/chatview/chatview.component";
@Component({
  selector: 'ngbd-modal-content',
  styleUrls: ['./model.component.css'],
  templateUrl: './model.component.html',

})
export class NgbdModalContent {
  @Input() public  image;
  @Input() public  name;
  @Input() medicalUser: MedicalInfo;
  @Input() currentUser: User;
  @Input() type: string;
  public loading: boolean = false;
  mail :string ;
  langnum : string ;
  constructor( public activeModal: NgbActiveModal,public dataservice :DataService,private modalService: NgbModal, public sanitizer: DomSanitizer) {
  }


  printpage()
  {}

  sendmail(){
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };

    const modeldoc =this. modalService.open(NgbPrintModalContent,options);
    modeldoc.componentInstance.iduser = this.currentUser;
    modeldoc.componentInstance.body = this.medicalUser;
    modeldoc.componentInstance.name = this.name ;
  }

}


        <div class="backrow row">
        <h5 class="modal-title textlost" *ngIf="name!==null && name !== ''">Document</h5>

          <h5 class="modal-title textlost" *ngIf="name===null || name ===''">{{'editprofil_general_label_exportrecord' | translate}}</h5>
          <button type="button" class="close-modal-btn closeemail" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
             <span aria-hidden="true" class="text-white closebut">×</span>
          </button>
        </div>
        <div class="modal-body" >
          <div class="row" style="padding:15px">
          <span class="emailstyle" *ngIf="name!==null && name !== ''"> Email {{name}} to :</span>

            <span class="emailstyle text-left" *ngIf="name===null || name ===''">{{'printEmail_poster' | translate}} :</span>

          </div>
          <div class="row" style="padding:20px">
            <div class="col-md-12 col-sm form-group" [formGroup]="form">
                    <input style="background-color:#F7F5F5" type="email" formControlName="mail" matInput required [(ngModel)]="mail"  class="form-control serialNumber"  aria-describedby="SerialNumber" placeholder="name@email.com" onfocus="if (this.value == 'Type some text here') {this.value=''}" >

                     <span *ngIf="(getEmail.invalid && getEmail.touched) || getEmail.dirty">
                     <small matSuffix *ngIf="getEmail.errors?.required" class="text-danger">{{'inputchecker_label_emailrequired' | translate}}</small>
                     <small matSuffix *ngIf="getEmail.errors?.pattern" class="text-danger">{{'chatbot_script_medical_validemail' | translate}} </small>
                 </span>
                    <div class="invalid-feedback" >
                    {{'finder_response_textarea' | translate}}
                    </div>

            </div>
          </div>
            <ng-container *ngIf="loading" style="margin: 40px;">
              <mat-spinner style="stroke: #04A6BC;" [diameter]="40"></mat-spinner>
            </ng-container>
        </div>

        <div style="justify-content: center;" class="modal-footer">
         <div class="row" style="padding: 10px 10px 24px 10px;">
           <div class="col-md-12 col-sm">
              <button style="background-color:#23CE6B; color:white;" type="button" (click)="sendmail()" [disabled]="!form.valid"  class="btn btn-lg btn-block"  role="button">
                <span class="continue"  >{{'finder_medicalpage_btn_print' | translate}}</span>
             </button>
           </div>
         </div>
        </div>
  
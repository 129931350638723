<div class="modal-body">
    <div class="backrow" [style.background-color]="color">
    </div>
    <div style="text-align: -webkit-center;text-align: -moz-center;">
       
        <div class="cerclrinfo" >
          <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2142 19.2797L9.79168 15.8572L7.92847 17.7205L13.2142 23.0062L23.7856 12.4347L21.9224 10.5715L13.2142 19.2797Z" fill="#04A6BC"/>
            <path d="M15.8572 37L7.69601 32.6485C5.36941 31.4108 3.42379 29.5628 2.06794 27.303C0.712092 25.0432 -0.00279226 22.4568 8.19626e-06 19.8214V2.64286C0.000707862 1.94214 0.279376 1.27033 0.774856 0.774848C1.27034 0.279367 1.94215 0.000699666 2.64287 0H29.0714C29.7721 0.000699666 30.444 0.279367 30.9394 0.774848C31.4349 1.27033 31.7136 1.94214 31.7143 2.64286V19.8214C31.7171 22.4568 31.0022 25.0432 29.6464 27.303C28.2905 29.5628 26.3449 31.4108 24.0183 32.6485L15.8572 37ZM2.64287 2.64286V19.8214C2.64067 21.9777 3.22575 24.0939 4.3353 25.9429C5.44485 27.7918 7.03698 29.3037 8.94079 30.3162L15.8572 34.0043L22.7735 30.3175C24.6775 29.3049 26.2698 27.7928 27.3793 25.9436C28.4889 24.0944 29.0739 21.978 29.0714 19.8214V2.64286H2.64287Z" fill="#04A6BC"/>
            </svg>
        </div>
    </div>
    <div class="styletext p-3">
        <span>
          Security, privacy and compliance are in our DNA, which is why we are constantly working to ensure our solutions meet the latest global data security and encryption standards. We undergo regular external audits to ensure our services are compliant and secure.
        </span>
        <div class="row w-100 p-2 mt-3">
          <div class="col">
            <img src="/assets/img/Group 12697 1.svg"  class="img-fluid m-0">
            <img src="/assets/img/Group 12699 2 1.svg"  class="img-fluid mt-4" >

          </div>
          <img src="/assets/img/Group 12698 1.svg"  class="img-fluid m-0">

        </div>
      
    </div>
   

   
   
</div>
<div class="position-red-arrow p-2" *ngIf="errorShareLocation">
    {{'location_sharing_required' | translate}}
    <mat-icon>location_on</mat-icon>
</div>
<div *ngIf="!errorShareLocation">
    <div class="backrow" [style.background-color]="color">
    </div>
    <div class="text-center d-flex justify-content-center" style="text-align: -webkit-center;text-align: -moz-center;">
        <div class="cercle" *ngIf="number  === 1">
            <svg *ngIf="type == 'error'" width="62" height="56" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M54.9202 56H7.07905C5.83859 55.9999 4.61993 55.6735 3.54512 55.0535C2.47031 54.4335 1.57709 53.5417 0.95492 52.4674C0.332744 51.3931 0.00345386 50.174 2.70225e-05 48.9322C-0.00339981 47.6904 0.319158 46.4695 0.935394 45.3918L24.8563 3.56598C25.476 2.48253 26.3705 1.58215 27.4494 0.955966C28.5283 0.329779 29.7533 0 31.0004 0C32.2475 0 33.4725 0.329779 34.5514 0.955966C35.6303 1.58215 36.5248 2.48253 37.1444 3.56598L61.0646 45.3918C61.6809 46.4696 62.0034 47.6905 62 48.9324C61.9965 50.1743 61.6671 51.3934 61.0449 52.4677C60.4226 53.5421 59.5293 54.4339 58.4544 55.0538C57.3795 55.6737 56.1607 56 54.9202 56V56ZM31.247 41.2173C30.7091 41.1823 30.1699 41.2628 29.6655 41.4533C29.1611 41.6438 28.7032 41.9399 28.3223 42.3218C27.9415 42.7037 27.6465 43.1627 27.4571 43.668C27.2677 44.1732 27.1883 44.7131 27.2241 45.2516C27.2026 45.7817 27.2891 46.3106 27.4785 46.8062C27.6678 47.3017 27.956 47.7534 28.3254 48.1338C28.7124 48.5054 29.1706 48.7947 29.6723 48.9842C30.174 49.1737 30.7089 49.2594 31.2446 49.2363C31.7747 49.2668 32.3052 49.1846 32.8012 48.9951C33.2973 48.8056 33.7476 48.5131 34.1226 48.1368C34.4976 47.7605 34.7887 47.309 34.9768 46.812C35.1649 46.315 35.2458 45.7837 35.214 45.2532C35.2385 44.7157 35.1546 44.1789 34.9673 43.6746C34.78 43.1703 34.4931 42.709 34.1237 42.3182C33.7443 41.9484 33.2935 41.66 32.7988 41.4707C32.3042 41.2814 31.7761 41.1951 31.247 41.2173V41.2173ZM28.0556 10.7983L28.6007 36.1097H33.8413L34.3605 10.7983H28.0556Z" fill="#F4C809"/>
          </svg>
<!--             <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="68px"><linearGradient id="HoiJCu43QtshzIrYCxOfCa" x1="21.241" x2="3.541" y1="39.241" y2="21.541" gradientUnits="userSpaceOnUse"><stop offset=".108" stop-color="#0d7044"/><stop offset=".433" stop-color="#11945a"/></linearGradient><path fill="#28a79a" d="M16.599,41.42L1.58,26.401c-0.774-0.774-0.774-2.028,0-2.802l4.019-4.019	c0.774-0.774,2.028-0.774,2.802,0L23.42,34.599c0.774,0.774,0.774,2.028,0,2.802l-4.019,4.019	C18.627,42.193,17.373,42.193,16.599,41.42z"/><linearGradient id="HoiJCu43QtshzIrYCxOfCb" x1="-15.77" x2="26.403" y1="43.228" y2="43.228" gradientTransform="rotate(134.999 21.287 38.873)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#2ac782"/><stop offset="1" stop-color="#21b876"/></linearGradient><path fill="url(#HoiJCu43QtshzIrYCxOfCb)" d="M12.58,34.599L39.599,7.58c0.774-0.774,2.028-0.774,2.802,0l4.019,4.019	c0.774,0.774,0.774,2.028,0,2.802L19.401,41.42c-0.774,0.774-2.028,0.774-2.802,0l-4.019-4.019	C11.807,36.627,11.807,35.373,12.58,34.599z"/></svg>
 -->            <svg *ngIf="type == 'success'" class="svgcercle" height="40.307" viewBox="0 0 56.3 40.307" width="56.3" xmlns="http://www.w3.org/2000/svg"><g _ngcontent-cwe-c12="" data-name="Groupe 9061" id="Groupe_9061" transform="translate(396.147 -348.847)"><line _ngcontent-cwe-c12="" data-name="Ligne 1179" fill="none" id="Ligne_1179" stroke="#04a6bc" stroke-linecap="round" stroke-width="8" transform="translate(-390.5 367.5)" x2="18" y2="16"></line><line _ngcontent-cwe-c12="" data-name="Ligne 1180" fill="none" id="Ligne_1180" stroke="#04a6bc" stroke-linecap="round" stroke-width="8" transform="translate(-372.5 354.5)" x1="27" y2="29"></line></g></svg>
        </div>
        <div class="cercle" *ngIf="number === 4">
            <svg class="svgcercle" xmlns="http://www.w3.org/2000/svg" width="56.3" height="40.307" viewBox="0 0 56.3 40.307">
            <g id="Groupe_9061" data-name="Groupe 9061" transform="translate(396.147 -348.847)">
              <line id="Ligne_1179" data-name="Ligne 1179" x2="18" y2="16" transform="translate(-390.5 367.5)" fill="none" stroke="#04a6bc" stroke-linecap="round" stroke-width="8"/>
              <line id="Ligne_1180" data-name="Ligne 1180" x1="27" y2="29" transform="translate(-372.5 354.5)" fill="none" stroke="#04a6bc" stroke-linecap="round" stroke-width="8"/>
            </g>
          </svg>
        </div>
    </div>
    <div class="styletext">
        <span [style.color]="color">{{texte |translate}}</span>
    </div>
    <div class="chatmsg mb-4" *ngIf="chatinfo">
        <span>{{title | translate}}</span>
    </div>

    <div class="marg" *ngIf="number !== 3">
        <button [style.background-color]="color" type="button" (click)="closemodel()" class="btn btnclick btn-lg btn-block" role="button">
          <span >{{buttonText | translate}}</span>
        </button>

    </div>
</div>

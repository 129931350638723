
export class SharedData {
    latitude: number;
    longitude: number;
    objectjob:string ;
    jobapikey:string ; 
    jobpage:string ; 
    initSharedData() {
        this.latitude = null;
        this.longitude = null;
        this.objectjob = null;
        this.jobapikey = null;
        this.jobpage = null;
    }
}
import { Injectable, Pipe } from "@angular/core";

@Pipe({
  name: "phone",
})
export class PhonePipe {
    transform(phone: string) {
      var toString = String(phone),
      phoneNumber = toString.replace(/[^0-9]/g, ""),
      countArrayStr = phoneNumber.split(""),
      numberVar = countArrayStr?.length,
      closeStr = countArrayStr.join("");
      if (numberVar == 10) {
          var phone = closeStr.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3"); // Change number symbols here for numbers 10 digits in length. Just change the periods to what ever is needed.
      } else if (numberVar == 11) {
        var phone = closeStr.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1 $2 $3 $4"); // Change number symbols here for numbers 11 digits in length. Just change the periods to what ever is needed.
      } else if (numberVar == 8) {
        var phone = closeStr.replace(/(\d{1})(\d{3})(\d{4})/, "$1 $2 $3"); // Change number symbols here for numbers 8 digits in length. Just change the periods to what ever is needed.
      } else if (numberVar > 11) {
          var howMany = closeStr?.length,
              subtract = (10 - howMany),
              phoneBeginning = closeStr.slice(0, subtract),
              phoneExtention = closeStr.slice(subtract),
              disX = "x", // Change the extension symbol here
              phoneBeginningReplace = phoneBeginning.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3"), // Change number symbols here for numbers greater than 10 digits in length. Just change the periods and to what ever is needed.
              array = [phoneBeginningReplace, phoneExtention],
              afterarray = array.splice(1, 0, " "),
              phone = array.join("");

      } else {
          var phone = phone;
      }
      return '+'+phone;
      }
}

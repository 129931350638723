import { LoaderService } from './../../../services/loader/loader.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { LeaveRoomMessage, OutGoingMessage, SocketService } from './../../../services/socket/socket.service';
import {Observable, Subject, from ,interval,of} from 'rxjs';
import icfile from '@iconify/icons-ic/file-download';
import secure from '@iconify/icons-ic/security';
import iclocation from '@iconify/icons-ic/outline-place';
import icflechdroite from '@iconify/icons-ic/trending-flat';
import icflechgauche from '@iconify/icons-ic/keyboard-backspace';
import  icvideo from '@iconify/icons-ic/baseline-videocam';
import icReward from '@iconify/icons-ic/sharp-card-giftcard';
import { AuthService } from '../../../services/auth/auth.service';
import {  ElementRef, Component, OnInit, Input, ViewChild, Inject, EventEmitter, Output, HostListener, SecurityContext, OnDestroy } from '@angular/core';
import {  Router } from '@angular/router';
import { DataService } from 'src/app/services/chat/data.service';
import {  User } from 'src/app/models/user.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalComponent} from 'angular-5-popup';
import {  NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { ChatWindowemerComponent } from '../chat-windowemer/chat-windowemer.component';
import { NgbdPopupmodelContent } from '../../listmodal/popupmodel/popupmodel.component';
import { NgbdPopupmodelDocContent } from '../../listmodal/popupmodeldoc/popupmodeldoc.component';

declare const L: any;
 class testResult{
     result ;
 }



class Videocall{
  meeting_url ;
}

@Component({
  selector: 'Chathomeemer',
  templateUrl: './chathomeemer.component.html',
  styleUrls: ['./chathomeemer.component.css'],

})
export class ChathomeemerComponent  implements  OnInit, OnDestroy  {
  @Output() valueChange = new EventEmitter();
  @ViewChild("modal") modal: ModalComponent;
  @Input() chatUrl: string;
  @Output() countmessage :EventEmitter<any>= new EventEmitter();
  collapseIfScrolled: boolean = false;
  @ViewChild(ChatWindowemerComponent) child:ChatWindowemerComponent;
  public chatview :boolean = false ;
  public chaturl :boolean = false ;
  videocall = new Videocall ;
  public collapsedUser =false;
  icfile  =icfile;
  testsend :boolean =false;
  test : boolean = false;
  iclocation= iclocation;
  public name: string;
  public image: string;
  icvideo = icvideo ;
  icReward =icReward;
  label:string;
  pictureurl:string;
  target: any =Event;
  chat :boolean=false ;
  emergency : string ;
  icflechgauche =icflechgauche ;
  icflechdroite =icflechdroite ;
  message :string ;
  result : string ;
  photoURL: SafeUrl = null;
  document_name :string;
  langnum:string;
  @Input() collapsedcard: boolean = false;
  @Input() collapsed: number = 1;
  chatvideo : boolean=false;
  picture_url_tag:string;
  currentUser : User ;
  currentlanguage : any ;
  public msg: Subject<any> = new Subject();
  public urlSafe: any;
  public count :number ;
  windowheight : any ;
  toggle = true;
  public showMe = false;
  public loading: boolean = false;
  hasAlreadyReleased: boolean = false;
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  loadingDots: boolean = false;

  @ViewChild('scrollrow')  myScrollContainer:  ElementRef;
  emergencyContact: any;
  constructor(public sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document, private socketService: SocketService,
  private sharedService: SharedService,
    private modalService: NgbModal,private authService :AuthService, private router :Router,private dataservice :DataService,
    public loaderService: LoaderService)
    {
      interval(60000).subscribe(()=>{
        localStorage.removeItem('medicaleInfoReleased');
        localStorage.removeItem('longitudeEmergency');
        localStorage.removeItem('latitudeEmergency');
      })

      if(localStorage.getItem('medicaleInfoReleased') === 'true'){
        this.hasAlreadyReleased = true;
      }else{
        this.hasAlreadyReleased = false;
        localStorage.removeItem('medicaleInfoReleased');
      }

    this.collapsed = 0;
    this.currentUser = this.authService.currentUserValue;

    this.chatview = this.dataservice?.chatview ;
    this.chaturl = this.dataservice?.chaturl;
    if (  this.currentUser!= null)
    {
       if (  this.currentUser.tag_info){
        this.emergency =  this.currentUser.tag_info.tag_info.emergency;
        this.pictureurl = this.currentUser.tag_info.preference_user.allow_share_picture==="1"?  this.currentUser?.tag_creator?.tag_creator_profile_picture_url:null;
        this.picture_url_tag =  this.currentUser.tag_info.tag_info.picture_url=== null ? null :  this.currentUser.tag_info.tag_info.picture_url;
        if( this.currentUser.tag_info.other_info?.length > 0)
        {
          this.photoURL = this.currentUser?.tag_info.other_info[0]?.documents[0]?.data != null? this.currentUser?.tag_info.other_info[0]?.documents[0]?.data : null;
          this.document_name = this.currentUser?.tag_info.other_info[0]?.documents[0]?.document_name;

        }

      }
    }
    this.dataservice.listenVideocall().subscribe((m: Videocall) => {
      this.addVideo(m);
    })
    this.sharedService.getDiscussionEmergencyEvent()
      .subscribe((status)=>{
        if(status)
        this.emergencyContact = JSON.parse(localStorage.getItem('emergency_contact'));
      })
    this.sharedService.onDetectMessageSent().subscribe((value)=>{
      this.loadingDots = value;
      setTimeout(() => {
        this.loadingDots = false
      }, 1000);

    })
   }

   valueChanged() {
    this.valueChange.emit();
  }
   testcollapsedUser(){
    this.collapsedUser = true;
   }
   public doSomething(nummessge: any) {
    this.countmessage.emit(nummessge);
}
    @HostListener('window:beforeunload', ['$event'])
    canLeavePage($event: any): Observable<void> {
      return null;
    }
  ngOnInit() {
    this.windowheight =window.screen.height -240;
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("longitudeEmergency", position.coords.longitude?.toString());
      localStorage.setItem("latitudeEmergency",position.coords.latitude?.toString());
    })
  }

  public  onChange(target: any)    {
  //this.changeuser();
    this.collapsedUser = false ;
    //this.msg.next(target);
    if(target?.type !== 'file')
    target.value = target?.value.trim();
    if(target?.value == '') return;
    this.dataservice.converse(target, 'emergency_contact');
    if(target?.type !== 'file' && target.type !== 'location')
    this.onSendMessage(target.value)
    target.value = '';
    /* let d = this.document.getElementById('scrollrow');
    if(d)
    {
      d.scrollTop = d.scrollHeight;
    } */

  }

  onSendMessage(text){
    let  emergency_contact: any =  JSON.parse(localStorage.getItem('emergency_contact'));
    if(emergency_contact != null){
      const message: OutGoingMessage = {
        room: emergency_contact.sn,
        message: text,
      };
      this.socketService.sendMessage(message);
      this.message = '';
    }
  }

  changeuser(){
    setTimeout(() => { const chatinput = document.querySelector('Chat-bot');
    chatinput?.addEventListener('click', this.onClick.bind(this));},5000);

  }
  onClick(event) {

  }

  openchat()
{
  this.chatvideo = false ;
}
  public onMsgReceive(msg: string) {

          setTimeout(() => { let a = this.document.getElementById('scrollrow');
          if(a)
          {
            a.scrollTop = a.scrollHeight;
          }
         },2000);
  }

  opnevideocall(){

    this.chatvideo=true ;
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "videoClass"
    };

    const modeldoc =this.modalService.open(NgbdPopupmodelContent,options);
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number=3;
    modeldoc.componentInstance.color="#04A6BC";
    modeldoc.componentInstance.texte="Start a video or audio call";
    modeldoc.componentInstance.type = "alert";
    modeldoc.componentInstance.confirmationBoxTitle = 'Start call';
    modeldoc.componentInstance.confirmationMessage = 'No, Thanks';
    modeldoc.componentInstance.typeUser = 'emergency_contact';

    modeldoc.componentInstance.event.subscribe((data:any) => {
      if ( data.chatvideo)
      {

        this.startvideocall();
      }
       this.chatvideo=false ;
      modeldoc.close();
    });

  }
  openlocation()
  {
    this.dataservice.videocall().subscribe((value: Videocall) => {
      if (value) {

        this.target =  Event ;
        this.target.type = "location";
        this.target.value= value.meeting_url;
        this.onChange(this.target);
      }
    });
  }

  startvideocall()
  {
     this.dataservice.videocall().subscribe((value: Videocall) => {
      if (value) {
          this.addVideo(value)
          window.open( value.meeting_url)
        }
      } ,
      err => {
      }
     );
  }


  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {

  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    return null ;

  }
  ngOnDestroy() {
    localStorage.removeItem('emergency_contact')
   }

  onKey(event: any) {

    // if (event.keyCode !== 13 && this.testsend === false) {
    //  this.testsend = true;
    //  this.dataservice.typingmessage('start');
    // }
    // else if  (event.keyCode === 13)
    // {
    //   this.testsend = false;
    // //this.dataservice.typingmessage('stop');
    // }
  }



  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  /* leave(){
    const leaveRoom: LeaveRoomMessage = {
      'room': this.emergencyContact.sn
    }
    this.socketService.leaveRoom(leaveRoom)
  } */

   public addItem($event) {
    if( !localStorage.getItem("longitudeEmergency") && !localStorage.getItem("latitudeEmergency"))
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("longitudeEmergency", position.coords.longitude?.toString());
      localStorage.setItem("latitudeEmergency",position.coords.latitude?.toString());
      localStorage.setItem("location","true");
      localStorage.setItem("position","true");
      this.target =  Event ;
      this.target.type = "location";
      this.target.value= "yes";
      this.onChange(this.target);
    },(error)=>{
      this.openPopup("pop_erreur_case_location")
    })
    else{
      localStorage.setItem("location","true");
      localStorage.setItem("position","true");
      this.target =  Event ;
      this.target.type = "location";
      this.target.value= "yes";
      this.onChange(this.target);
    }

  }

  openPopup(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.texte=content;
    modeldoc.componentInstance.type="error";
    modeldoc.componentInstance.color="#F4C809";

     modeldoc.componentInstance.event.subscribe((data:any) => {
      modeldoc.close();
    });

  }

  public addVideo(value: Videocall) {
    this.target =  Event ;
    this.target.type = "url";
    this.target.value= value.meeting_url;
    this.onChange(this.target);
  }

  addNewItem(value: string) {
  }

  ApprouvEmerg(){
    const emergencyContact = JSON.parse(localStorage.getItem("emergency_contact"))
    this.authService.ApprouveEmereg(emergencyContact.sn)
    .subscribe(data=>{
      this.toggle =false;
      this.hasAlreadyReleased = true;
      localStorage.setItem('medicaleInfoReleased', 'true')
      let target = {
        'value': '[pincode]:'+ data.pincode,
        'type': 'text'
      }
      this.onChange(target)

   }
  );
 }

 getEmergencyScroll(event): void{
    if (!(this.collapseIfScrolled && event) || !(!this.collapseIfScrolled && !event)){
      this.collapseIfScrolled = event
    }
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/browser';


if (environment.production) {
  enableProdMode();
  Sentry.init({
    environment: 'production',
    dsn: 'https://1ccfb9a682f93d9c73ec6943af7de5f8@sentry.neopolis-dev.com/36',
    tracesSampleRate: 1.0
});
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => err);

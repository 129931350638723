
    export class System {
        turn_count: number;
    }

    export class Global {
        session_id: string;
        system: System;
    }

    export class System2 {
    }

    export class MainSkill {
        system: System2;
        user_defined  : userDefined;
    }

    export class userDefined{
      name : string  = null;
      email : string  = null;
      phone : string  = null;
      first_name : string  = null;
      last_name : string  = null;
      location: string  = null;
      id_device: string  = null;
      reward_request: number  = null;
      note: string  = null;
      longitude: number  = null;
      latitude: number  = null;
      item_etablissement: string  = null;
      item_location: string  = null;
      lost_item_info: string  = null;
  }

  export class UpdateFinderInformation{
    email: string = null;
    first_name: string  = null;
    last_name: string = null;
    lng: number = null;
    lat: number = null;
    id_device: string = null;
    lost_item_info: string = null;
    phone: string = null;
    reward_request: number = null;
    note: string = null;
}

  export class ItemInfo{

  }
    export class Skills {
        "main skill": MainSkill;
    }

    export interface Context {
        global: Global;
        skills: Skills;
    }

    export class Input {
        text: string;
    }

    export class Value {
        input: Input;
    }

    export class Option {
        label: string;
        value: Value;
    }

    export class Generic {
        response_type: string;
        text: string;
        source:string;
        options: Option[];
        title: string;
        time :number ;
        typing :boolean;
    }

    export class Output {
        entities: any[];
        generic: Generic[];
        intents: any[];
    }

    export class chatbotmessage {
        context: Context;
        texterror?: string;
        output: Output;
        message :string ;
    }




<button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white closebut" aria-hidden="true">×</span>
        </button>
        <div class="modal-body mt-0" *ngIf="!loadingAttachement">
          <ng-container *ngFor="let item of listDocuments; let index = index">
          <ng-container *ngIf="index == document_index">
            <img *ngIf="item?.image?.toLowerCase().includes('png') || item?.image?.toLowerCase().includes('jpg') || item?.image?.toLowerCase().includes('jpeg')" [src]="item.image" loading="auto" class="img-fluid img-border imgmodel"  alt="Responsive image">
            <div class="col" style="word-break: break-word;">
            <div class="row " class="name-document-content">
                <p class="modelname mt-3 mb-0">{{item?.title}}</p>
            </div>
          </div>
          </ng-container>
        </ng-container>
<!--           <div class="mt-5">
            <a style="display: grid" class="justify-content-center" [href]="image" target="_blank">
            <mat-icon style="font-size: 25px;" class="m-auto" *ngIf="!image.includes('png') && !image.includes('jpg') && !image.includes('jpeg')"> attach_file</mat-icon>
            <span *ngIf="!image.includes('png') && !image.includes('jpg') && !image.includes('jpeg')">Document</span>
            </a>
          </div> -->
        </div>
        <div  class="modal-footer">

        <div class="row w-100" style="justify-content: space-between;margin: 0px 0px 11px;">
        <span>
            <button type="button" *ngIf="document_index !== 0 " class="icon2"  (click)="Documentsuivant('precedant')">
              <img src="/assets/img/flech.png" style=" transform: rotate(-180deg);" >
            </button>
        </span>
        <span>
            <button type="button" *ngIf="(document_index+1) !== listDocuments?.length" class="icon2" (click)="Documentsuivant('next')">
              <img src="/assets/img/flech.png"  >
            </button>
        </span>
         </div>
        </div>
import { OutGoingMessage, SocketService } from './../../../services/socket/socket.service';
import { TranslateService } from '@ngx-translate/core';
import { UpdateFinderInformation } from './../../../models/chatbotmessage';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2, ViewChild, ɵConsole} from '@angular/core';
import { ChathomeComponent } from '../../normalchat/chathome/chathome.component';
import {DataService} from '../../../services/chat/data.service';
import iclocation from '@iconify/icons-ic/outline-place';
import { ChatService } from '../../../services/chat/chat.service';
import { ClipboardService } from 'ngx-clipboard';
import { MapsAPILoader} from '@agm/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Message } from 'src/app/models/message';
import {  Generic } from '../../../models/chatbotmessage';
import { Observable } from 'rxjs';
import { DatePipe, DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ESendBy } from 'src/app/models/EsendBy';
declare const L: any;
class Position{
  coords :Coords
}
class Coords{
  longitude ;
  latitude ;
}
@Component({
  selector: 'chat-msgemer',
  templateUrl: './chat-msgemer.component.html',
  styleUrls: ['./chat-msgemer.component.scss'],
})
export class ChatMsgemerComponent implements OnInit {
  @Input() msg: Message;
  @Input() sender:string;
  @Input() collapsed: any;
  collapsedimage : boolean=false;
  collapsedmap = false;
  @Output() onMsgReceive = new EventEmitter();
  @Input() collapsedUser: boolean = false ;
  lat :string ='';
  lng:string ='';
  location :Object;
  iclocation =iclocation;
  @ViewChild('txtConfigFile') txtConfigFile: ElementRef;

  @Output() target: any =Event;
  @ViewChild('myDiv') myDiv: ElementRef<HTMLElement>;
  srcvideo : SafeUrl ;

  @Output() change = new EventEmitter();
  sendlocation:boolean = false ;
  private gmap: ElementRef;
  firstRemoveClass: boolean = true;
  permissionLocationNotAllowed: boolean = false;

  @ViewChild('gmap', { static: false }) set content(content: ElementRef) {
    if(content) {
        this.gmap = content;
    }
  }
  @ViewChild('notbot') notbot:ElementRef;

  locationmap  :boolean=false ;
  position : Position ;
  currentlanguage : any ;
   map:any;
   langnum:string;
   currentLong: any;
   loader: boolean = true;
   marker: google.maps.Marker;
   mesg :Message ;
   originalmsg:boolean = true ;
   translatedmsg:boolean = false ;
  public messagedata:Generic ;
   Currentposition : boolean=false ;
   center: google.maps.LatLngLiteral
  public isVisible = true;

  constructor(@Inject(DOCUMENT) private document: Document,private renderer:Renderer2 ,private clipboardService: ClipboardService,private authService :AuthService , private mapsAPILoader: MapsAPILoader,public dataService: DataService
  ,public chatservice :ChatService,private sanitizer: DomSanitizer ,public ChathomeComponent :ChathomeComponent, private translateService: TranslateService, private socketService: SocketService, private datePipe: DatePipe) {
    Window["myComponent"] = this;
  }

  ngOnInit() {
      this.langnum =localStorage.getItem('browsercode')
      this.currentlanguage= this.authService.currentLanguagesValue ;
      if ( this.msg.location != null){
        if(this.msg.sendBy != "user"){
          let position = new Position ;
          position.coords = new Coords ;
          position.coords.longitude = this.msg.location.split(',')[1];
          position.coords.latitude =this.msg.location.split(',')[0];
            this.initmapOwner(position.coords.longitude, position.coords.latitude)
        }
        else if( !localStorage.getItem("longitudeEmergency") && !localStorage.getItem("latitudeEmergency"))
        navigator.geolocation.getCurrentPosition((position) => {
          localStorage.setItem("longitudeEmergency", position.coords.longitude?.toString());
          localStorage.setItem("latitudeEmergency",position.coords.latitude?.toString());
          this.initmap();
        },(error)=>{
          this.permissionLocationNotAllowed = true;
        });
        else{
          this.initmap();
        }
      }
      let a = this.document.getElementById('scrollrow');
        if(a)
        {
          a.scrollTop = a.scrollHeight;
        }
      if (this.msg?.video != null || this.msg?.text?.includes('[video]:'))
      this.convertEmbededVideo(this.msg?.video || this.msg?.text.replace('[video]:',''));
      this.collapsed = this.dataService.collapsedUser ;


  }

  convertEmbededVideo ( video)
  {
    let source: string = video.replace('https://www.youtube.com/watch?v=' , '')
    if(source.includes('&'))
    source = source.replace(source.substring(source.indexOf('&')),'')
    this.srcvideo =this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+source) ;
  }

  @HostListener('window:beforeunload', ['$event'])
  canLeavePage($event: any): Observable<void> {

    return null;
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  translationmessage(msg : any){
    let text = '';
    if(msg?.text){
      if(msg?.text.includes('[pincode]:')){
        this.translateService.get('finder_emergencycontact_release_msginfo').subscribe((message: string) => { text = message })
      }
      else
      text = msg?.text
    }else if(msg?.data?.title){
      text = msg?.data?.title
    }
    if(text){
      this.authService.translationMessage(text).subscribe((res:any)=>{
        if(!res?.translation?.translations || res?.translation?.translations?.length > 0){
          if (!res?.translation?.translations || !res?.translation?.translations[0]?.translation ){
            this.originalmsg = false ;
            this.translatedmsg=true;
            return;
          }
        }

       this.notbot.nativeElement.classList.add('translateMsg');
       const span = this.renderer.createElement('span');
       span.setAttribute("id", "idTraduction");

     if(this.firstRemoveClass){
       if(this.notbot?.nativeElement?.firstChild?.id == 'originalMessage'){
        this.notbot.nativeElement.firstChild.style.display = 'none';
        this.firstRemoveClass = false;
       } else if(this.notbot?.nativeElement?.firstElementChild?.id == 'originalMessage' ){
          this.notbot.nativeElement.firstElementChild.style.display = 'none';
          this.firstRemoveClass = false;
       }

     }

     let translatedText: string = res.translation?.translations[0]?.translation
     const text = this.renderer.createText(translatedText);
     this.renderer.appendChild(span, text);
     //this.renderer.appendChild(this.notbot.nativeElement, span);
     this.renderer.insertBefore(this.notbot.nativeElement, span, this.notbot.nativeElement.firstChild);
     this.originalmsg = false ;
     this.translatedmsg=true;
     });
    }
  }
  Seeoriginal(msg: any){
    const childElements = this.notbot.nativeElement.children;
    for (let child of childElements) {
      if(child.id  == 'idTraduction' || child.id  == 'originalMessage')
      this.renderer.removeChild(this.notbot.nativeElement, child);
    }

    this.notbot.nativeElement.classList.remove('translateMsg');
    let text;
    const balisep = this.renderer.createElement('p');
    balisep.setAttribute("id", "idTraduction");
    if(msg.text != null){
      if(msg?.text.includes('[pincode]:')){
        this.translateService.get('finder_emergencycontact_release_msginfo').subscribe((message: string) => {text = this.renderer.createText(message)})
      }
      else
      text = this.renderer.createText(msg?.text)
    }
    else if(msg?.data?.title != null)
    text = this.renderer.createText(msg.data.title)
    this.renderer.appendChild(balisep,text);
    //this.renderer.appendChild(this.notbot.nativeElement, balisep);
    this.renderer.insertBefore(this.notbot.nativeElement, balisep, this.notbot.nativeElement.firstChild);

    this.originalmsg = true ;
    this.translatedmsg=false;
  }

  trustUrl(url: string): SafeUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(url) ;
  }


  initmap(){
    const latitude: number = parseFloat( localStorage.getItem('latitudeEmergency'));
    const longitude: number = parseFloat( localStorage.getItem('longitudeEmergency'));
    this.mapsAPILoader.load().then(() => {
      var mapProp = {
        center: new google.maps.LatLng(latitude, longitude, false),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

        if (this.gmap!= null)
        {
          this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
          if( this.msg.sendBy === ESendBy.user)
          this.showPosition(latitude, longitude);
          else
          {
          this.position = new Position ;
          this.position.coords = new Coords ;
          this.position.coords.longitude = this.msg.location.split(',')[1];
          this.position.coords.latitude =this.msg.location.split(',')[0];
          this.trackposition(this.position.coords.longitude, this.position.coords.latitude);
          }
        }
    });
  }

  initmapOwner(longitude, latitude){
    this.mapsAPILoader.load().then(() => {
      var mapProp = {
        center: new google.maps.LatLng(latitude, longitude, false),
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
        if (this.gmap!= null)
        {
          this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
          if( this.msg.sendBy === ESendBy.user)
          this.showPosition(latitude, longitude);
          else
          {
          this.position = new Position ;
          this.position.coords = new Coords ;
          this.position.coords.longitude = this.msg.location.split(',')[1];
          this.position.coords.latitude =this.msg.location.split(',')[0];

          this.trackposition( this.position.coords.longitude, this.position.coords.latitude);
          }
        }
    });
  }

ngOnDestroy(){
}

isNumber(value) {
  if (Number(value))
  return true;
  return false ;
}
copyContent(val) {
     this.clipboardService.copyFromContent(val)
}
// test(val){

//     this.target.type = "text";
//     if ( val === "share_localisation")
//     { this.msg.location = 'map';
//           localStorage.setItem("location","true");
//           this.target.type = 'location';
//           this.target.value=" ";
//     }
//     else
//     this.target.value=val;
//     this.ChathomeComponent.onChange(this.target);

// }

public changeinput(target: any) {
    this.target.type = "text";
}


findMe() {
    localStorage.setItem("location","false");
    /* if (navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position.coords.latitude, position.coords.longitude);
      });
    }
    else
    {
      //alert("Geolocation is not supported by this browser.");
    } */

  }
  trackposition(longitude,latitude) {
    this.locationmap = true ;

    this.Currentposition=false;
    this.moveToLocation(latitude, longitude);


}

 showPosition(lat: number, long: number) {
    this.locationmap = true ;

    let dateString = this.datePipe.transform(new Date(), 'hh:mm a');

    this.moveToLocation(lat, long);

    if ( this.msg.time=== dateString)
    {
      let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
      updateFinderInformation.lng = long;
      updateFinderInformation.lat = lat;
      /* this.dataService.updateUser(null,updateFinderInformation).pipe()
      .subscribe();
      if (!this.Currentposition)
        this.dataService.SendmessageEmergency("[position]:"+lat+","+long).subscribe(
        (data :any)=>{}); */
       // this.onSendMessage("[position]:"+lat+","+long)

    }
    this.Currentposition=false;
  }

    moveToLocation(latitude , longitude){
      let location = new google.maps.LatLng(latitude, longitude);
    this.mapsAPILoader.load().then(() => {
     if (this.map!= undefined )
      this.map.panTo(location);
    });
    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
   }
   else {
    this.marker.setPosition(location);
   }
  }

  trackPosition(position) {
    let liststring = position?.toString().split(",");
    this.moveToLocation(liststring[0],liststring[1]);;
  }

  onClick(event) {
  }

  convertingCommandsToText(commands:string): string{
    let returnedText: string = '';
    if(commands?.includes('['))
    commands = commands.substring(0, commands.indexOf('['))
    let splitedList: any[] = commands?.split(' ');
    for (let index = 0; index < splitedList?.length; index++) {
      const element:string = splitedList[index];

      if(element.includes('chatbot_script')){
        returnedText += ' ';
        this.translateService.get(element).subscribe((text: string) => {returnedText += text})
      }else{
        returnedText += ' ';
        returnedText += element
      }
    }

    return this.removeHtmlCodeFromText(returnedText.trim());

  }

  removeHtmlCodeFromText(text: string): string{
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  onSendMessage(text){
    let  emergency_contact: any =  JSON.parse(localStorage.getItem('emergency_contact'));
    if(emergency_contact != null){
      const message: OutGoingMessage = {
        room: emergency_contact.sn,
        message: text,
      };
      this.socketService.sendMessage(message);
    }
  }
}








<div class="container-fluid">
    <div class="titlechat">
        <!-- [ngClass]="{'visible' : chatview, 'hidden': !chatview}"-->
        <!--  <div class="loader-position" *ngIf=" !loadingDots">
            <div class="col-3">
                <div class="snippet" data-title=".dot-pulse">
                    <div class="stage">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="header-center">

            <div class="flexrow" [ngStyle]=" { 'display': (!chaturl) ? 'none': 'flex'} ">
                <!--!hasAlreadyReleased && -->
                <div class="display-7 font-medium m-0 ng-tns-c504-506 " fxLayout="row " *ngIf="emergencyContact?.is_medical == 'True' && emergencyContact?.owner_of_this_tag_require_medical_record_protection == true && !hasAlreadyReleased">
                    <button class="card cardtop" (click)="ApprouvEmerg()" [ngClass]="{ 'white' : !toggle, 'redbutton': toggle, 'custom-window': collapseIfScrolled}" type="button ">
                        <div class="card-title m-0 p-0">
                                <svg class="Ellipse" width="30 " height="30 " viewBox="0 0 30 30 " fill="none " xmlns="http://www.w3.org/2000/svg ">
                                    <path d="M29.5 15C29.5 23.0081 23.0081 29.5 15 29.5C6.99187 29.5 0.5 23.0081 0.5 15C0.5 6.99188 6.99187 0.5 15 0.5C23.0081 0.5 29.5 6.99188 29.5 15Z " fill="white " stroke="#4F4F4F "/>
                                    <path fill-rule="evenodd " clip-rule="evenodd " d="M14.6031 10.3602C13.8644 10.3602 13.2051 10.8852 12.9975 11.5995C12.9181 11.8742 12.9181 12.155 12.9487 12.4542C13.028 13.2662 13.7301 13.9804 14.3833 14.3773C14.481 14.4383 14.6214
                                14.536 14.7069 14.5604L14.7374 15.8791C14.7435 16.0561 14.7741 16.1111 14.7741 16.3187C14.3528 16.4163 13.4187 17.0757 13.4187 17.7717C13.4187 18.4737 14.4016 19.1941 14.8412 19.2979C14.8412 19.7497 14.8778 20.1648 14.8778 20.6532C14.8778 21.1416
                                14.7741 21.7338 15.2136 21.7338C15.6898 21.7338 16.8375 21.0561 16.8375 20.177C16.8375 19.7741 16.4285 19.3345 16.1599 19.1941L16.1172 20.0061C16.1172 20.0855 16.4712 20.5433 15.5494 21.1599C15.5494 19.9267 15.6165 18.7851 15.6165 17.5702C15.3662
                                17.6312 14.9999 17.8449 14.8046 17.9792V18.4188C14.652 18.3394 14.2612 18.0403 14.2612 17.9121C14.2612 17.5274 14.5421 17.4298 14.9389 17.2344L15.8241 16.8315C16.3797 16.6056 17.0695 16.3126 17.0695 15.5433C17.0695 14.9695 16.6422 14.6276 16.2881
                                14.3895C16.2881 15.4334 16.2393 15.58 15.6776 15.8791C15.6776 15.2259 15.7142 14.6886 15.7142 14.0537L15.7447 12.6984C15.7447 12.4969 15.7814 12.4542 15.7814 12.2588C15.4212 12.2527 15.2563 12.1245 14.6275 12.1245C14.6275 12.387 14.6642 12.5397
                                14.6642 12.7655C14.6642 12.9914 14.6642 13.2173 14.6642 13.4432C14.481 13.3455 14.2734 13.1318 14.1574 12.9731C13.9132 12.6373 13.7362 12.0574 14.1025 11.7277C14.2429 11.5995 14.4016 11.5568 14.652 11.5262C15.0549 11.4774 16.6422 12.094 16.3125
                                11.1294C16.227 10.8852 16.0134 10.7265 15.8241 10.5983L15.8607 8.84003C15.8607 8.06469 14.481 8.01585 14.5665 9.01097C14.5848 9.21854 14.5726 9.46884 14.5726 9.68252C14.5665 9.95724 14.6031 10.1221 14.6031 10.3602ZM13.3699 5.08545H16.63C17.1855
                                5.08545 17.6434 5.54332 17.6434 6.09888V10.3358L21.282 8.16237C21.7582 7.87544 22.387 8.03417 22.6678 8.51036L24.3406 11.3126C24.6275 11.7887 24.4688 12.4176 23.9926 12.6984L20.1465 15L23.9926 17.3016C24.4688 17.5885 24.6275 18.2112 24.3406 18.6874L22.6678
                                21.4896C22.3809 21.9658 21.7582 22.1245 21.282 21.8376L17.6434 19.6642V23.9011C17.6434 24.4566 17.1855 24.9145 16.63 24.9145H13.3699C12.8143 24.9145 12.3565 24.4566 12.3565 23.9011V19.6642L8.71789 21.8376C8.2417 22.1245 7.61288 21.9658 7.33205
                                21.4896L5.65927 18.6874C5.37234 18.2112 5.53107 17.5824 6.00726 17.3016L9.85342 15L6.00726 12.6984C5.53107 12.4115 5.37234 11.7887 5.65927 11.3126L7.33205 8.51036C7.61898 8.03417 8.2417 7.87544 8.71789 8.16237L12.3565 10.3358V6.09888C12.3565 5.54332
                                12.8143 5.08545 13.3699 5.08545Z " fill="#E82127 "/>
                                </svg>


                        </div>
                        <div  class="pb-0">
                             <p class="card-text mb-1" [ngStyle]="{ 'color': toggle ? 'white': '#4f4f4f'} ">RELEASE</p>
                            <p *ngIf="!collapseIfScrolled" class="card-text" [ngStyle]="{ 'color': toggle ? 'white': '#4f4f4f'} ">{{'finder_medicalpage_label_medicalinfo' | translate}}</p>

                        </div>
                      </button>
                </div>
                <div class="card btn cardtop" [ngClass]="{ 'white' : chatvideo, 'blueborder': !chatvideo ,'custom-window': collapseIfScrolled}" (click)="openchat()">
                    <div class="card-title ">
                        <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg ">
                        <path fill-rule="evenodd " clip-rule="evenodd " d="M5.02125 20.4263C5.21587 20.6216 5.365 20.8575 5.45802 21.1171C5.55103 21.3767 5.58564 21.6536 5.55937 21.9281C5.4276 23.1986 5.17777 24.454 4.81312 25.6781C7.42875 25.0725 9.02625 24.3713
                    9.75187 24.0037C10.1634 23.7953 10.6374 23.7459 11.0831 23.865C12.3608 24.2056 13.6777 24.3771 15 24.375C22.4925 24.375 28.125 19.1119 28.125 13.125C28.125 7.14 22.4925 1.875 15 1.875C7.5075 1.875 1.875 7.14 1.875 13.125C1.875 15.8775 3.03187
                    18.4312 5.02125 20.4263ZM4.09687 27.7481C3.65263 27.8362 3.20694 27.9168 2.76 27.99C2.385 28.05 2.1 27.66 2.24813 27.3113C2.41461 26.9186 2.5672 26.5203 2.70562 26.1169L2.71125 26.0981C3.17625 24.7481 3.555 23.1956 3.69375 21.75C1.39313 19.4438
                    0 16.425 0 13.125C0 5.87625 6.71625 0 15 0C23.2838 0 30 5.87625 30 13.125C30 20.3738 23.2838 26.25 15 26.25C13.5143 26.252 12.0349 26.0591 10.5994 25.6763C9.62437 26.1694 7.52625 27.0675 4.09687 27.7481Z "  [ngStyle]="{ 'fill': chat ?
                    '#4F4F4F': '#4f4f4f'} "/>
                        <path d="M9.375 13.125C9.375 13.6223 9.17746 14.0992 8.82582 14.4508C8.47419 14.8025 7.99728 15 7.5 15C7.00272 15 6.52581 14.8025 6.17417 14.4508C5.82254 14.0992 5.625 13.6223 5.625 13.125C5.625 12.6277 5.82254 12.1508 6.17417 11.7992C6.52581
                    11.4475 7.00272 11.25 7.5 11.25C7.99728 11.25 8.47419 11.4475 8.82582 11.7992C9.17746 12.1508 9.375 12.6277 9.375 13.125ZM16.875 13.125C16.875 13.6223 16.6775 14.0992 16.3258 14.4508C15.9742 14.8025 15.4973 15 15 15C14.5027 15 14.0258 14.8025
                    13.6742 14.4508C13.3225 14.0992 13.125 13.6223 13.125 13.125C13.125 12.6277 13.3225 12.1508 13.6742 11.7992C14.0258 11.4475 14.5027 11.25 15 11.25C15.4973 11.25 15.9742 11.4475 16.3258 11.7992C16.6775 12.1508 16.875 12.6277 16.875 13.125ZM24.375
                    13.125C24.375 13.6223 24.1775 14.0992 23.8258 14.4508C23.4742 14.8025 22.9973 15 22.5 15C22.0027 15 21.5258 14.8025 21.1742 14.4508C20.8225 14.0992 20.625 13.6223 20.625 13.125C20.625 12.6277 20.8225 12.1508 21.1742 11.7992C21.5258 11.4475 22.0027
                    11.25 22.5 11.25C22.9973 11.25 23.4742 11.4475 23.8258 11.7992C24.1775 12.1508 24.375 12.6277 24.375 13.125Z " [ngStyle]="{ 'fill': chat ? '#4F4F4F': '#4f4f4f'} "/>
                    </svg>

                    </div>
                    <p *ngIf="!collapseIfScrolled" class="card-text">{{'finder_medicalpage_label_chat' | translate}}</p>
                </div>

                <div class="card btn cardtop" (click)="opnevideocall() " [ngClass]="{'white' : !chatvideo, 'blueborder': chatvideo, 'custom-window': collapseIfScrolled}">
                    <div class="card-title ">
                        <svg width="33 " height="33 " viewBox="0 0 33 33 " fill="none " xmlns="http://www.w3.org/2000/svg ">
                          <path d="M25.6578 29.2772C18.9485 33.877 11.8247 31.625 9.10148 29.924C7.22172 30.5709 3.33207 31.8646 2.81152 31.8646C2.29097 31.8646 2.06444 31.4333 2.01624 31.2177L4.04059 24.7493C-0.528674 16.2397 3.6309 9.08133 6.28184 6.56583C8.499
                            4.21803 14.7118 0.198024 21.826 2.90039C28.9402 5.60275 31.3453 11.4052 31.6586 13.9686C32.4539 17.1549 32.3671 24.6774 25.6578 29.2772Z " [ngStyle]="{ 'stroke': !chatvideo ? '#4F4F4F': 'white'} " stroke-width="2 "/>
                          <path d="M9.1737 12.2437H19.8739C20.3366 12.2437 20.5487 12.7228 20.5969 12.9624V15.0466C21.4885 14.5196 23.4743 13.3073 24.2841 12.6749C25.0938 12.0424 25.3927 12.7468 25.4409 13.178V21.0838C25.4409 21.9463 24.7661 21.7786 24.4287 21.5869L20.5969
                          19.0714C20.621 19.5745 20.6547 20.7245 20.5969 21.2994C20.539 21.8744 20.0908 21.9702 19.8739 21.9463H9.1737C8.53747 21.9463 8.37842 21.5151 8.37842 21.2994V12.9624C8.37842 12.3874 8.90861 12.2437 9.1737 12.2437Z " [ngStyle]="{ 'stroke': !chatvideo ? '#4F4F4F': 'white'} " stroke-width="2 "/>
                        </svg>
                    </div>
                    <p *ngIf="!collapseIfScrolled" class="card-text">{{'finder_chat_videocall'|translate}}</p>
                </div>
                <!-- <button (click)="leave()">leave</button> -->
            </div>


        </div>
        <!-- <div *ngIf="toggle" class="bordercritical text-center mb-4 mt-4 ml-auto mr-auto">
            <div class="titleborder p-0">
                <mat-label class="typesize" style="color: #FFFFFF;">{{'INFORMATION'| translate }}</mat-label>
            </div>

            <div class="row mr-0 mx-0 py-2">
                <mat-label style="color: #FFFFFF; "><strong>{{'finder_case_infoprotection_message' | translate}}</strong></mat-label>
            </div>
        </div> -->
    </div>

    <div id=" ">
        <Chat-botemer class="chat-window " (countChanged)="doSomething($event) " [msg]="msg " [msgTemplate]='message' [inputTemplate]='input' (onMsgReceive)="onMsgReceive($event) " (scrollEmergencyChange)="getEmergencyScroll($event)" [isMedicaleReleased]="toggle">
            <ng-template #window>
            </ng-template>
        </Chat-botemer>
        <ng-template #message let-text="text " let-object="object " let-video="video " let-url="url " let-image="image " let-sendBy="sendBy " let-location="location " let-num="num " let-time="time ">

        </ng-template>
        <ng-template #input style="align-items: center; ">
            <chat-inputemer (sendMessageEvent)="onChange($event); " (newItemEvent)="addItem($event) "></chat-inputemer>
        </ng-template>
    </div>


</div>

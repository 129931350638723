
        <button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white" aria-hidden="true">×</span>
        </button>
        <div class="modal-body mt-0">
          <img [src]="image" class="img-fluid" alt="Responsive image">
        </div>
        <div  class="modal-footer">
        <div class="col">
          <div class="row  " style="justify-content: center;" >
              <p class="modelname">{{name}}</p>
          </div>
          <div class="row" style="justify-content: space-between;margin: 0px 0px 11px;">
            <button [disabled]="isFirstDoc()" class="icon2"  (click)="Documentavant()">
              <img src="/assets/img/flech.png" style=" transform: rotate(-180deg);" >
            </button>
            <button  [disabled]="isLastDoc()"  class="icon2" (click)="Documentsuivant()">
              <img src="/assets/img/flech.png" >
            </button>
          </div>
         </div>
        </div>
  
<button type="button" color="primary" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  <span class="text-white close-button-modal-2" aria-hidden="true">×</span>
</button>

<!-- <iframe class="w-100" [ngClass]="{'modal-custom-height-content': image.includes('pdf')}" *ngIf="image.includes('pdf')" [src]="sanitizer.bypassSecurityTrustResourceUrl(image)">
</iframe> -->

<div *ngIf="!image.includes('pdf')" class="modal-body mt-0" id="printsection">
    <img [src]="image" class="img-fluid imgborder " alt="Responsive image">
</div>
<div class="modal-footer col">
    <div class="row">
        <span class="name">{{name}}</span>
    </div>
    <!-- Hide PRINT & EXPORT -->
    <div *ngIf="type === 'print'" class="row">
        <div class="text-center pad-de-button pr-0">
            <button class="card btn-print" printSectionId="printsection" [printTitle]="name" ngxPrint>
        <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 4V1H23.5V4M6.5 4H1V19.5H6.5M6.5 4H23.5M23.5 4H29V19.5H23.5M23.5 19.5V25.5H6.5V19.5M23.5 19.5V14H6.5V19.5" stroke="#BE303C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="card-text">{{'editprofil_medical_btn_print' |translate}}</p>

      </button>

        </div>
        <!-- <div class="text-center">
            <button class="card btn-print" (click)="sendmail()">
          <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.8571 0H2.14286C1.57454 0 1.02949 0.225765 0.627628 0.627628C0.225765 1.02949 0 1.57454 0 2.14286V19.2857C0 19.854 0.225765 20.3991 0.627628 20.8009C1.02949 21.2028 1.57454 21.4286 2.14286 21.4286H27.8571C28.4255 21.4286 28.9705 21.2028 29.3724 20.8009C29.7742 20.3991 30 19.854 30 19.2857V2.14286C30 1.57454 29.7742 1.02949 29.3724 0.627628C28.9705 0.225765 28.4255 0 27.8571 0ZM25.5 2.14286L15 9.40714L4.5 2.14286H25.5ZM2.14286 19.2857V3.11786L14.3893 11.5929C14.5686 11.7173 14.7817 11.784 15 11.784C15.2183 11.784 15.4314 11.7173 15.6107 11.5929L27.8571 3.11786V19.2857H2.14286Z" fill="#BE303C"/>
            </svg>
           <p class="card-text" >{{'editprofil_medical_btn_email' | translate}}</p>

        </button>
        </div> -->
    </div>
</div>

import { ParamApplication } from 'src/app/models/ParamApp';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.scss']
})
export class InfosComponent implements OnInit {

  name: string = 'success'; //hardcode for test
  email: string = '';
  code: string = '';
  instagramLink: string;
  linkedInLink: string;
  facebookLink: string;
  youtubeLink: string;
  termOfUseLink: string;
  privacyPolicyLink: string;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService)
  {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.code = params.get('code');
      this.name = params.get('name');
      this.email = params.get('email');
    });
  }

  ngOnInit(): void {
    this.authService.getParamApp().subscribe((response: ParamApplication)=>{
      this.instagramLink = response.data['FM_instagram']
      this.facebookLink = response.data['FM_facebook']
      this.linkedInLink = response.data['FM_linkedin']
      this.youtubeLink = response.data['FM_youtube']
      this.privacyPolicyLink = response.data['privacypolicy_url']
      this.termOfUseLink = response.data['termsofuse_url']
    })
  }
}

import { UpdateFinderInformation } from './../../../models/chatbotmessage';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/chat/data.service";
import { DomSanitizer } from '@angular/platform-browser';
import { User } from 'src/app/models/user.model';

class data {
  showMe;
  loading;
  chatvideo ;
  urlSafe;
}
class Videocall{
  meeting_url ;
}
@Component({
  selector: 'ngbd-popupreward-content',
  styleUrls: ['./popupreward.component.css'],
  templateUrl: './popupreward.component.html',

})
export class NgbdPopuprewardContent {
  public color="#04A6BC";
  showMe :boolean;
  chatvideo  :boolean;
  public event: EventEmitter<any> = new EventEmitter();
  loading :boolean;
  datamodel = new  data ;
  isSubmited: boolean = false;

  rewardForm: FormGroup;
  constructor(public dataservice :DataService,public sanitizer: DomSanitizer, private _formBuilder: FormBuilder) {
  }
  ngOnInit(){

    this.rewardForm = this._formBuilder.group({
      email: new FormControl('',[
        Validators.required,
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$")]),
      name: new FormControl('', Validators.required)
    })
  }

  public navigate()
  {
    const currentUser: User =  JSON.parse(localStorage.getItem('currentUser'));

    this.isSubmited = true;
    let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
      updateFinderInformation.email = this.rewardForm.value.email;
      updateFinderInformation.first_name = this.rewardForm.value.name;
      updateFinderInformation.reward_request = 1;
      let navigatorLanguage = localStorage.getItem("languageId") || 48
    this.dataservice.updateUser(currentUser?.finder_info?.id, updateFinderInformation, navigatorLanguage).pipe()
      .subscribe((data)=>{

      });

  }
  public closemodel()
  { this.event.emit( this.datamodel);

  }

  get getEmail(){
    return this.rewardForm.get('email')
    }

    get getName(){
    return this.rewardForm.get('name')
    }

}

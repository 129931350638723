import { NgbdModalContent } from './../../listmodal/model/model.component';
import { LeaveRoomMessage, OutGoingMessage, SocketService } from './../../../services/socket/socket.service';
import { SharedService } from './../../../services/shared-service/shared.service';
import {BehaviorSubject, Observable, Subject, interval} from 'rxjs';
import icfile from '@iconify/icons-ic/file-download';
import secure from '@iconify/icons-ic/security';
import iclocation from '@iconify/icons-ic/outline-place';
import icflechdroite from '@iconify/icons-ic/trending-flat';
import icflechgauche from '@iconify/icons-ic/keyboard-backspace';
import  icvideo from '@iconify/icons-ic/baseline-videocam';
import icReward from '@iconify/icons-ic/sharp-card-giftcard';
import { AuthService } from '../../../services/auth/auth.service';
import { ElementRef, Component, OnDestroy, OnInit, Input, ViewChild, Inject, EventEmitter, Output, HostListener, LOCALE_ID, AfterViewInit } from '@angular/core';
import {  CanDeactivate, Router } from '@angular/router';
import { DataService } from 'src/app/services/chat/data.service';
import {  User } from 'src/app/models/user.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalComponent} from 'angular-5-popup';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { ChatWindowComponent } from '../chat-window/chat-window.component';
import { NgbdPopupmodelContent } from '../../listmodal/popupmodel/popupmodel.component';
import { NgbdPopupconfidContent } from '../../listmodal/popupconfid/popupconfid.component';
import { NgbdPopuprewardContent } from '../../listmodal/popupreward/popupreward.component';


import { SharedData } from 'src/app/models/sharedData.model';
import { NgbdPopupmodelDocContent } from '../../listmodal/popupmodeldoc/popupmodeldoc.component';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Message } from 'src/app/models/message';
import { ESendBy } from 'src/app/models/EsendBy';

declare const L: any;
 class testResult{
     result ;
 }
 class Photo{
  error ;
  url;
}


class Videocall{
  meeting_url ;
}

@Component({
  selector: 'Chathome',
  templateUrl: './chathome.component.html',
  styleUrls: ['./chathome.component.css'],
  providers:[
    { provide: LOCALE_ID, useValue: localStorage.getItem('browsercode')  },
  ]

})
export class ChathomeComponent  implements  OnInit ,OnDestroy, AfterViewInit  {
  @Output() valueChange = new EventEmitter();
  @ViewChild('gmap', {static: false}) gmapElement: ElementRef<HTMLElement>;
  @ViewChild("modal") modal: ModalComponent;
  @Output() countmessage :EventEmitter<any>= new EventEmitter();
  collapseIfScrolled: boolean= false;
  @ViewChild(ChatWindowComponent) child:ChatWindowComponent;
  public chatview :boolean = false ;
  public chaturl :boolean = false ;
  chatreward: boolean=false;
  chatconf: boolean=false;
  chat:boolean = true ;
  videocall = new Videocall ;
  map: google.maps.Map;
  marker: google.maps.Marker;
  disabled_btns = false;
  location:boolean =false ;
  displayedImage :string ;
  public collapsedUser =false;
  icfile  =icfile;
  testsend :boolean =false;
  secure= secure;
  testDoc : boolean = false;
  photo =new Photo();
  testmessage = new testResult();
  iclocation= iclocation;
  animal: string;
  public name: string;
  public image: string;
  icvideo = icvideo ;
  icReward =icReward;
  userName: string;
  label:string;
  mobile: string;
  mail:string;
  pictureurl:string = null;
  target: any =Event;
  emergency : string ;
  icflechgauche =icflechgauche ;
  icflechdroite =icflechdroite ;
  message :string ;
  result : string ;
  photoURL: SafeUrl = null;
  document_name :string;
  langnum:string;
  @Input() collapsedcard: boolean = false;
  @Input() collapsed: number = 1;
  public showMe = false;
  closeResult: string;
  public urlSafe: any;
  currentLat: any;
  currentLong: any;
  messageenter:string ='';
  public document_index = 0;
  collapsedgauche :boolean = false;
  collapseddroite :boolean = false;
  Collapseddoc :boolean = false;
  currentFile: any = {};
  picture_url_tag:string = null;
  currentUser : User ;
  currentlanguage : any ;
  public latitude :number ;
  public longitude:number ;
  public msg: Subject<any> = new Subject();
  public ClickCounter:number ;
  public count :number ;
  chatvideo : boolean=false;
  public msgArray: Observable<Array<any>> = new Observable<Array<any>>();
  public loading: boolean = false;
  public typetag:string;
  windowheight : any ;
  @ViewChild('layout') canvasRef;
  @ViewChild('scrollrow')  myScrollContainer:  ElementRef;
  scrollContainer: any;
  showInformation: boolean = true;
  showNameCollapsed: string = '';
  isObject: boolean = false;
  errorShareLocation: boolean = false;
  lastScrollTopValue: number = 0;
  lastDateMessage: Date = null;
  isDisabledInput: boolean = false;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  isOwnerReply: boolean = false;
  interval: any;
  nbMessage: number = 0;

  constructor( public sharedData : SharedData ,public sanitizer: DomSanitizer, @Inject(DOCUMENT) private document: Document,private socketService: SocketService,private loaderService: LoaderService,
    private modalService: NgbModal,private authService :AuthService, private router :Router,private dataservice :DataService, private sharedService: SharedService)
    {
    if(localStorage.getItem('currentChatbot') == 'object' || localStorage.getItem('currentChatbot') == 'pet')
      this.isObject = true;
    else
      this.isObject = false;

    this.dataservice.listenVideocall().subscribe((m: Videocall) => {
      this.addVideo(m);
    });
    this.sharedService.detectMessagesChange()
    .subscribe((value: boolean)=>{
      this.isDisabledInput = value;
    })

    interval(60000).subscribe(()=>{
      localStorage.removeItem('longitude');
     localStorage.removeItem('latitude');
   })
   }
  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame?.nativeElement;
    if(!localStorage.getItem("currentUser"))
    this.router.navigate(['']);
  }

   valueChanged() {
    this.valueChange.emit();
  }

  expandEmail(name: string): string {
    if (name?.length < 22) {
      return name;
    } else {
      return name.substring(0, 19) + '...';
    }
  }

  expandLabelTag(name: string): string {

    if (name?.length < 15) {
      return name;
    } else {
      return name.substring(0, 15) + '...';
    }
  }

  expandName(name: string): string {

    if (name?.length < 16) {
      return name;
    } else {
      return name.substring(0, 16) + '...';
    }
  }

  expandHeaderTitle(name: string): string {

    if (name?.length < 30) {
      return name;
    } else {
      return name.substring(0, 27) + '...';
    }
  }

  /**********confidentialité bloc*************************************/
  openPopupconfid(){

    this.chatconf =true;
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
        windowClass : "videoClass"
      };

      const modeldoc =this.modalService.open(NgbdPopupconfidContent,options);
      setTimeout(() => {  this.chatconf = false;},1000);


      modeldoc.componentInstance.event.subscribe((data:any) => {

        modeldoc.close();
      });
    }

  /************************** */
  expandFileName(name: string): string {
    if (name?.length < 10) {
      return name;
    } else {
      return name.substring(0, 7) + '...';
    }
  }



  testcollapsedUser(){
    this.collapsedUser = true;
   }
   public doSomething(nummessge: any) {
    this.nbMessage++;
    this.scrollTo('navigateToChat')
    if(this.chat) this.nbMessage = 0;
    this.countmessage.emit(nummessge);
  }

  scrollTo(el: string) {
    const element = document.getElementById(el);
    element?.scrollIntoView()
    const offset = 160;
    const bodyRect = document.body?.getBoundingClientRect().top;
    const elementRect = element?.getBoundingClientRect().top;
    const elementPosition = elementRect || 1 - bodyRect || 1;
    const offsetPosition = elementPosition || 1 - offset || 1;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  @HostListener('change', ['$event']) onWindowScroll(e) {

    // Your Code Here

  }

   getscrollChange(event): void{
    if (!(this.collapseIfScrolled && event) || !(!this.collapseIfScrolled && !event)){
      this.collapseIfScrolled = event
      this.sharedService.scrollDetector(event);
    }

   }
   @ViewChild('scrollframe', {static: false}) scrollFrame: any;

  @HostListener('wheel', ['$event'])
  Event(event) {

       if(event?.wheelDelta > 0){
        this.collapseIfScrolled = false;
        this.sharedService.scrollDetector(false);

    }
    else{
        this.collapseIfScrolled = true;
        this.sharedService.scrollDetector(true);
    }
  }

  ngOnInit() {
    this.sharedData.objectjob = localStorage.getItem('objectjob');
    if(localStorage.getItem('isOwnerReply') == 'true') this.isOwnerReply = true;
    this.interval = interval(5000).subscribe(()=>{
      if(localStorage.getItem('isOwnerReply') == 'true') this.isOwnerReply = true;
    });

    this.windowheight =window.screen.height -160;
    this.collapsed = 1;
    this.currentUser = this.authService.currentUserValue;
    if(this.currentUser?.tag_info.preference_user?.allow_share_name == '1'){
      this.showNameCollapsed = this.currentUser?.tag_member?.tag_creator_first_name;
    }else if (this.currentUser?.tag_info.preference_user?.allow_share_emails == '1'){
      this.showNameCollapsed = this.currentUser?.tag_member?.tag_creator_mail1;
    }
    else if(this.currentUser?.tag_info.preference_user.allow_share_phone == '1'){
      this.showNameCollapsed = this.currentUser?.tag_member?.tag_creator_mobile;
    }

    if(this.currentUser?.tag_info.preference_user?.allow_share_picture == '0' &&
      this.currentUser?.tag_info.preference_user?.allow_share_name == '0' &&
      this.currentUser?.tag_info.preference_user?.allow_share_emails == '0' &&
      this.currentUser?.tag_info.preference_user?.allow_share_phone == '0'){
        this.showInformation = false
      }



    this.chatview = this.dataservice.chatview ;
    this.chaturl = this.dataservice.chaturl;
    if (  this.currentUser!= null)
    {
       if (  this.currentUser.tag_info)
       {

          this.emergency =  this.currentUser.tag_info.tag_info.emergency;

          if (this.currentUser.tag_info.preference_user.allow_share_picture==="1" )
          {
             this.picture_url_tag =  this.currentUser.tag_info.tag_info.picture_url=== null ? "/assets/img/no_pic.png" :  this.currentUser.tag_info.tag_info.picture_url;

              if (this.currentUser?.pet?.general_info !== undefined)
              {

                this.pictureurl =  (this.currentUser.tag_info.features.pet_share_picture === 1 && this.currentUser?.pet.preference_user?.allow_share_picture?.value === '1') ?  this.currentUser.tag_member.tag_creator_profile_picture_url:null;

              }
              else if (this.currentUser.tag_info?.tag_info.active_emergency=== 1 &&  (this.emergency === '1' || parseInt(this.emergency) === 1) )
              {
              this.pictureurl = this.currentUser.tag_info.features.medical_share_picture === 1 ?  this.currentUser.tag_member.tag_creator_profile_picture_url:null;
              }
              else {
                this.pictureurl = this.currentUser.tag_info.features.object_share_picture === 1?  this.currentUser.tag_member.tag_creator_profile_picture_url:null;
              }
           }
          if( this.currentUser.tag_info.other_info?.length > 0)
          {
            this.photoURL = this.currentUser?.tag_info.other_info[0]?.documents[0]?.data !== null? this.currentUser?.tag_info.other_info[0]?.documents[0]?.data : null;
            this.document_name =this.currentUser?.tag_info.other_info[0]?.documents[0]?.document_name!== null? this.currentUser?.tag_info.other_info[0]?.documents[0]?.document_name : null;
            if ( this.photoURL === null &&  this.document_name  === null )
            {
              for(let i = 0; i <this.currentUser.tag_info.other_info?.length; i++)
              {
                if ( this.currentUser.tag_info.other_info[i].documents?.length >0 )
                  for(let j = 0; j <this.currentUser.tag_info.other_info[i].documents?.length ; j++)
                  {
                    this.photoURL = this.currentUser?.tag_info.other_info[i]?.documents[j]?.data !== null? this.currentUser?.tag_info.other_info[i]?.documents[j]?.data : null;
                    this.document_name =this.currentUser?.tag_info.other_info[i]?.documents[j]?.document_name!== null? this.currentUser?.tag_info.other_info[i]?.documents[j]?.document_name : null;
                    if ( this.photoURL !== null ||  this.document_name  !== null )
                     {
                       this.testDoc = true ;
                       break ;
                     }
                  }

              }
            }
            else  this.testDoc = true ;
            if ( this.testDoc === false)
            this.Collapseddoc = true;

          }
          else  if( this.currentUser.tag_info.other_info?.length === 0 ||  this.currentUser.tag_info.other_info!.length === 0 ||this.currentUser.tag_info.other_info===undefined)
          {
           this.Collapseddoc = true;
          }
        }
    } else  {
      this.router.navigate(['']);
      return;
    }

    this.dataservice.Sendmessage('[sn]:'+this.currentUser?.tag_info?.tag_info.serial_number)
    this.dataservice.Sendmessage('[tag_label]:'+this.currentUser?.tag_info?.tag_info.tag_label);

  }
  public  onChangefile(target: any)    {


    if ( target.type === 'file')
    {
      let extFile: string = target.files[0].type;
      if (extFile.includes("jpg") || extFile.includes("jpeg") || extFile.includes("png")){
        target.label = target.value;
        this.dataservice.testsend = false;
        //this.changeuser();
        this.collapsedUser = false ;
        this.dataservice.converse(target, 'finder')
        target.value = '';
        let d = this.document.getElementById('scrollrow');
        if(d)
        {
          d.scrollTop = d.scrollHeight;
        }
      }else{
        this.openPopup("popup_info_imagetype_error")
      }
    }
  }

  /* leave(){
    const leaveRoom: LeaveRoomMessage = {
      'room': this.currentUser?.tag_info.tag_info?.serial_number
    }
    this.socketService.leaveRoom(leaveRoom)
  } */

  public  onChange(target: any)    {
    if ( target?.value === undefined)
    {
      target =  Event ;
      target.value = target;
    }

    this.dataservice.testsend = false;
    // this.changeuser();
    this.collapsedUser = false ;
    target.value = target?.value.trim();
    if(target.value == '') return;
    this.dataservice.converse(target, 'finder')
    if(this.lastDateMessage != new Date() && target.value != 'share_localisation'){
      this.onSendMessage(target.label || target.value, ESendBy.finder);
      this.lastDateMessage = new Date();
    }
    else this.lastDateMessage = new Date();
    target.value = '';

    let d = this.document.getElementById('scrollrow');
    if(d)
    {
      d.scrollTop = d.scrollHeight;
    }

  }

  onSendMessage(text, sender: string){
    if(sender == 'bot') text = ':[bot]'+text;

    const message: OutGoingMessage = {
      room: this.currentUser?.tag_info?.tag_info.serial_number,
      message: text,
    };
    this.socketService.sendMessage(message);
    this.message = '';
  }

  onClick(){}

  public onMsgReceive(msg: string) {

          setTimeout(() => { let a = this.document.getElementById('scrollrow');
          if(a)
          {
            a.scrollTop = a.scrollHeight;
          }
         },2000);
  }
  openreward(){

    this.chatreward = true;
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "videoClass"
    };

    const modeldoc =this.modalService.open(NgbdPopuprewardContent,options);

    setTimeout(() => {  this.chatreward = false;},1000);
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });
  }
  openPopup(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.texte=content;
    modeldoc.componentInstance.type="error";
    modeldoc.componentInstance.color="#F4C809";

     modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });


  }
  opnevideocall(){
    this.dataservice.videocall().subscribe((value: Videocall) => {
      if (value) { this.addVideo(value);
      }
    });
  }
  openchat()
  {
    this.nbMessage = 0;
    this.chat=true;
  }

  openPetDetails(): void{
    this.chat = !this.chat;
  }

  openvideocall(){
    this.chatvideo=true ;
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
      windowClass : "videoClass"
    };

    const modeldoc =this.modalService.open(NgbdPopupmodelContent,options);
    modeldoc.componentInstance.chatinfo = false;
    modeldoc.componentInstance.number=3;
    modeldoc.componentInstance.color="#04A6BC";
    modeldoc.componentInstance.texte="finder_label_videostart";
    modeldoc.componentInstance.type = "alert";
    modeldoc.componentInstance.confirmationBoxTitle = 'finder_label_callstart';
    modeldoc.componentInstance.confirmationMessage = 'finder_label_buttonno';
    modeldoc.componentInstance.typeUser = 'finder';
    modeldoc.componentInstance.tel = this.currentUser.tag_member.tag_creator_mobile;
    modeldoc.componentInstance.allowTel = this.currentUser.tag_info.preference_user.allow_share_phone
    setTimeout(() => {   this.chatvideo=false ;},1000);

    modeldoc.componentInstance.event.subscribe((data:any) => {
      if ( data.chatvideo)
      {

        this.startvideocall();
      }
       this.chatvideo=false ;
      modeldoc.close();
    });
  }
  startvideocall()
  {
     this.dataservice.videocall().subscribe((value: Videocall) => {
      if (value) {
          this.addVideo(value)
          window.open( value.meeting_url)
        }
      } ,
      err => {
      }
     );
  }

  openPetFile(content, type?: string) {
    let customSize = 'sm';
    if(content?.data?.includes('pdf'))
    customSize = 'xl'

    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: customSize,
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };
    if(content?.data?.includes('pdf')){
      window.open(content?.data, '_blank')
    }else{
      const modeldoc =this.modalService.open(NgbdModalContent,options);
      modeldoc.componentInstance.image = content?.data;
      modeldoc.componentInstance.currentUser = this.currentUser?.tag_creator?.tag_creator_id;
      modeldoc.componentInstance.name = content?.document_name;
      modeldoc.componentInstance.type = type
    }
  }


  ngOnDestroy(){
  }
  onKey(event: any) {

    //   if (event.keyCode !== 13 && this.dataservice.testsend === false) {
    //  this.dataservice.testsend = true;
    //  //this.dataservice.typingmessage('start').subscribe((value: any) => {
    //   if (value.error === false)
    //   {
    //   }
    // });
    // }
    //  else if  (event.keyCode === 13)
    //  {

    //  }
  }



  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

   public addItem(event) {
    if( !localStorage.getItem("longitude") && !localStorage.getItem("latitude"))
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("longitude", position.coords.longitude?.toString());
      localStorage.setItem("latitude",position.coords.latitude?.toString());
          this.location=true ;

          localStorage.setItem("location","true");
          localStorage.setItem("position","true");
          /* setTimeout(() => {
            this.location=false ;
          }, 1000);
          this.delay(3000); */

          this.target =  Event ;
          this.target.type = "location";
          this.target.value= "share_localisation";
          this.onChange(this.target);
      },(error)=>{
        this.openPopup("pop_erreur_case_location")
      })
      else{
        localStorage.setItem("location","true");
        localStorage.setItem("position","true");
        /* setTimeout(() => {
          this.location=false ;
        }, 1000);
        this.delay(3000); */

        this.target =  Event ;
        this.target.type = "location";
        this.target.value= "share_localisation";
        this.onChange(this.target);
      }
    }


  public addVideo(value: Videocall) {
    this.target =  Event ;
    this.target.type = "url";
    this.target.value= value.meeting_url;
    this.onChange(this.target);
  }

  addNewItem(value: string) {
  }

  ApprouvEmerg(){
    this.authService.ApprouveEmereg(localStorage.getItem("serialnumberemergency"))
    .subscribe(data=>{
   }
 );
  }

  open(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };
    if ( content === undefined || content?.length === 0 )
    {
      this.openPopup("finder_documents_nodocs")
      return;
    }
    else
    {
      this.testDoc= false ;

        if ( content[0].documents?.length !== 0 )
        {
          this.testDoc = true ;
          this.image = content[0].documents[this.document_index].data;
          this.name = content[0].documents[this.document_index].document_name
          if ( content[0].documents[this.document_index].data.substr(-3)  === "pdf")
            {
              window.open(content);
              return;
            }
          const modeldoc =this. modalService.open(NgbdocModalContent,options);
          modeldoc.componentInstance.image = this.image;
          modeldoc.componentInstance.name = this.name;

         }
      if ( this.testDoc === false )
      this.openPopup("finder_documents_nodocs")
    }

  }
  openTag(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,

    };
    if ( content === undefined)
    {
      this.openPopup("finder_documents_nopicture")
      return;
    }
    if ( content.substr(-3)  === "pdf")
      {
        window.open(content);
        return;
      }
    const modeldoc =this. modalService.open(NgbdocModalContent,options);
    modeldoc.componentInstance.image = content;
    modeldoc.componentInstance.test = true;

    if (this.currentUser?.pet.general_info?.name)
    modeldoc.componentInstance.name = this.currentUser?.pet.general_info?.name ;
    else if (this.currentUser?.tag_info?.tag_info.tag_label)
    modeldoc.componentInstance.name = this.currentUser?.tag_info?.tag_info.tag_label ;
    else if ( this.currentUser?.tag_info?.tag_info.tag_label === null && this.currentUser?.tag_info?.tag_info.tag_description !== null )
    modeldoc.componentInstance.name = this.currentUser?.tag_info?.tag_info?.tag_description;
    else if (this.currentUser?.tag_info?.tag_info.tag_label === null && this.currentUser?.tag_info?.tag_info.tag_description === null && this.currentUser?.tag_info?.tag_info.serial_number !== null)
     modeldoc.componentInstance.name = this.currentUser?.tag_info?.tag_info?.serial_number;
  }

  openImage(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
    };
    if ( content === undefined)
    {
      this.openPopup("finder_documents_nopicture")
      return;
    }
    this.image=this.pictureurl;
    const modeldoc =this.modalService.open(NgbdocprofilModalContent,options);
    modeldoc.componentInstance.image = this.image;
    modeldoc.componentInstance.allowSharePicture = this.currentUser?.tag_info.preference_user?.allow_share_picture;
    modeldoc.componentInstance.allowShareName = this.currentUser?.tag_info.preference_user?.allow_share_name;
    modeldoc.componentInstance.allowShareEmail = this.currentUser?.tag_info.preference_user?.allow_share_emails;
    modeldoc.componentInstance.allowSharePhone = this.currentUser?.tag_info.preference_user?.allow_share_phone;
    modeldoc.componentInstance.tel = this.currentUser?.tag_member.tag_creator_mobile;
    modeldoc.componentInstance.name = this.currentUser?.tag_member.tag_creator_first_name;
    modeldoc.componentInstance.email = this.currentUser?.tag_member.tag_creator_mail1;
  }
}

@Component({
  selector: 'ngb-modal-content',
  styleUrls: ['./chathome.component.css'],
  template:`<button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white closebut" aria-hidden="true">×</span>
        </button>
        <div class="modal-body mt-0" *ngIf="!loadingAttachement">
          <ng-container *ngFor="let item of listDocuments; let index = index">
          <ng-container *ngIf="index == document_index">
            <img *ngIf="item?.image?.toLowerCase().includes('png') || item?.image?.toLowerCase().includes('jpg') || item?.image?.toLowerCase().includes('jpeg')" [src]="item.image" loading="auto" class="img-fluid img-border imgmodel"  alt="Responsive image">
            <div class="col" style="word-break: break-word;">
            <div class="row " class="name-document-content">
                <p class="modelname mt-3 mb-0">{{item?.title}}</p>
            </div>
          </div>
          </ng-container>
        </ng-container>
<!--           <div class="mt-5">
            <a style="display: grid" class="justify-content-center" [href]="image" target="_blank">
            <mat-icon style="font-size: 25px;" class="m-auto" *ngIf="!image.includes('png') && !image.includes('jpg') && !image.includes('jpeg')"> attach_file</mat-icon>
            <span *ngIf="!image.includes('png') && !image.includes('jpg') && !image.includes('jpeg')">Document</span>
            </a>
          </div> -->
        </div>
        <div  class="modal-footer">

        <div class="row w-100" style="justify-content: space-between;margin: 0px 0px 11px;">
        <span>
            <button type="button" *ngIf="document_index !== 0 " class="icon2"  (click)="Documentsuivant('precedant')">
              <img src="/assets/img/flech.png" style=" transform: rotate(-180deg);" >
            </button>
        </span>
        <span>
            <button type="button" *ngIf="(document_index+1) !== listDocuments?.length" class="icon2" (click)="Documentsuivant('next')">
              <img src="/assets/img/flech.png"  >
            </button>
        </span>
         </div>
        </div>`

})
export class NgbdocModalContent {
  @Input() public  image;
  @Input() public name ;
  @Input() public test ;
  currentFile: any = {};
  currentUser : User ;
  disabled_btns = false;
  displayedImage;
  @Input() public document_index = 0;
   public otherinfodoc_index = 0;
  photoURL: SafeUrl = null;
  document_name :string;
  collapsedbutton : boolean = false;
  collapsedbuttonright : boolean = false;
  listDocuments:any[] = [];
  loadingAttachement: boolean =false;

  constructor(public activeModal: NgbActiveModal,private authService :AuthService) {
       this.currentUser = this.authService.currentUserValue;

  }
  ngOnInit()
  {
    this.loadingAttachement = true;
    this.listDocuments.push({'image': this.image, 'title': this.name})
    if(this.currentUser?.tag_info.other_info?.length > 0){
      const otherInfo: any[] = this.currentUser?.tag_info.other_info;
      otherInfo.forEach(element => {
        if(element?.documents?.length > 0){
          element.documents.forEach(element => {
            const block = {
              'image': element.data,
              'title': element.document_name,
            }
            if(block?.image?.includes('png') || block?.image?.includes('jpg') || block?.image?.includes('jpeg'))
            this.listDocuments.push(block);
          });
        }
      });
    }
    if(this.currentUser?.pet && Object.keys(this.currentUser?.pet)?.length>0){
      if(this.currentUser?.pet.other_info?.length > 0){
        const otherInfo: any[] = this.currentUser?.pet.other_info;
        otherInfo.forEach(element => {
          if(element?.documents?.length > 0){
            element.documents.forEach(element => {
              const block = {
                'image': element.data,
                'title': element.document_name,
              }
              if(block?.image?.includes('png') || block?.image?.includes('jpg') || block?.image?.includes('jpeg'))
              this.listDocuments.push(block);
            });
          }
        });
      }
      if(this.currentUser?.pet.vaccins?.length > 0){
        const vaccinsInfo: any[] = this.currentUser?.pet.vaccins;
        vaccinsInfo.forEach(element => {
          if(element?.documents?.length > 0){
            element.documents.forEach(element => {
              const block = {
                'image': element.data,
                'title': element.document_name,
              }
              this.listDocuments.push(block);
            });
          }
        });
      }
    }
    this.loadingAttachement = false;
  }
  isFirstDoc() {

    if (this.document_index === 0 )
    {

      return true;
    }
    else return false
  }

  isLastDoc() {

    return this.currentFile.index === this.currentUser?.tag_info.other_info[this.document_index]?.documents?.length-1;
  }

  Documentsuivant(type: string){
    if(type === 'next') this.document_index++;
    else this.document_index--

  }
}

@Component({
  selector: 'ngbp-modal-content',
  styleUrls: ['./chathome.component.css'],
  template:`<button type="button" class="close-modal-btn pull-right" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span class="text-white closebut" aria-hidden="true">×</span>
        </button>
        <div class="modal-body">
            <img *ngIf="allowSharePicture  == '1' && image; else pictureNotAllowed" [src]="image" class="rounded-circle modelimg img-fluid" >
            <ng-template #pictureNotAllowed>
              <img src="/assets/img/no_pic.png" class="rounded-circle modelimg img-fluid" >
            </ng-template>
        </div>
        <div class="modal-footer">
          <div  class="col">
            <div class="row" style="justify-content: center;">
               <p class="card-text-label" *ngIf="allowShareName == '1' && name">{{name}}</p>
            </div>
            <div class="row modeltype"  *ngIf="allowShareEmail == '1' && email">
              <a href="mailto:{{email?.trim()}}" [title]="email" target="_blank">
                  <div class="tel-container email-conatiner mb-2">

                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21px" height="15px" viewBox="0 0 20 15" version="1.1">
                        <g id="surface1">
                        <path style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;" d="M 18.570312 0 L 1.429688 0 C 1.050781 0 0.6875 0.152344 0.417969 0.429688 C 0.152344 0.703125 0 1.074219 0 1.460938 L 0 13.148438 C 0 13.535156 0.152344 13.910156 0.417969 14.183594 C 0.6875 14.457031 1.050781 14.609375 1.429688 14.609375 L 18.570312 14.609375 C 18.949219 14.609375 19.3125 14.457031 19.582031 14.183594 C 19.847656 13.910156 20 13.535156 20 13.148438 L 20 1.460938 C 20 1.074219 19.847656 0.703125 19.582031 0.429688 C 19.3125 0.152344 18.949219 0 18.570312 0 Z M 17 1.460938 L 10 6.414062 L 3 1.460938 Z M 1.429688 13.148438 L 1.429688 2.125 L 9.59375 7.902344 C 9.710938 7.988281 9.855469 8.035156 10 8.035156 C 10.144531 8.035156 10.289062 7.988281 10.40625 7.902344 L 18.570312 2.125 L 18.570312 13.148438 Z M 1.429688 13.148438 "/>
                        </g>
                      </svg>
                    </span>
                    <span class="tel-size text-white"> {{expandEmail(email)}}</span>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="19px" viewBox="0 0 12 18" version="1.1">
                      <g id="surface1">
                      <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 2.000651 2.000434 L 11.999349 11 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                      <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 11.999349 11 L 2.000651 19.999566 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                      </g>
                      </svg>
                    </span>
                  </div>
                </a>
                  <!-- <p > {{email}}</p> -->
            </div>
            <div class="row modeltype mt-1" *ngIf="allowSharePhone == '1' && tel">
            <!--  <a href="https://wa.me/{{tel?.trim()}}"> -->
              <a href="tel://{{tel?.trim()}}" target="_blank">
                <div class="tel-container">

                  <span>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.55822 7.03642L5.5088 7.08585L5.4676 7.14231C5.32307 7.34037 5.18527 7.62292 5.18196 7.97478L5.18085 8.09345L5.21053 8.20835C5.69522 10.0845 7.12281 11.7244 8.28011 12.7862C8.43787 12.9309 8.61191 13.0978 8.79702 13.2753C9.19618 13.6581 9.64686 14.0903 10.0971 14.4564C10.7756 15.0083 11.5899 15.534 12.5611 15.7451C12.7096 15.7843 12.905 15.8194 13.1177 15.8099C13.3423 15.7999 13.6732 15.7346 13.9535 15.4741L13.9686 15.4601L13.983 15.4454L15.1962 14.2098C15.3008 14.1399 15.4181 14.0922 15.5215 14.074C15.6383 14.0535 15.6863 14.0773 15.6934 14.0815L15.903 14.2031H15.9207L19.993 16.607C20.0399 16.6378 20.0646 16.6668 20.0776 16.6868C20.0916 16.7082 20.0972 16.7268 20.0991 16.7416C20.1025 16.7676 20.0969 16.7998 20.0659 16.8335L17.1146 19.7615L17.1093 19.7668L17.1041 19.7721C16.8934 19.9881 16.5861 20.0972 16.1788 20.0999C13.9546 20.0288 11.7847 18.931 9.88788 17.7004C6.78773 15.4421 3.99796 12.6802 2.25646 9.39073C1.54062 7.90789 0.838545 6.26438 0.904172 4.91202L0.904979 4.8954L0.905171 4.87876C0.910734 4.39563 1.04246 4.08883 1.21461 3.93126L1.22929 3.91783L1.24336 3.90376L4.17322 0.973879C4.22274 0.934454 4.2579 0.915474 4.27852 0.90656C4.30165 0.923605 4.34354 0.961561 4.39508 1.03587L6.74251 5.48765C6.80885 5.64279 6.77815 5.81172 6.64464 5.95L5.55822 7.03642Z" stroke="white" stroke-width="1.8"/>
                    </svg>
                  </span>
                  <span class="tel-size text-white"> {{tel | phone}}</span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12px" height="19px" viewBox="0 0 12 18" version="1.1">
                    <g id="surface1">
                    <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 2.000651 2.000434 L 11.999349 11 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                    <path style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke:rgb(100%,100%,100%);stroke-opacity:1;stroke-miterlimit:4;" d="M 11.999349 11 L 2.000651 19.999566 " transform="matrix(0.857143,0,0,0.818182,0,0)"/>
                    </g>
                    </svg>
                  </span>
                </div>
              </a>
            </div>
            <div class="row modeltype mt-1">
                <button type="button"  class="skip-button button my-3" (click)="activeModal.dismiss('Cross click')">
                  <span>  {{'finder_label_buttonclose' | translate}}</span>
              </button>
              </div>
          </div>
        </div>`

})
export class NgbdocprofilModalContent {
  @Input() public  image;
  @Input() public name ;
  @Input() public tel ;
  @Input() public email ;
  @Input() public allowShareName;
  @Input() public allowShareEmail;
  @Input() public allowSharePicture;
  @Input() public allowSharePhone;

  currentFile: any = {};
  currentUser : User ;
  disabled_btns = false;
  displayedImage :string ;
  @Input() public document_index = 0;
  photoURL: SafeUrl = null;
  document_name :string;
  collapsedbutton : boolean = false;
  constructor(public activeModal: NgbActiveModal,private authService :AuthService) {


  }
  ngOnInit()
  {
  }
  expandEmail(name: string): string {
  if (name?.length < 17) {
    return name;
  } else {
    return name.substring(0, 14) + '...';
  }
  }
}




export class ObjectType{
    type_label:string;
    icon:string;
    id:number;
    id_app:string;
}
export class optionsObject{
    object_types:ObjectType[]=[];
}
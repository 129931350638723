<div id="moreInfo">
    <div class="mt-3 d-inline-block">
        <div class="d-flex text-left mb-3">
            <a (click)="convertEmbededVideo('https://www.youtube.com/watch?v=o_pfmPXg0MI&t=26s')" class="mb-2 vid-info">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.9529 39.912C30.9246 39.912 39.8189 31.0177 39.8189 20.046C39.8189 9.0743 30.9246 0.179993 19.9529 0.179993C8.98122 0.179993 0.0869141 9.0743 0.0869141 20.046C0.0869141 31.0177 8.98122 39.912 19.9529 39.912Z" fill="#04A6BC" />
                  <path d="M14.2139 29.317V11.658L30.1069 20.487L14.2139 29.317Z" fill="white" />
                </svg>
                <span class="m-1 titleLinkVideo mb-2">{{'text_howitworks_landpagev2' | translate}}</span>
            </a>

            <a (click)="convertEmbededVideo('https://www.youtube.com/watch?v=DlvGH--et0A&t=49s')" class="mb-2 ml-3 vid-info ">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9529 39.912C30.9246 39.912 39.8189 31.0177 39.8189 20.046C39.8189 9.0743 30.9246 0.179993 19.9529 0.179993C8.98122 0.179993 0.0869141 9.0743 0.0869141 20.046C0.0869141 31.0177 8.98122 39.912 19.9529 39.912Z" fill="#04A6BC" />
                <path d="M14.2139 29.317V11.658L30.1069 20.487L14.2139 29.317Z" fill="white" />
              </svg>
                <span class="m-1 titleLinkVideo mb-2">{{'pets_landpagev2' | translate}}</span>
            </a>
        </div>

        <div class="d-flex text-left">
            <a (click)="convertEmbededVideo('https://www.youtube.com/watch?v=HkwwjIHpS58')" class="mb-2 vid-info ">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9529 39.912C30.9246 39.912 39.8189 31.0177 39.8189 20.046C39.8189 9.0743 30.9246 0.179993 19.9529 0.179993C8.98122 0.179993 0.0869141 9.0743 0.0869141 20.046C0.0869141 31.0177 8.98122 39.912 19.9529 39.912Z" fill="#04A6BC" />
                <path d="M14.2139 29.317V11.658L30.1069 20.487L14.2139 29.317Z" fill="white" />
              </svg>
                <span class="m-1 titleLinkVideo mb-2">
                  {{'medicalvid_landpagev2' | translate}}
                </span>
            </a>
            <a (click)="convertEmbededVideo('https://www.youtube.com/watch?v=cTdPs5bXBLs&t=11s')" class="mb-2 ml-3 vid-info ">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9529 39.912C30.9246 39.912 39.8189 31.0177 39.8189 20.046C39.8189 9.0743 30.9246 0.179993 19.9529 0.179993C8.98122 0.179993 0.0869141 9.0743 0.0869141 20.046C0.0869141 31.0177 8.98122 39.912 19.9529 39.912Z" fill="#04A6BC" />
                <path d="M14.2139 29.317V11.658L30.1069 20.487L14.2139 29.317Z" fill="white" />
              </svg>
                <span class="m-1 titleLinkVideo mb-2">
                  {{'vulnerable_part_landpagev2' | translate}}
                </span>
            </a>
        </div>
    </div>
    <div>
        <iframe class="iframe-width my-3" [src]="srcvideo" frameborder="0" allowfullscreen>
      </iframe>
    </div>

    <div class="info-text-size text-left p-3">
        <!-- We are an innovative platform for emergency reponse that aims to
                        assist you in
                        recovering lost items, pets, or family members in a straightforward, budget-friendly, and
                        dependable manner. The platform appeals to humanity's altruistic nature by recognizing that we
                        would all be more willing to assist others if it were easier to do so, wheter by the returning
                        lost possession or pets,or by providing aid in times of crisis. -->
        <p>{{'text_recognize_coundme_landpagev1' | translate}}</p>


        <!-- FOUND ME provides a variety of interactive tags,labels,and bracelets that feature QR codes,which
                        can be utilized for your valuable possessions,such as house keys, wallet, sports equipment,
                        suitcases, and even your computer. Additionally, you can use our products to keep track of your
                        furry companions and your loved ones. -->
        <p>{{'text_recognize_coundme_landpagev2' | translate}}</p>
        <!-- Our tags,labels,and bracelets are more convenient than GPS trackers, as they are compact,do not
                        require batteries and have no range limitations,making them usable anywhere in the
                        world. -->
        <p>{{'text_tags_foundme_landpagev2' | translate}}</p>
        <!-- For more information about FOUND ME, please visit our website  -->
        <p>{{'text_vist_website_landpagev2' | translate}} <span><a class="text-dark" [href]="getSafeUrl('https://www.foundme.com')" target="_blank">www.foundme.com</a></span>
        </p>
    </div>
</div>

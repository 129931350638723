import { ResetPasswordComponent } from './Pages/reset-password/reset-password.component';
import { PhonePipe } from './Pages/normalchat/chat-msg/phone-pipe.pipe';
import { environment } from 'src/environments/environment';
import { LoaderInterceptor } from './services/loader-interceptor/loader.interceptor';
import { SharedService } from './services/shared-service/shared.service';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatFormFieldModule} from '@angular/material/form-field'
import { APP_BASE_HREF, CommonModule, DatePipe, PathLocationStrategy, registerLocaleData } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IconModule } from '@visurel/iconify-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { HomeComponent } from './Pages/home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SplashScreenComponent } from './Pages/splash-screen/splash-screen.component';
import { MatCardModule } from '@angular/material/card';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS ,HttpClient} from '@angular/common/http';
import { NbThemeModule, NbLayoutModule, NbChatModule, NbSpinnerModule } from '@nebular/theme';
import { LostOrFoundComponent } from './Pages/lost_or_found/lost-or-found.component';
import { ChatService } from './services/chat/chat.service';
import { ChatWindowComponent } from './Pages/normalchat/chat-window/chat-window.component';
import { ChatMsgComponent } from './Pages/normalchat/chat-msg/chat-msg.component';
import { ChatInputComponent } from './Pages/normalchat/chat-input/chat-input.component';
import { ChathomeComponent ,NgbdocModalContent ,NgbdocprofilModalContent} from './Pages/normalchat/chathome/chathome.component';
import { ChatMsgemerComponent } from './Pages/emegencychat/chat-msgemer/chat-msgemer.component';
import { ChatWindowemerComponent } from './Pages/emegencychat/chat-windowemer/chat-windowemer.component';
import { ChatInputemerComponent } from './Pages/emegencychat/chat-inputemer/chat-inputemer.component';
import { ChathomeemerComponent} from './Pages/emegencychat/chathomeemer/chathomeemer.component';
import {  ScModalModule } from 'angular-5-popup';
import {AgmCoreModule}  from '@agm/core';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeAr from '@angular/common/locales/ar';
import { ChatviewComponent, NgbPrintModalContent, NgbvdocModalContent} from './Pages/normalchat/chatview/chatview.component';
import { AuthService } from './services/auth/auth.service';
import { MatSnackBar, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedData } from './models/sharedData.model';
import { ModalModule, WavesModule, InputsModule, ButtonsModule } from 'angular-bootstrap-md'
 import { MatDialogModule} from '@angular/material/dialog';
 import { GoogleMapsModule } from '@angular/google-maps';
import { DataService } from './services/chat/data.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgxPrintModule} from 'ngx-print';
import { NgbdModalContent } from './Pages/listmodal/model/model.component';
import { NestedComponent } from './Pages/Nested.component';
import { RouterModule } from '@angular/router';
import { NonroutedComponent } from './Pages/nonrouted/nonrouted.component';
import { ErrorInterceptor } from './services/error/error.interceptor';
import { ClipboardModule } from 'ngx-clipboard';
import { NotFoundComponent } from './Pages/not-found/not-found.component';
import { AuthGuard } from './services/auth/auth-guard-service';
import { NgbdPopupmodelContent } from './Pages/listmodal/popupmodel/popupmodel.component';
import { NgbdPopupconfidContent } from './Pages/listmodal/popupconfid/popupconfid.component';
import { NgbdPopupmodelDocContent } from './Pages/listmodal/popupmodeldoc/popupmodeldoc.component';
import { ChatUrlComponent } from './Pages/emegencychat/chatUrl/chatUrl.component';
import {TranslationService} from './services/translate';
import { HomepageComponent } from './Pages/freemiumhomepage/homepage.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SuccessComponent } from './Pages/success/success/success.component';
import { NgbdPopuprewardContent } from './Pages/listmodal/popupreward/popupreward.component';
import { ChatModule } from './Pages/chat/chat.module';
import { LoaderService } from './services/loader/loader.service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InfosComponent } from './Pages/success/infos/infos.component';
import { NgbdModalResponse } from './Pages/listmodal/modelResponse/model.component';
import { MoreInformationsComponent } from './Pages/more-informations/more-informations.component';
import { PetLandingpageComponent } from './Pages/pet-landingpage/pet-landingpagecomponent';
import { ObjectScannedComponent } from './Pages/object-scanned/object-scanned.component';
import { MedicaleObjectComponent } from './Pages/medicale-object/medicale-object.component';
import { LandingPageComponent } from './Pages/landing-page/landing-page.component';
import { SentryErrorHandler } from './sentry-error-handler';
import { ContactInfoDialog } from './Pages/listmodal/contact-info-dialog/contact-info-dialogcomponent';

const config: SocketIoConfig = {
  url: environment.socketUrl, // socket server url;
  options: {
    transports: ['websocket'],
  },
};

const language: string = window.navigator.language || 'en';

registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeAr);

export function HttpLoaderFactory (http: HttpClient) {
  //return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent ,
    NgbdModalContent,NgbdPopupconfidContent,NgbdPopuprewardContent,NgbdModalResponse,
    NgbdPopupmodelContent,NgbPrintModalContent, NgbdPopupmodelDocContent,ContactInfoDialog,
    NgbdocModalContent,
    NgbdocprofilModalContent,
    NgbvdocModalContent,
    HomeComponent,
    SplashScreenComponent,
    ChatWindowComponent,ChatWindowemerComponent,NotFoundComponent,ChatUrlComponent,
    ChathomeComponent,LostOrFoundComponent,ChathomeemerComponent,
    ChatMsgComponent, ChatInputComponent,ChatMsgemerComponent, ChatInputemerComponent,
    ChatviewComponent,NestedComponent,NonroutedComponent, HomepageComponent, SuccessComponent, PhonePipe,
    ResetPasswordComponent,
    InfosComponent,
    LandingPageComponent,
    MoreInformationsComponent,
    PetLandingpageComponent,
    ObjectScannedComponent,
    MedicaleObjectComponent
  ],
  exports: [ NgbdocModalContent,NgbdocprofilModalContent,NgbvdocModalContent, MatSlideToggleModule, MatCheckboxModule],
  imports: [
    ChatModule,
    NgxCaptchaModule,
    ClipboardModule,
    NgxPrintModule,
    ModalModule.forRoot(), WavesModule, InputsModule, ButtonsModule,
    MatProgressSpinnerModule,ScModalModule,
    MatDialogModule,GoogleMapsModule,
    RouterModule,
    AgmCoreModule.forRoot({apiKey :'AIzaSyCAx6dN13VpYcAF4xiEWIZ9xKKz3e0j_DE', language: language}),
    HttpClientModule,
    BrowserModule,
    MatFormFieldModule,
    MatInputModule,
    AppRoutingModule,
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    IconModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatCardModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbChatModule,
    NbSpinnerModule,
    HttpClientModule,NgbModule,
    TranslateModule.forRoot({
     loader: {
       provide: TranslateLoader,
      useClass: TranslationService,
       deps: [HttpClient]
     }
    }),
    SocketIoModule.forRoot(config),
  ],
  entryComponents: [NgbdModalContent, ContactInfoDialog,NgbPrintModalContent,NgbdPopuprewardContent,NgbdPopupconfidContent,NgbdPopupmodelContent,NgbdPopupmodelDocContent,NgbdocModalContent,NgbdocprofilModalContent,NgbvdocModalContent, NgbdModalResponse],
  providers: [ PathLocationStrategy,ChatMsgComponent,ChathomeComponent,ChatMsgemerComponent,ChathomeemerComponent,
     SharedData,ChatService,DatePipe,  AuthService ,ChatService, SharedService, LoaderService,
     MatSnackBar ,TranslateService  , { provide: LOCALE_ID, useValue: 'en-EN' },
  AuthGuard,
  {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  //  {provide: LocationStrategy, useClass: HashLocationStrategy}
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
  },
  {provide: APP_BASE_HREF, useValue: '/'},
  { provide: ErrorHandler, useClass: SentryErrorHandler }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

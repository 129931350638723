import { OutGoingMessage, SocketService } from './../../../services/socket/socket.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/chat/data.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
class data {
  showMe;
  loading;
  chatvideo ;
  urlSafe;
}
class Videocall{
  meeting_url ;
}
@Component({
  selector: 'ngbd-popupmodel-content',
  styleUrls: ['./popupmodel.component.css'],
  templateUrl: './popupmodel.component.html',

})
export class NgbdPopupmodelContent {
  @Input() public chatinfo:boolean;
  @Input() public srcimage:any;
  @Input() public test:any;
  @Input() public texte:string;
  @Input() public color:any;
  @Input() public title:string;
  @Input() public type:string;
  @Input() public number:0;
  @Input() confirmationMessage:any;
  @Input() tel:any;
  @Input() allowTel:any;
  @Input() typeUser: string
  showMe :boolean;
  chatvideo  :boolean;
  public event: EventEmitter<any> = new EventEmitter();
  loading :boolean;
  datamodel = new  data ;
  linkVideoCall: string = '';
  constructor(public dataservice :DataService,public sanitizer: DomSanitizer, private _sharedService:SharedService, private socketService: SocketService, private dataService: DataService) {
  }
  ngOnInit(){
  }

  triggerEvent() {
    this.event.emit({data: 12345});
  }
  public openvideo()
  {
    if ( this.type === "alert")
    {
    this.datamodel.loading=true;
    this.datamodel.chatvideo=true;
    //this.event.emit( this.datamodel);

    }
    this.event.emit( this.datamodel);

  }
  public closemodel()
  { this.event.emit( this.datamodel);

  }

  startVideoCall(): void{
    let message: any;
    this._sharedService.startVideoCall(this.typeUser)
    .subscribe((response)=>{
       message = {
        'value': response?.data?.roomUrl,
        'type': 'url'
      };
      this.dataService.converse(message, this.typeUser);

    }, error=>{
       message = {
        'value': error?.error?.data?.error,
        'type': 'text'
      };
      this.dataService.converse(message, this.typeUser);
    })
    this.closemodel()
  }

  onSendMessage(text){
    let  emergency_contact: any =  JSON.parse(localStorage.getItem('emergency_contact'));
    const message: OutGoingMessage = {
      room: emergency_contact.sn,
      message: text,
    };
    this.socketService.sendMessage(message);
    this.linkVideoCall = '';
  }
}

import { UpdateFinderInformation } from './models/chatbotmessage';
import { DataService } from 'src/app/services/chat/data.service';
import { SocketService, OtherIncommingMessage, RoomMessage, User } from './services/socket/socket.service';

import { Component ,OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PathLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {  first } from 'rxjs/operators';
import { AuthService } from './services/auth/auth.service';
import { OneSignal } from 'onesignal-ngx';

class Login{
  error;
  message;
  tag_info:{tag_info:{emergency : string ;}}
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'projectfoundme';
  routePathParam;
  navigationEnd:Observable<any>;
  subRoutePathParam ;
  supportLanguages = ['en', 'fr'];
  SerialNumberInput: string ;
  login = new Login();
  data:any;
  pathParam :Observable<string>;
  userId:any;
  onsSignalKeyMobile: string = '';
  onsSignalKeyFinder: string = '';
  idDevice: string = null;

  constructor(private activatedRoute: ActivatedRoute,   private authService :AuthService,
    private translateService: TranslateService,private router: Router,
     pathLocationStrategy: PathLocationStrategy,private translate : TranslateService,
     private socketService: SocketService, private oneSignal: OneSignal
    ) {
    //chmod('/src/OneSignalSDKWorker.js', 777, null);
    translate.setDefaultLang('en');
    const browserlang = this.translateService.getBrowserLang();
    localStorage.setItem('browsercode',browserlang)
    this.translateService.addLangs(this.supportLanguages);
   // this.translateService.setDefaultLang('en');
      this.authService.getTranslationId(browserlang).subscribe((data) => {
        localStorage.setItem("languageId",data.id_language);

        this.translateService.use(data.id_language);
        //this.translateService.getTranslation(data.id_language);
      },
      (err) => {
        this.translateService.use('en');
        this.translateService.getTranslation('en');
      });

      this.socketService.onServerEvent().subscribe((data) => {
        //console.log(data as OtherIncommingMessage);
      });

     /*  this.socketService.onMessageEvent().subscribe((data) => {
        const messageContent = JSON.parse(data as string)
        this.socketService.newMessageSent(messageContent);
        this.socketService.newMessageEmergencySent(messageContent);
      }); */

      this.socketService.onRoomUsersEvent().subscribe((data) => {
        const event = JSON.parse(data as string) as RoomMessage;
        const users = event.message.map((user) => JSON.parse(user)) as User[];
        const newEvent = Object.assign({}, event, { message: users });
        //console.log(newEvent);
      });
      this.socketService.onRoomEvent().subscribe((data) => {
        //this.socketService.eventRoom(JSON.parse(data as string));
      });
      this.socketService.onErrorEvent().subscribe((data) => {
        //console.log(data as OtherIncommingMessage);
      });
  }
  ngOnInit(): void  {
   /*  navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("longitudeEmergency", position.coords.longitude?.toString());
      localStorage.setItem("latitudeEmergency",position.coords.latitude?.toString());
      localStorage.setItem("longitude", position.coords.longitude?.toString());
      localStorage.setItem("latitude",position.coords.latitude?.toString());
    }); */
    this.authService.getParamApp().subscribe((response)=>{
      this.onsSignalKeyMobile = response.data['onesignal_appid']
      this.onsSignalKeyFinder = response.data['onesignal_web_app_id']
      this.oneSignal.init({
        appId: this.onsSignalKeyFinder,
      });
      this.oneSignal.getUserId().then((idDevice)=>{
        this.idDevice = idDevice
        localStorage.setItem('idDevice',this.idDevice)
      })
    });
  }


  navigate() {
    if (this.SerialNumberInput)
    {

        this.authService.login(this.SerialNumberInput, this.idDevice).pipe(first())

        .subscribe((data :Login)=>{
            this.login = data;

            if(this.login.error=== true)
          {
            //alert(this.login.message)
          }
          else if ( data.tag_info!= null)
              if (data.tag_info.tag_info != null )
                  if (data.tag_info.tag_info.emergency != "1"  )
                  this.router.navigate(['/chathome']);
                  else
                  this.router.navigate(['/lost_or_found']);
                }
      )
   }
  }



}

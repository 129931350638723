import { map, take } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { SocketService, OtherIncommingMessage, RoomMessage, JoinRoomMessage, LeaveRoomMessage } from './../../../services/socket/socket.service';
import { LoaderService } from './../../../services/loader/loader.service';
import {
  Component, ContentChild, ElementRef, EventEmitter,OnDestroy, Inject, Input, OnInit, Output, QueryList, TemplateRef,
  ViewChild, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import { BehaviorSubject, Observable, Subject, SubscriptionLike , Subscription, interval} from 'rxjs';
import {DataService} from '../../../services/chat/data.service';
import {scan, share, takeUntil} from 'rxjs/internal/operators';
import {ESendBy} from '../../../models/EsendBy';
import { HttpClient, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RootObject } from 'src/app/models/messagerealtime.model';
import { Message } from 'src/app/models/message';
import { ChatService } from 'src/app/services/chat/chat.service';
import { Event, NavigationEnd, Router, Scroll } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user.model';
import { HostListener } from "@angular/core";
import { ChatMsgComponent } from '../chat-msg/chat-msg.component';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { Socket } from 'ngx-socket-io';
import { Generic, Option, Value } from 'src/app/models/chatbotmessage';

class DataRoom {
  last_event_id ;
  queue_id;
  result ;
}

@Component({
  selector: 'Chat-bot',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss']
})
export class ChatWindowComponent implements OnInit , OnDestroy, AfterViewInit, OnChanges{
  @ContentChild(TemplateRef) template: TemplateRef<any>;
  @Input() msgTemplate: TemplateRef<any>;
  public chatview :boolean = false ;
  @Input() inputTemplate: TemplateRef<any>;
  @Input() msg: Subject<any>;
  nummsg: number = 0;
  subscription: SubscriptionLike;
  @Output() countChanged:  EventEmitter<any>= new EventEmitter();
  @ViewChild(ChatMsgComponent) child:ChatMsgComponent;
  ownerrepley : boolean = false ;
  public collapsedUser:boolean =false ;
  @Output() onMsgReceive = new EventEmitter();
  @Output() scrollChange = new EventEmitter<boolean>();
  @ViewChild('msgArea') msgArea: ElementRef;
  @ViewChild('defaultMsgTemplate') defaultMsgTemplate: TemplateRef<any>;
  @ViewChild('defaultInputTemplate') defaultInputTemplate: TemplateRef<any>;

  sender :string;
  message :string ;
  target: any =Event;
  public currentUser: User;
   data : any ;
   loading:boolean=false ;
   @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  @Input() collapsed:boolean =false ;
  allMessages: Observable<Message[]>;
  isTyping: Observable<boolean>;
  typingBehaviour = new BehaviorSubject<boolean>(false);
  nbMessages: number = 0;
  currentChatbot = localStorage.getItem('currentChatbot');
  isObject: boolean;
  scrollContainer: any;
  @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  public isLoading: Subject<boolean> = this.loaderService.isLoading;
  collapseIfScrolled: boolean = true;
  nbMessagesServer: number = 0;
  autoriseLocation: boolean = false;
  interval: Subscription;
  nbJoin: number = 1;
  listEventRoom: string[]=[];

  constructor( @Inject(DOCUMENT) private document: Document,private chatService :ChatService, public dataService: DataService,private datePipe: DatePipe , private authService : AuthService,
  private dataservice :DataService, private socket: Socket, public loaderService: LoaderService, private socketService: SocketService,
  private sharedService:SharedService) {

    if(localStorage.getItem('currentChatbot') == 'object' || localStorage.getItem('currentChatbot') == 'pet')
    this.isObject = true;
    else
    this.isObject = false;

    /* if (navigator.geolocation ) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.autoriseLocation = true;
        localStorage.setItem("longitude", position.coords.longitude?.toString());
        localStorage.setItem("latitude",position.coords.latitude?.toString());
      });
    } */

   this.dataService.listenMessages().subscribe((status: any) => {
      this.loading=status ;
    })

    this.interval = interval(3000).subscribe(()=>{

      var socket = this.socket.connect();
      if(!socket.connected && this.nbJoin == 1) {
        this.nbJoin++;
        this.rejoinRoom();
      }
      const joinMessage: JoinRoomMessage = {
        id_user: this.currentUser?.finder_info?.id,
        user_type: 'finder',
        username: 'Finder',
        room: this.currentUser?.tag_info.tag_info?.serial_number,
      };
      this.socketService.isInRoom(joinMessage)
    });

    this.socketService.onCheckUserInRoom().subscribe((room: string) => {
      //console.log(JSON.parse(room));
      const data = JSON.parse(room)
      if(data.message == 'user not in the room' && data.room == this.currentUser?.tag_info.tag_info?.serial_number.toUpperCase())
      this.rejoinRoom();
    })

    this.currentUser = this.authService.currentUserValue;
    /* this.interval = interval(10000).subscribe(()=>{
      if(localStorage.getItem('isOwnerReply') == "true"){
        this.verifyDiscussionMessage();

      }
    }); */


    if(this.isObject){
      /**
       * Join romm
       */
    const joinMessage: JoinRoomMessage = {
      id_user: this.currentUser?.finder_info?.id,
      user_type: 'finder',
      username: 'Finder',
      room: this.currentUser?.tag_info.tag_info?.serial_number,
    };
    this.socketService.joinRoom(joinMessage)

    /**
     * if join room error recall to join
     */
    this.socketService.onErrorEvent().subscribe((data: any) => {
      let dataJson = JSON.parse(data);
      if(dataJson)
      if(dataJson?.message?.includes('You are not in the room')  || dataJson?.message?.includes('Join request not valid') || dataJson?.message?.includes('User Info is not valid') || dataJson?.message?.includes('Message request is not valid')){
        this.rejoinRoom();
      }
    });
    }

    this.chatview = this.dataService.chatview;
    this.allMessages = null;

  }

  rejoinRoom(): void {
    const joinMessage: JoinRoomMessage = {
      id_user: this.currentUser?.finder_info?.id,
      user_type: 'finder',
      username: 'Finder',
      room: this.currentUser?.tag_info.tag_info?.serial_number,
    };
    this.socketService.joinRoom(joinMessage);
   this.verifyDiscussionMessage();
  }

  verifyDiscussionMessage(): void{
    const requestListMessages = this.dataService.GetAllMessage(this.currentUser?.finder_info?.id, this.currentUser.tag_info.tag_info.serial_number, 'finder').toPromise();
    requestListMessages.then((response: any)=>{
      //console.log((response?.dicussion?.length-1) , this.dataService.numberDiscussionMessages);


      if((response?.dicussion?.length-1) > this.dataService.numberDiscussionMessages){
        for (let index = (this.dataService.numberDiscussionMessages+1); index < response?.dicussion?.length; index++) {
          if(!this.dataService.searchMessageOnConversation(response?.dicussion[index].content, this.dataService.allConversation)){
            let message = response?.dicussion[index];
            //console.log((response?.dicussion?.length-1)-(this.dataService.numberDiscussionMessages+1) );

            /* let saveListMessages: any[] = JSON.parse(localStorage.getItem('lastMessageSent')) ? JSON.parse(localStorage.getItem('lastMessageSent')) : []
              if(message?.sender_full_name == 'Found.me')
              if(saveListMessages.includes((message?.content))) {
                continue;
              }else{
                saveListMessages.push((message?.content));
                localStorage.setItem('lastMessageSent', JSON.stringify(saveListMessages))
              } */
              console.log({'content':message?.content,'list':this.dataService.lastMessageSent});

              if(this.dataService.searchMessageOnConversation(message?.content, this.dataService.lastMessageSent)) {
                continue;
              }

              this.pushMessageToConversation(response, index);
         }
        }
      }
    });
  }

  pushMessageToConversation(response: any,index: number): void{
    let message = response?.dicussion[index];
    if ( message.sender_email!== "welcome-bot@zulip.com")
      {
      let messagedata = new Generic();
      let sender;
      let userMessage: Message;
      let botnum = 0;
      messagedata.typing=false;
      messagedata.text=message.content.replace('<p>','').replace('</p>','')
      messagedata.title=message.content.replace('<p>','').replace('</p>','')
      messagedata.response_type = 'text';
      var theDate = new Date(message.timestamp * 1000);
      let dateString = this.datePipe.transform(theDate, 'hh:mm a');
        if ( message.sender_full_name==="Found.me" || (message.sender_full_name==='Finder' && this.dataService.chaturl))
        {
          if(message.sender_full_name==="Found.me")
          sender= ESendBy.bot ;
          else if (message.sender_full_name==="Finder")
          sender= ESendBy.finder ;

            if ( message.content.includes("[photo]:"))
            {
            userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null ,  botnum,dateString);

            }
            else if ( message.content.includes("[url]:")  )
            userMessage = new Message(null, sender,  message.content.replace("[url]:","") ,null, message.content.replace("[url]:",""),null,null, botnum,dateString);
              else if ( message.content.includes("[video]:")  )
              {
              message.content = message.content.replace('[video]: ','')
            userMessage = new Message(null, sender,null,null, null,null, message.content, botnum,dateString);

              }
                else if ( message.content.includes("[pincode]:"))
            {
              let serial = localStorage.getItem("SerialNumber")
              var pincode =message.content.replace("[pincode]:","")
              localStorage.setItem("pincode",pincode)
                    this.authService.Getemergency(serial,pincode)
                    .subscribe((data :any)=>{

              });
            }
            else if  ( message.content.includes("[position]:"))
            {
              //if(this.isPositionAutorised){
                userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null ,  botnum,dateString);
              //}
            }
            else if  ( message.content.includes("[title]"))
            {
              messagedata =new Generic;
              let substrings = message.content.split('[option]');
              messagedata.title = substrings[0].replace('[title]','').replace('<p>','').replace('</p>','');
              messagedata.response_type="option";
              messagedata.options = [];

              for ( let j=1 ; j<substrings?.length ;j++)
              {
              let option = new Option ();
              let substr = substrings[j].split('[label]');
              option.value = new Value ;
              option.value.input = new Input ;
              option.value.input.text =substr[0].replace('<p>','').replace('</p>','')
              option.label =substr[1].replace('<p>','').replace('</p>','')
              messagedata.options.push (option);

              }

              userMessage = new Message(message.content, ESendBy.bot, null,messagedata, null,null, null, botnum ,dateString );

            }
            else if  ( message.content.includes("[link]:"))
            userMessage= new Message(null, message.sender_full_name, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);

            else if(message.content.includes("[option]")){
              let content =  message.content;

              let listSplit: any[]=[];
              let options: Option[]=[];


              content = content.replace('<p>', '')
              content = content.replace('</p>', '')
              listSplit = content.split('[option]')
              for (let index = 0; index < listSplit?.length; index++) {
                if(listSplit[index].includes('[label]')){
                  let option = new Option();
                  let newValue = new Value();
                  let newInput = new Input();
                  let label = '';
                  let value = '';

                  label = listSplit[index].substring(0, listSplit[index].indexOf('[label]'))
                  value = listSplit[index].substring(listSplit[index].indexOf('[label]')+7)

                  option.label = value;

                  newInput.text = label;

                  newValue.input = newInput;

                  option.value = newValue;

                  options.push(option);
                }
              }
              let newGeneric: Generic = new Generic();
              newGeneric.response_type = 'option';
              newGeneric.title = listSplit[0];
              newGeneric.options = options;

              userMessage= new Message(null, sender, null ,newGeneric, null ,null,null, botnum,dateString);
            }
            else{
              if( !message.content.includes('cmd_open_medical_informations')){
                userMessage= new Message(messagedata.text,sender, null, messagedata, null,null, null ,  botnum,dateString);
                }
            }

          this.dataService.update(userMessage);
          botnum++;
      }
        else if ( message.sender_full_name===('Finder') && !this.dataService.chaturl)
      {
        botnum=0;
        sender= ESendBy.user  ;

        if ( message.content.includes("[photo]:")){
          userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString);
          this.dataService.update(userMessage)
        }
        else if ( message.content.includes("[pincode]:"))
        {
          var pincode = message.content.replace("[pincode]:","")
          let serial = localStorage.getItem("SerialNumber")
          this.authService.Getemergency(serial,pincode)
                .subscribe((data :any)=>{

          });
        }
        else if ( message.content.includes("[newdiscussion]:"))
        {
          var pincode = message.content.replace("[pincode]:","")
          let serial = localStorage.getItem("SerialNumber")
          this.authService.Getemergency(serial,pincode)
                .subscribe((data :any)=>{

          });
        }
        else if ( message.content.includes("[url]:")){
          userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString);
          this.dataService.update(userMessage)
        }
        else if ( message.content.includes("[video]:"))
        userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString);
        else if  ( message.content.includes("[position]:"))
        {
          //if (this.isPositionAutorised) {
            userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null , null,dateString);
          //}
        }
        else if  ( message.content.includes("[link]:")){
          userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString);
          this.dataService.update(userMessage);
        }

        else
        userMessage= new Message(messagedata.text,sender, null, null, null,null, null , null,dateString);

        if(userMessage.content || userMessage.data || userMessage.location)
        this.dataService.update(userMessage);


        }

        else
        {

        botnum=0;
        /* if(message.sender_email.includes("@emer"))
        sender= 'user';
        else */
        sender= message.sender_full_name;

        /* if(saveListMessages.includes((message?.content) && !message?.content.includes('[position]:'))) {
          index++;
          continue;
        }else{
          saveListMessages.push((message?.content));
          localStorage.setItem('lastMessageSent', JSON.stringify(saveListMessages))
        } */

        let typeUser: string ='';
        if(message.sender_email.includes("@emer")){
          typeUser = "emergency_contact";
        }
        else if(message.sender_email.includes("@user")){
          typeUser = "owner";
        } else if(message.sender_email.includes("@finder")){
          typeUser = "finder";
        }

        if ( message.content.includes("[photo]:")){
          userMessage = new Message(null,sender, message.content.replace("[photo]:",""), null, null,null, null , null,dateString, null, null, null, typeUser);
          this.dataService.update(userMessage);
        }
        else if ( message.content.includes("[url]:")){
          userMessage = new Message(null, sender, null ,null, message.content.replace("[url]:",""),null,null,null,dateString, null, null, null,typeUser);
          this.dataService.update(userMessage);
        }
        else if  ( message.content.includes("[position]:"))
        {
          //if (this.isPositionAutorised ) {
            userMessage = new Message(null,sender, null, null,null,messagedata.text.replace("[position]:",""), null , null,dateString, null, null, null,typeUser);
          //}
        }
        else if ( message.content.includes("[video]:"))
        userMessage = new Message(null, sender, message.content.replace("[video]:","") ,null, null,null,null,null,dateString, null, null, null,typeUser);

        else if  ( message.content.includes("[link]:")){
          userMessage= new Message(null, sender, null ,null, message.content.replace("[link]:","") ,null,null,null,dateString, null, null, null,typeUser);
          this.dataService.update(userMessage);
        }
        else{
          if( !message.content.includes('cmd_open_medical_informations')){
            userMessage = new Message(messagedata.text,sender, null, null, null,null, null ,  null,dateString , null, null, null,typeUser);
          }
        }
        if(userMessage.content || userMessage.data || userMessage.location)
        this.dataService.update(userMessage);
        }

      }
  }

  ngAfterViewInit(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading = this.loaderService.isLoading;
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  lastScrollTopValue: number = 0;
  onScroll(event){
    this.scrollContainer = this.scrollFrame.nativeElement;

    let pos = this.scrollContainer?.scrollTop  + this.scrollContainer?.offsetHeight >= this.scrollContainer?.offsetHeight + (this.lastScrollTopValue);
    if(pos)
    this.lastScrollTopValue += 2
    else
    this.lastScrollTopValue -= 2
    this.collapseIfScrolled = pos;
    this.scrollChange.emit(pos);
   }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  ngOnInit() {


      if(localStorage.getItem('oldUser') === this.currentUser?.finder_info?.id && localStorage.getItem('currentChatbot')  === localStorage.getItem('oldChatbot') && (localStorage.getItem('sessionid') && localStorage.getItem('sessionid') !== 'undefined') ){
        this.dataservice.conversation  = new BehaviorSubject<Message[]>([]);
        this.allMessages = new BehaviorSubject<Message[]>([]);
        this.dataService.getmessages(this.currentUser?.finder_info?.id, this.currentUser.tag_info.tag_info.serial_number, 'finder')
      }
      else{
        /* if(this.dataservice.chatAlreadyStarted == true){
          this.dataservice.chatAlreadyStarted = false;
          this.dataservice.conversation  = new BehaviorSubject<Message[]>([]);
          this.allMessages = new BehaviorSubject<Message[]>([]);
          this.dataService.getmessages(this.currentUser.finder_info?.id, this.currentUser.tag_info.tag_info.serial_number, 'finder')
        }else{ */
          this.dataservice.conversation  = new BehaviorSubject<Message[]>([]);
          this.allMessages = new BehaviorSubject<Message[]>([]);
          localStorage.setItem("sessionid", undefined);
          localStorage.setItem('isOwnerReply',"false")
          this.dataService.init();
       // }
      }

      this.msgTemplate = this.msgTemplate ? this.msgTemplate : this.defaultMsgTemplate;
      this.inputTemplate = this.inputTemplate ? this.inputTemplate : this.defaultInputTemplate;
      this.allMessages =  null;
      this.allMessages = this.dataService.conversation.asObservable()
        .pipe(
          scan((acc, val) => {

            this.nbMessages = acc?.length
            if(val[0].sendBy == 'server')
            this.nbMessagesServer++;
            else
            this.nbMessagesServer = 0;

            this.nbMessages -=  this.nbMessagesServer;

            setTimeout(() => {
              this.msgArea.nativeElement.scrollTop = this.msgArea.nativeElement.scrollHeight;
            });
            if(this.dataService?.onPause?.response_type == 'pause' && this.dataService?.onPause?.typing == true){
              this.typingBehaviour.next(true);
              this.isTyping = this.typingBehaviour.asObservable();
              setTimeout(() => {
              this.typingBehaviour.next(false);
              this.isTyping = this.typingBehaviour.asObservable();
              }, this.dataService?.onPause?.time);
              this.dataService.onPause = null;
            }
            else
            this.typingBehaviour.next(false);
            if (ESendBy.bot === val[0]?.sendBy && !this.dataService.testbot) {
              if ( val[0]?.content != "undefined")
              this.onMsgReceive.emit(val[0]?.content);
            }
            return acc.concat(val);
          } )
        )

    if ( this.currentUser != null && !this.dataService.chatbegin)
    {
        this.socketService.onRoomEvent().subscribe((data) => {
       //   console.log(data)
          //this.socketService.eventRoom(JSON.parse(data as string));
          if(this.listEventRoom.includes((JSON.parse(data as string)).message))
          this.listEventRoom.push((JSON.parse(data as string)).message)
          else{
            this.listEventRoom.push((JSON.parse(data as string)).message)
            this.getMessageEvent(JSON.parse(data as string))
          }
          //console.log(JSON.parse(data as string));
        });

        this.socketService.onMessageEvent()
        .subscribe((data) => {
          const messageContent = JSON.parse(data as string)
          if(messageContent?.user?.user_type !== 'finder'){
              if( localStorage.getItem('lastTimeMessage') == undefined){
                localStorage.setItem('lastTimeMessage', messageContent.timestamp)
                this.getMessageEvent(messageContent)
                }
              else
            if( localStorage.getItem('lastTimeMessage') !== messageContent.timestamp){
              localStorage.setItem('lastTimeMessage', messageContent.timestamp)
              this.getMessageEvent(messageContent)
              }
            }
        });

        this.socketService.onEventRoom()
        .subscribe((roomEvent)=>{
          if(roomEvent != null){
            this.target.value = roomEvent.message;
            this.target.type = 'roomEvent'
            this.dataService.converseOwner(this.target, roomEvent?.user?.username);
          }
        });
    }
  }

  getMessageEvent(socketMessage){
    if(socketMessage?.message != null && !socketMessage?.message.includes('Finder chatbot_script_user_join')){
        this.Realtimeevent(socketMessage)
      }
  }

  Realtimeevent(messageEvent){
      this.dataService.chatbegin=true;
      var image ;

          if (messageEvent != null)
          {
            this.sharedService.detectMessages.next(true);
              if ( messageEvent?.message?.includes("[photo]:") )
              {
                      this.message= null;
                      this.dataService.pushcount( 1);
                      image =  messageEvent?.message?.replace("[photo]:","") ;
                      this.target.value = image;
                      this.target.type = "image";

              }
              else if ( messageEvent?.message?.includes("[url]:") )
                  {
                      this.message= null;
                      this.nummsg = this.nummsg+1;
                      this.dataService.pushcount( 1);
                      image =  messageEvent?.message?.replace("[url]:","")  ;
                      this.target.value = image;
                      this.target.type ='url';
              }
              else if ( messageEvent?.message?.includes("[link]:") )
              {
                  this.message= null;
                  this.nummsg = this.nummsg+1;
                  this.dataService.pushcount(1);
                  image =  messageEvent?.message?.replace("[link]:","") ;
                  this.target.value = image;
                  this.target.type ='url';


              }
              else if ( messageEvent?.message?.includes("[position]:") )
              {
                  this.message= null;
                  image =  messageEvent?.message?.replace("[position]:","") ;
                  this.target.value = image;
                  this.target.type ='location';
                  this.nummsg = this.nummsg+1;
                  this.dataService.pushcount(1);

              }
              else if ( messageEvent?.message?.includes("[pincode]:"))
                  {
                    let serial = localStorage.getItem("SerialNumber")
                    var pincode =messageEvent?.message?.replace("[pincode]:","")
                    localStorage.setItem("pincode",pincode)
                        this.authService.Getemergency(serial,pincode)
                        .subscribe((data :any)=>{
                          localStorage.setItem("pincode",pincode)
                        this.sharedService.getMedicaleInformation();
                    });
              }
              else
              {
                  image= null ;
                  this.nummsg = this.nummsg+1;
                  this.dataService.pushcount(1);
                  this.countChanged.emit(1);
                  this.target.value = messageEvent.message;
                  this.target.type = 'text'
              }

              let typeUser;
              messageEvent?.user?.user_type == 'users' ?  typeUser = 'owner' : typeUser = 'emergency_contact'
              this.sender = messageEvent?.user?.username;
              if ( this.target != null && this.sender != "pincode")
              this.dataService.converseOwner(this.target, this.sender, typeUser);
              this.dataService.testbot = false ;
              if(this.sender != 'Finder' && this.sender != 'server')
              this.playAudio()
              if (this.sender != 'Finder' && this.sender != 'server' && this.dataService.ownerrepley == false)
              {
                this.dataService.ownerrepley=true;
                if(localStorage.getItem('isOwnerReply') !== 'true')
                this.dataService.chatfirst("/Send_user_Response" ,"owner_did_reply",1)
                this.dataService.collapsedUser=false;
                localStorage.setItem('isOwnerReply',"true")
              }
          }
  }

    childFunc(num:number){
     this.countChanged.emit(num);

   }
   public  onChangefile(target: any){
  }

      playAudio(){
      /*  let audio = new Audio();
        audio.src = "./assets/audio/alertmessage.wav";
        audio.load();
        ai

        audio.muted = false;
        audio.play(); */

        this.beep()

      }

        /**
       * Helper function to emit a beep sound in the browser using the Web Audio API.
       *
       * @param {number} duration - The duration of the beep sound in milliseconds.
       * @param {number} frequency - The frequency of the beep sound.
       * @param {number} volume - The volume of the beep sound.
       *
       * @returns {Promise} - A promise that resolves when the beep sound is finished.
       */
        beep(duration?, frequency?, volume?){
          const myAudioContext = new AudioContext();
          return new Promise((resolve, reject) => {
              // Set default duration if not provided
              duration = duration || 200;
              frequency = frequency || 1700;
              volume = volume || 100;

              try{
                  let oscillatorNode = myAudioContext.createOscillator();
                  let gainNode = myAudioContext.createGain();
                  oscillatorNode.connect(gainNode);

                  // Set the oscillator frequency in hertz
                  oscillatorNode.frequency.value = frequency;

                  // Set the type of oscillator
                  oscillatorNode.type= "sine";
                  gainNode.connect(myAudioContext.destination);

                  // Set the gain to the volume
                  gainNode.gain.value = volume * 0.01;

                  // Start audio with the desired duration
                  oscillatorNode.start(myAudioContext.currentTime);
                  oscillatorNode.stop(myAudioContext.currentTime + duration * 0.001);

                  // Resolve the promise when the sound is finished
                  oscillatorNode.onended = () => {
                  };
              }catch(error){
                  reject(error);
              }
          });
      }

  public onChange(target: any) {

    if ( target.value === undefined)
    {
      target =  Event ;
      target.value = target;
    }

    this.msg.next(target.value);
    target.value = '';
  }

  ngOnDestroy(){
    if(this.isObject){
      this.dataService.conversation=new BehaviorSubject<Message[]>([]);
      const leaveRoom: LeaveRoomMessage = {
        'room': this.currentUser?.tag_info.tag_info?.serial_number
      }
      //this.socketService.leaveRoom(leaveRoom)
    }
    this.dataservice.conversation  = new BehaviorSubject<Message[]>([]);

  }

  @HostListener('window:hashchange', ['$event'])
  onHashChange(event) {

    if (this.subscription) {
      this.subscription?.unsubscribe();
      }
  }

   @HostListener('window:beforeunload', ['$event'])
    canLeavePage($event: any): Observable<void> {

      return null;
    }

  @HostListener('window:beforeunload')
  unsubscribemessage()
  {

    if (this.subscription) {
    this.subscription?.unsubscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: Event): void {

    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {

    if (this.subscription) {
      this.subscription?.unsubscribe();
    }
    return null ;

  }
  addItem(newItem: string) {
  }

  onKey(event: any) {
    if (event.keyCode == 13) {

    }
  }



}



    export class BloodInfo {
        blocks: any[];
        blood_diastolic?: any;
        blood_pressure?: any;
        blood_systolic?: any;
        blood_type?: any;
        crtical_infos?: any;
        diabates: any[];
        id_blood_type?: any;
    }
    export class CrtitcalInfo {
        allow: number;
        description: string;
        id: number;
        label: string;
    }
    export class EmerencyContact {
        emergency_contact_allow_chat: number;
        emergency_contact_mail1: string;
        emergency_contact_mail2: string;
        emergency_contact_mobile1: string;
        emergency_contact_tel: string;
        first_name: string;
        id: number;
        last_name: string;
    }

    export class physician_contact {
        first_name: string;
        id: number;
        last_name: string;
        mail: string;
        mail2: string;
        mobile: string;
        mobile2: string;
        speciality: string;
    }
    export class Heightweight {
        height_cm?: any;
        height_ft?: any;
        height_inch?: any;
        id_height_weight?: any;
        weight_kg?: any;
        weight_lbs?: any;
    }

    export class Allergies {
        active: number;
        blocks:  Block[];
    }

    export class Cancer {
        active: number;
        blocks:  Block[];
    }

    export class Implants {
        active: number;
        blocks:  Block[];
    }

    export class Medication {
        active: number;
        blocks:  Block[];
    }

    export class Neuroligic {
        active: number;
        blocks:  Block[];
    }

    export class Plumonary {
        active: number;
        blocks:  Block[];
    }

    export class Psychiatric {
        active: number;
        blocks:  Block[];
    }

    export class RenalKenedy {
        active: number;
        blocks:  Block[];
    }

    export class Cardiac {
        active: number;
        blocks:  Block[];
    }

    export class Block {
        active: number;
        description: string;
        documents: Document[];
        id: number;
        id_block?: any;
        label: string;
        reminders: Reminder2[];
    }
    export interface Document {
        active: number;
        data: string;
        doc_type_id: number;
        document_name: string;
        id: number;
        label_doc: string;
        label?: string;
        public: number;
    }
    export class Reminder2 {
        active: number;
        rappel_id: number;
        rappel_reminder: string;
        reminder_date: string;
        reminder_description: string;
        reminder_id: number;
        reminder_label: string;
        reminder_last_update: string;
        status: number;
    }
    export class InfectionDisaces {
        active: number;
        blocks: Block[];
    }

    export class MedicalDiseaces {
        Allergies: Allergies;
        Cancer: Cancer;
        Implants: Implants;
        Medication: Medication;
        Neuroligic: Neuroligic;
        Plumonary: Plumonary;
        Psychiatric: Psychiatric;
        "Renal (Kenedy)": RenalKenedy;
        cardiac: Cardiac;
        "infection disaces": InfectionDisaces;
    }

    export class OrganDonar {
        documents: any[];
        donar?: any;
        donar_information: string;
    }

    export class Resuscitate {
        active: number;
        allow: number;
        description: string;
        documents: any[];
        id?: any;
        id_medical_record: number;
        label: string;
    }

    export class MedicalRecord {
        blood_info: BloodInfo;
        crtitcal_info: CrtitcalInfo[];
        distitnct_sign?: any;
        donar?: any;
        donar_information: string;
        emerency_contact: EmerencyContact[];
        eye_color?: any;
        gender?: any;
        heightweight: Heightweight;
        id_eye_color?: any;
        id_gender?: any;
        id_marital_status?: any;
        id_medical_record: number;
        insurance_info: any[];
        marital_status?: any;
        medical_diseaces: MedicalDiseaces;
        miscilanious: any[];
        organ_donar: OrganDonar;
        other_medical_record_info: any[];
        pet_at_home?: any;
        physician_contact: physician_contact[];
        pincode: string;
        religion_label?: any;
        resuscitate: Resuscitate;
        spoken_languages?: any;
        immunization?: any;


    }
    export class Address {
        additional_adress?: any;
        country_label?: any;
        cp?: any;
        dep_name?: any;
        geom: string;
        latt: string;
        lng: string;
        region_name?: any;
        state_label?: any;
        street_name?: any;
    }

    export class BirthDateInfo {
        day: string;
        month: string;
        year: number;
    }

    export class Currency {
        active?: any;
        allow_value?: any;
        id?: any;
        id_currency_label?: any;
        value?: any;
    }

    export class UserGeneralInfo {
        active: number;
        address: Address;
        birth_date: string;
        birth_date_info: BirthDateInfo;
        code_phone?: any;
        currency: Currency;
        currency_label: string;
        expire_password?: any;
        first_name: string;
        id_phone_code?: any;
        id_picture_profile: number;
        last_name: string;
        last_update_pwd?: any;
        mail: string;
        mail2: string;
        mobile: string;
        mobile2?: any;
        profile_picture_url: string;
        reward?: any;
        role: number;
        role_label: string;
        tel: string;
        tel2?: any;
        user_id_language: string;
        sub_users: SubUser[];
        index: number;
    }

    export class SubUser {
        user_general_info: UserGeneralInfo;
        medical_record: MedicalRecord;
    }
    export class MedicalInfo {
        medical_record: MedicalRecord;
        medical_record_protection?: boolean;
        user_general_info: UserGeneralInfo;
    }




import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from 'src/environments/environment';

@Injectable()
export class TranslationService implements TranslateLoader {

    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any | null> {
    lang= localStorage.getItem('languageId')
    if(lang)
    return this.http.get<any>(`${environment.apiUrl}/get_translate_text_and_label_app?id_language=${lang}`)
    .pipe(map(data => {
      return data;
    }));
    else return this.http.get<any>(`${environment.apiUrl}/get_translate_text_and_label_app?id_language=en`)
    .pipe(map(data => {
      return data;
    }));;
    }

}



import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from "@angular/core";

@Component({
  selector: 'ngbd-modal-content',
  styleUrls: ['./model.component.css'],
  templateUrl: './model.component.html',

})
export class NgbdModalResponse {

  @Input() public text?:string;
  @Input() public type:string;
  @Input() public title:string;
  @Input() public color:string;
  constructor( private modalService: NgbModal) {}

  ngOnInit(){
  }

  public closemodel(){
    this.modalService.dismissAll()
  }

}

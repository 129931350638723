import { Injectable, OnInit }    from '@angular/core';
import 'rxjs/add/operator/map';
import { of } from 'rxjs';
import 'rxjs/add/operator/toPromise';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {  SafeHtml } from '@angular/platform-browser';
import { Input
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import {SharedData} from './../../models/sharedData.model'

@Injectable()
export class ChatService implements OnInit {

  labelHtml: any;
  content: SafeHtml;
  private session_id :string;

  @Input() test:boolean=false;
  private headers = new HttpHeaders( );
  private options = { headers: this.headers };
  constructor(  private http: HttpClient, private authService : AuthService ,private sharedData: SharedData) {

  }
  sendMessage(url :string ,message: any,sessionid :any ): Promise<any> {

    const currentUser = this.authService.currentUserValue;
    let assistant_id =localStorage.getItem("assistant_id");
    let lang = localStorage.getItem('browsercode');
    let phone = null;
    let sessionIdValue = '';
    if(sessionid == undefined && localStorage.getItem("sessionid") == 'undefined')
    sessionIdValue = undefined
    else
    sessionIdValue = localStorage.getItem("sessionid");

    if(currentUser?.tag_member.tag_creator_mobile != null || currentUser?.tag_member.tag_creator_mobile != null ){
      phone = currentUser?.tag_member.tag_creator_mobile
    } else if( currentUser?.tag_member.tag_creator_phones != null || currentUser?.tag_member.tag_creator_phones != null ){
      phone = currentUser?.tag_member.tag_creator_phones
    }
    // "32e869c7-520a-4132-9ed8-6019b7e16943"
    return this.http.get(`${environment.apiUrl}`+url+"?text="+message+"&code_lang="+lang+"&owner_thank_msg="+currentUser?.tag_info?.tag_info?.tag_custum_message  +"&owner="+(currentUser?.tag_info?.preference_user?.allow_share_name==="1" && currentUser?.tag_creator?.tag_creator_first_name!== null ? currentUser?.tag_creator?.tag_creator_first_name :'Owner')+"&tag="+(currentUser?.tag_info?.tag_info.tag_label !== null  ?currentUser?.tag_info?.tag_info.tag_label : "lost object")+"&phone="+(currentUser?.tag_info?.preference_user.allow_share_phone==="1"&&currentUser?.tag_creator?.tag_creator_mobile !== null ?currentUser.tag_creator?.tag_creator_mobile:null) +"&session_id=" + sessionIdValue +"&assistant_id=" +assistant_id,this.options).toPromise()
  }

  ngOnInit(){
    let msg ;
  }
}

<mat-card style="text-align: -webkit-center; text-align: -moz-center">
    <mat-card-header>
        <img [src]="pictureurl" class="card-img-top rounded-circle img-fluid" style="border-radius: 50px" />
        <div class="contenttext">
            <span>{{ userName }}</span>
        </div>
    </mat-card-header>
    <mat-card-content class="h-100">
        <div class="cardcenter">
            <div class="p-6 text-primary-contrast" fxLayout="row ">
                <span class="titlefinder">{{ "finder_lostpage_title" | translate }}</span>
            </div>
            <button class="btn-medical" target="_blank " rel="noopener" (click)="openemergency()">
                <div>
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg ">
                        <path
                            d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z "
                            fill="white " />
                        <path fill-rule="evenodd " clip-rule="evenodd " d="M24.3386 17.267C23.1074 17.267 22.0085 18.1421 21.6626 19.3326C21.5303 19.7904 21.5303 20.2585 21.5812 20.7571C21.7134 22.1103 22.8836 23.3008 23.9723 23.9622C24.1351 24.0639 24.3691
                    24.2268 24.5116 24.2675L24.5625 26.4652C24.5726 26.7603 24.6235 26.8519 24.6235 27.1978C23.9214 27.3606 22.3646 28.4596 22.3646 29.6195C22.3646 30.7896 24.0028 31.9903 24.7354 32.1633C24.7354 32.9162 24.7965 33.6081 24.7965 34.4221C24.7965 35.2361
                    24.6235 36.2231 25.3561 36.2231C26.1498 36.2231 28.0626 35.0937 28.0626 33.6285C28.0626 32.9569 27.3809 32.2243 26.9332 31.9903L26.862 33.3436C26.862 33.4758 27.4522 34.239 25.9157 35.2666C25.9157 33.2113 26.0276 31.3086 26.0276 29.2837C25.6105 29.3855
                    25 29.7416 24.6744 29.9654V30.6981C24.42 30.5658 23.7688 30.0672 23.7688 29.8535C23.7688 29.2125 24.2369 29.0497 24.8982 28.7241L26.3736 28.0526C27.2995 27.6761 28.4493 27.1877 28.4493 25.9056C28.4493 24.9492 27.737 24.3794 27.1469 23.9826C27.1469
                    25.7225 27.0655 25.9667 26.1294 26.4652C26.1294 25.3765 26.1904 24.4811 26.1904 23.4229L26.2413 21.1641C26.2413 20.8283 26.3024 20.7571 26.3024 20.4315C25.702 20.4213 25.4273 20.2076 24.3793 20.2076C24.3793 20.6451 24.4403 20.8995 24.4403 21.276C24.4403
                    21.6525 24.4403 22.0289 24.4403 22.4054C24.1351 22.2426 23.7891 21.8865 23.5958 21.6219C23.1888 21.0623 22.8937 20.0957 23.5042 19.5462C23.7383 19.3326 24.0028 19.2613 24.42 19.2105C25.0916 19.1291 27.7371 20.1567 27.1876 18.5491C27.0452 18.1421 26.689
                    17.8775 26.3736 17.6639L26.4347 14.7335C26.4347 13.4412 24.1351 13.3598 24.2776 15.0184C24.3081 15.3643 24.2877 15.7815 24.2877 16.1376C24.2775 16.5955 24.3386 16.8702 24.3386 17.267ZM22.2832 8.47583H27.7167C28.6426 8.47583 29.4058 9.23895 29.4058
                    10.1649V17.2263L35.4701 13.604C36.2637 13.1258 37.3117 13.3904 37.7798 14.184L40.5677 18.8543C41.046 19.648 40.7814 20.696 39.9878 21.1641L33.5775 25.0001L39.9878 28.836C40.7814 29.3142 41.046 30.3521 40.5677 31.1458L37.7798 35.8161C37.3016 36.6097
                    36.2637 36.8743 35.4701 36.3961L29.4058 32.7738V39.8352C29.4058 40.7611 28.6426 41.5243 27.7167 41.5243H22.2832C21.3573 41.5243 20.5942 40.7611 20.5942 39.8352V32.7738L14.5299 36.3961C13.7362 36.8743 12.6882 36.6097 12.2202 35.8161L9.4322 31.1458C8.95397
                    30.3521 9.21853 29.3041 10.0122 28.836L16.4224 25.0001L10.0122 21.1641C9.21853 20.6858 8.95397 19.648 9.4322 18.8543L12.2202 14.184C12.6984 13.3904 13.7362 13.1258 14.5299 13.604L20.5942 17.2263V10.1649C20.5942 9.23895 21.3573 8.47583 22.2832 8.47583Z
                    " fill="#E82127 " />
                    </svg>
                </div>
                <span class="findermedical">{{ "finder_lostpage_btn_medical" | translate }}?</span>
            </button>

            <button class="lost mt-4" target="_blank" rel="noopener" fxLayout="row" (click)="openchatbot()">
              <img src="/assets/img/foudme-logo.png" [alt]="'finder_lostpage_btn_lost' | translate"/>

                <span >{{
                    "finder_lostpage_btn_lost" | translate
                    }}</span>
            </button>
            <button class="lost mt-4" target="_blank" rel="noopener" fxLayout="row" (click)="openchatbotchild()">
              <img src="/assets/img/foudme-logo.png" [alt]="'lost_person_finder_value' | translate"/>

                <span>{{'lost_person_finder_value' | translate}}</span>
            </button>
        </div>
    </mat-card-content>
</mat-card>

<div class="content" fxLayoutAlign="center">
    <div class="container">
        <div class="col-md-12 col-sm-12" style="padding-top:50px">
            <div class="p-6 pb-4 bg-header" fxLayout="column" fxLayoutAlign="center center">
                <svg width="119" height="67" viewBox="0 0 119 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1307:218)">
                    <path d="M95.0698 0.503047H105.905C108.14 0.45794 110.356 0.914103 112.392 1.83804C114.188 2.65882 115.705 3.98526 116.759 5.65504C117.777 7.4339 118.313 9.44816 118.313 11.498C118.313 13.5479 117.777 15.5622 116.759 17.341C115.705 19.0105 114.187 20.3366 112.392 21.157C110.357 22.0817 108.14 22.5382 105.905 22.493H95.0698V0.503047ZM105.593 16.715C106.288 16.7493 106.982 16.6463 107.637 16.4119C108.292 16.1775 108.895 15.8163 109.41 15.349C110.333 14.2788 110.841 12.9125 110.841 11.499C110.841 10.0856 110.333 8.71926 109.41 7.64904C108.895 7.18143 108.292 6.82 107.637 6.58543C106.982 6.35086 106.288 6.24776 105.593 6.28204H102.483V16.715H105.593Z" fill="white"/>
                    <path d="M92.0778 0.503052V22.4921H85.9838L77.6278 12.502V22.4921H70.4038V0.503052H76.4978L84.8538 10.4921V0.503052H92.0778Z" fill="white"/>
                    <path d="M56.6988 22.994C53.3275 22.994 50.6995 22.083 48.8148 20.261C46.9302 18.439 45.9875 15.884 45.9868 12.596V0.503052H53.3998V12.377C53.2984 13.6124 53.6017 14.8472 54.2638 15.8951C54.5809 16.2417 54.9666 16.5185 55.3965 16.7079C55.8264 16.8973 56.2911 16.9951 56.7608 16.9951C57.2306 16.9951 57.6952 16.8973 58.1251 16.7079C58.555 16.5185 58.9408 16.2417 59.2578 15.8951C59.9206 14.8475 60.2243 13.6125 60.1228 12.377V0.503052H67.4098V12.597C67.4098 15.885 66.4675 18.44 64.5828 20.262C62.6982 22.084 60.0698 22.995 56.6978 22.995" fill="white"/>
                    <path d="M31.0868 22.9941C28.8725 23.0299 26.6831 22.5231 24.7098 21.5181C22.8694 20.5698 21.3257 19.133 20.248 17.3654C19.1702 15.5977 18.6001 13.5674 18.6001 11.4971C18.6001 9.42675 19.1702 7.39643 20.248 5.62875C21.3257 3.86108 22.8694 2.42432 24.7098 1.47606C26.6951 0.504707 28.8761 -0.000244141 31.0863 -0.000244141C33.2965 -0.000244141 35.4775 0.504707 37.4628 1.47606C39.3031 2.42432 40.8468 3.86108 41.9246 5.62875C43.0023 7.39643 43.5724 9.42675 43.5724 11.4971C43.5724 13.5674 43.0023 15.5977 41.9246 17.3654C40.8468 19.133 39.3031 20.5698 37.4628 21.5181C35.4894 22.5232 33.3 23.0299 31.0858 22.9941H31.0868ZM31.0858 16.9941C31.9657 17.0057 32.8315 16.7716 33.5858 16.3181C34.342 15.8543 34.956 15.1914 35.3608 14.4021C35.795 13.495 36.0204 12.5022 36.0204 11.4966C36.0204 10.4909 35.795 9.49811 35.3608 8.59106C34.956 7.80172 34.3419 7.13888 33.5858 6.67506C32.8279 6.23231 31.966 5.99898 31.0883 5.99898C30.2105 5.99898 29.3486 6.23231 28.5908 6.67506C27.8347 7.13871 27.2209 7.80161 26.8168 8.59106C26.3826 9.49811 26.1572 10.4909 26.1572 11.4966C26.1572 12.5022 26.3826 13.495 26.8168 14.4021C27.2209 15.1915 27.8347 15.8544 28.5908 16.3181C29.3438 16.7711 30.208 17.0055 31.0868 16.9951L31.0858 16.9941Z" fill="white"/>
                    <path d="M7.41299 6.09404V9.86404H16.586V15.456H7.41299V22.4921H0V0.503052H16.586V6.09404H7.41299Z" fill="white"/>
                    <path d="M82.54 55.6V65.65H50.415V26.129H82.54V36.179H63.514V40.809H80.2231V50.409H63.514V55.603L82.54 55.6Z" fill="white"/>
                    <path d="M35.286 65.65L35.174 47.639L26.592 62.089H20.721L12.139 48.257V65.646H0V26.129H10.953L23.825 47.189L36.359 26.13H47.312L47.424 65.651L35.286 65.65Z" fill="white"/>
                    <path d="M97.2911 50.5191C97.2339 48.9565 97.6467 47.4126 98.4761 46.0871C99.4604 44.6776 100.597 43.3805 101.864 42.2191C102.602 41.5355 103.29 40.8003 103.924 40.0191C104.339 39.5077 104.568 38.8706 104.574 38.2121C104.583 37.8217 104.488 37.436 104.3 37.094C104.111 36.752 103.836 36.466 103.501 36.2651C102.687 35.7668 101.744 35.5208 100.791 35.5581C99.5829 35.5595 98.403 35.9235 97.4041 36.6031C96.3471 37.3025 95.4909 38.2655 94.9201 39.3971L84.8711 34.3721C86.3761 31.5116 88.7072 29.1704 91.5611 27.6531C94.94 25.9361 98.698 25.101 102.486 25.2251C107.077 25.2251 110.803 26.1661 113.664 28.0481C115.018 28.8784 116.128 30.0522 116.882 31.4504C117.636 32.8487 118.006 34.4215 117.955 36.0091C117.987 37.435 117.708 38.8508 117.137 40.1581C116.651 41.2527 115.994 42.263 115.19 43.1511C114.285 44.1026 113.324 44.9982 112.31 45.8331C111.381 46.6131 110.512 47.4622 109.71 48.3731C109.198 48.9704 108.918 49.7315 108.919 50.5181L97.2911 50.5191ZM103.106 66.2191C101.126 66.2951 99.1972 65.5835 97.7411 64.2401C97.0721 63.6341 96.5398 62.8927 96.1797 62.065C95.8195 61.2374 95.6396 60.3426 95.6521 59.4401C95.6308 58.5407 95.8068 57.6475 96.1677 56.8234C96.5286 55.9993 97.0656 55.2643 97.7411 54.6701C99.2608 53.4462 101.153 52.7788 103.105 52.7788C105.056 52.7788 106.948 53.4462 108.468 54.6701C109.144 55.2643 109.681 55.9993 110.042 56.8234C110.402 57.6475 110.578 58.5407 110.557 59.4401C110.57 60.3426 110.39 61.2374 110.03 62.065C109.669 62.8927 109.137 63.6341 108.468 64.2401C107.012 65.581 105.084 66.2908 103.106 66.2141V66.2191Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1307:218">
                    <rect width="118.316" height="66.214" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>


            </div>
            <div class="d-inline-block bg-white">
                <div class="taillemax p-2" id="belong">
                    <span><strong> {{'finder_freemium_two' | translate}}</strong></span>
                </div>
                <div class="champpersonnel" *ngIf="message!== null || name!== null || tel!== null || email!== null ">
                    <div class="container-regular p-0">
                        <span class="p-0">{{message}}</span>
                        <span class="mt-3" *ngIf="name!== null">{{name}}</span>
                        <span *ngIf="tel">{{'editprofil_label_tel' | translate}} : {{tel}}</span>
                        <span *ngIf="email!== null" [title]="email">{{expandEmail(email)}}</span>
                    </div>
                </div>


                <!--  start new bloc -->
                <div class="mx-3 taillemax">
                    <h5>
                        <strong>{{'freemium_finder_one' | translate}}</strong>
                    </h5>
                </div>


                <div class="border-form">
                    <form class="w-100" [formGroup]="freeForm" (ngSubmit)="navigate()">
                        <div class="row container-bold">
                            <div class="w-100 text-left">
                                <span for="inputEmail p-0"><strong class="p-0">{{"editprofil_general_label_firstname" | translate}}</strong>
                              </span>
                                <input name="firstname" type="text" matInput required class="form-control style-form-input" aria-describedby="Firstname" [placeholder]="'editprofil_general_label_firstname' | translate" formControlName="firstName">
                                <div *ngIf="(getFirstName.invalid && getFirstName.touched) || getFirstName.dirty">
                                    <small *ngIf="getFirstName.errors?.required" class="text-danger">{{'inputchecker_label_firstnamerequired' | translate}}</small>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-12 form-group pt-1 mt-2" style="text-align: start;">
                                <span for="inputEmail"><strong class="p-0">{{'editprofil_general_subtitle_email' | translate}}</strong></span>
                                <input name="email" type="email" matInput required class="form-control style-form-input" aria-describedby="email" [placeholder]="'placeholder_email' | translate" formControlName="email">
                                <div *ngIf="(getEmail.invalid && getEmail.touched) || getEmail.dirty">
                                    <small *ngIf="getEmail.errors?.required" class="text-danger">{{'inputchecker_label_emailrequired' | translate}}</small>
                                    <!--question mark(?) is a safe navigation operator-->
                                    <small *ngIf="getEmail.errors?.pattern" class="text-danger">{{'chatbot_script_medical_validemail' | translate}} </small>
                                </div>
                            </div>
                            <div class="w-100 text-left mt-2">
                                <span for="inputEmail p-0"><strong class="p-0">{{"editprofil_general_label_lastname" | translate}}</strong></span>
                                <input name="lastname" type="text" matInput class="form-control style-form-input" aria-describedby="Lastname" [placeholder]="'editprofil_general_label_lastname' | translate" formControlName="lastName">
                            </div>
                            <div class="w-100 text-left mt-2">
                                <span for="inputEmail p-0"><strong class="p-0">{{'editprofil_general_subtitle_phonenumbers' | translate}}</strong></span>
                                <input name="phone" type="text" matInput class="form-control style-form-input" aria-describedby="PhoneNumber" [placeholder]="'editprofil_general_subtitle_phonenumbers' | translate" formControlName="phone">
                            </div>
                            <div class="col-md-12 col-sm-12 form-group pt-2" style="text-align: start;">
                                <textarea name="Message" type="text" class="form-control style-form-input" matInput rows="2" [placeholder]="'label_note_app' | translate" formControlName="note"> </textarea>
                            </div>
                           <!-- <section class="example-section">
                                 <mat-checkbox>
                                    <span class="checkbox-reward-label">Yes, I'm interested in receiving a FOUND ME reward.</span>
                                </mat-checkbox>

                            </section>-->

                            <div id="slide">
                              <div class="d-flex" >
                                <label class="switch" for="checkbox">
                                  <input type="checkbox" id="checkbox"  name="reward" formControlName="reward"/>
                                  <div class="slider round"></div>
                                </label>
                                <span class="checkbox-slider-span">Yes, I'm interested in receiving a FOUND ME reward.</span>

                              </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12 col-sm continue">
                                <button type="submit" class="btn btn-lg btn-block" [disabled]="!freeForm.valid">
                                  <div class="spinner-border text-light" role="status" *ngIf="loading">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                              <span>{{'editprofil_label_send' | translate}}</span>
                          </button>
                            </div>
                        </div>
                    </form>
                </div>
                <!--  start new bloc -->
                <div>
                    <p class="container-bottom pb-3">{{'finder_label_buttonwebsite' | translate}}
                        <a href="https://www.FOUNDME.com/" class="text-dark" target="_blank">www.FOUNDME.com</a></p>
                </div>
            </div>


        </div>
    </div>
</div>

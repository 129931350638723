import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isScrolled: boolean = false;
  isScrolledChange = new BehaviorSubject<boolean>(false);
  discussionChange = new BehaviorSubject<boolean>(false);
  medicalInformation = new BehaviorSubject<boolean>(false);
  detectMessages = new BehaviorSubject<boolean>(false);
  countMessages= new BehaviorSubject<number>(0);
  detectMessageSent= new BehaviorSubject<boolean>(false);

  constructor(private _httpClient: HttpClient) { }

  scrollDetector(value: boolean): void {
    this.isScrolled = value;
    this.isScrolledChange.next(value);
  }

  onDetectMessageSent(): Observable<boolean> {
    return this.detectMessageSent.asObservable();
}

  onChangeScrollDetected(): Observable<boolean> {
      return this.isScrolledChange.asObservable();
  }

  getDiscussionEmergency(value: boolean): void {
    this.discussionChange.next(value);
  }

  getDiscussionEmergencyEvent(): Observable<boolean> {
      return this.discussionChange.asObservable();
  }

  getMedicaleInformation(): void {
    this.medicalInformation.next(true);
  }

  medicalInformationChange(): Observable<boolean> {
      return this.medicalInformation.asObservable();
  }

  countMessagesChange(): Observable<number> {
    return this.countMessages.asObservable();
  }

  detectMessagesChange(): Observable<boolean>  {
    return this.detectMessages.asObservable();
  }

  resetPassword(idUser :string ,currentPassword: string,newPassword :string ): Observable<any> {

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('id_user',idUser)
    queryParameters = queryParameters.append('confirm_password',encodeURIComponent(currentPassword))
    queryParameters = queryParameters.append('new_password',encodeURIComponent(newPassword))
    queryParameters = queryParameters.append('version','2')
    return this._httpClient.request<any>('get', environment.apiUrl+"/restore_password",
    {
        params: queryParameters,
    });
  }

  startVideoCall(typeUser: string): Observable<any> {
    const currentUser: User =  JSON.parse(localStorage.getItem('currentUser'));

    let queryParameters = new HttpParams();
    queryParameters = queryParameters.append('id_user',currentUser?.finder_info?.id)
    queryParameters = queryParameters.append('user_type',typeUser)
    queryParameters = queryParameters.append('sn',currentUser?.tag_info.tag_info?.serial_number)
    return this._httpClient.request<any>('get', environment.apiUrl+"/create_whereby",
    {
        params: queryParameters,
    });
  }
}

export class PetInformations {
    currency:               Currency = new Currency();
    emergency_contact_user: any[]=[];
    other_info:             any[]=[];
    preference_user:        { [key: string]: PreferenceUser };
    tag_info:               TagInfo =new TagInfo() ;
    tag_user_info:          TagUserInfo =new TagUserInfo() ;
    general_info:           GeneralInfo=new GeneralInfo();
    member_info:            MemberInfo =new MemberInfo() ;
    vaccins:                any[];
}





export class Currency {
    active:             any;
    allow_custum_field: any;
    allow_value:        any;
    custum_field:       any;
    id:                 any;
    id_currency_label:  any;
    value:              any;
}

export class GeneralInfo {
    breed:            null;
    active:           number;
    deleted:          number;
    birth_date_info:  BirthDateInfo;
    is_downgraded:    any;
    id_pet:           any;
    id_picture:       any;
    color:            any;
    pet_picture_url:  string;
    date_birth:       string;
    diet:             null;
    distincts_signs:  null;
    heightweight:     Heightweight;
    id_gender:        null;
    id_height_weight: null;
    id_member:        string;
    id_type:          number;
    microscopic:      Microscopic;
    name:             string;
    thank_you_msg:    null;
}

export class BirthDateInfo {
    day:   string;
    month: string;
    year:  number;
}

export class Heightweight {
    id_height_weight: any;
    height_cm:        any;
    height_ft:        any;
    height_inch:      any;
    weight_kg:        any;
    weight_lbs:       any;
}

export class Microscopic {
    id:                any;
    michrochip_number: any;
    note:              any;
}

export class MemberInfo {
    first_name:        string;
    last_name:         string;
    mail:              string;
    mail2:             string;
    mobile:            string;
    phone_code_string: string;
}

export class PreferenceUser {
    acces_label_txt: string;
    active:          number;
    id_field:        number;
    id_field_app:    string;
    value:           string;
}

export class TagInfo {
    active:              number;
    active_emergency:    number;
    archive:             number;
    emergency:           number;
    features:            { [key: string]: number };
    id_finder_website:   number;
    id_member:           string;
    id_picture:          number;
    id_tag:              number;
    id_tag_categorie:    number;
    id_type:             number;
    is_premium_limitted: boolean;
    pack_id_app:         string;
    pack_str:            string;
    picture_name:        string;
    picture_url:         string;
    serial_number:       string;
    tag_custum_message:  any;
    tag_description:     any;
    tag_features:        { [key: string]: number };
    tag_label:           any;
    user_features:       { [key: string]: number };
}

export class TagUserInfo {
    first_name: string;
    id_user:    string;
    last_name:  string;
    mail:       string;
    mail2:      string;
    mobile:     string;
    phone_code: string;
}
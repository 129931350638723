import { SharedService } from './../../../services/shared-service/shared.service';
import { LoaderService } from './../../../services/loader/loader.service';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import icPhoto from '@iconify/icons-ic/photo-camera';
import icPlace from '@iconify/icons-ic/place';
import { Subject, interval } from 'rxjs';
import { DataService } from 'src/app/services/chat/data.service';

@Component({
  selector: 'chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.css']
})
export class ChatInputComponent implements OnInit {
  icPhoto = icPhoto;
  icPlace = icPlace;
  textInput: string = '';
  message:string ;
  @Output() target: any =Event;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() newMessageEvent = new EventEmitter<any>();
  @Output() onEmitTextMessage = new EventEmitter<any>();
  @ViewChild('defaultButton') defaultButton : ElementRef;

  textmessage :string = '';
  @Input() collapsed: any;
  @Input() msg: Subject<any>;
  selectedFile :File = null ;
  fileToUpload: File = null;
   url : string = null ;
   isOwnerReply: boolean = false;
   isLoading: Subject<boolean> = this.loaderService.isLoading;
   isDisabledInput: boolean = true;
  interval: any;
  constructor( private _sharedService :SharedService, private loaderService: LoaderService) {
  }

  ngOnInit( ) {
    if(localStorage.getItem('isOwnerReply') == 'true') this.isOwnerReply = true;
    this.interval = interval(5000).subscribe(()=>{
      if(localStorage.getItem('isOwnerReply') == 'true') this.isOwnerReply = true;
    });
    this._sharedService.detectMessagesChange()
    .subscribe((value: boolean)=>{
      this.isDisabledInput = value;
    })
  }

  addNewItem() {
    this.newItemEvent.emit();
  }

  onChange() {
    let target = {
      'value': this.textmessage,
      'typing': true
    };
    this.textmessage = '';
    this.onEmitTextMessage.emit(target);
  }


  onKey(event: any) {
  }

  onEnter() {
  }

}

import { Component, EventEmitter, Input } from "@angular/core";
import { DataService } from "src/app/services/chat/data.service";
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
class data {
  showMe;
  loading;
  chatvideo ;
  urlSafe;
}
class Videocall{
  meeting_url ;
}
@Component({
  selector: 'ngbd-popupmodeldoc-content',
  styleUrls: ['./popupmodeldoc.component.css'],
  templateUrl: './popupmodeldoc.component.html',

})
export class NgbdPopupmodelDocContent {
  @Input() public chatinfo:boolean;
  @Input() public srcimage:any;
  @Input() public test:any;
  @Input() public texte:string;
  @Input() public color:any;
  @Input() public title:string;
  @Input() public type:string;
  @Input() public number = 0;
  @Input() public buttonText = 'editprofil_label_clickhere';

  showMe :boolean;
  chatvideo  :boolean;
  public event: EventEmitter<any> = new EventEmitter();
  loading :boolean;
  datamodel = new  data ;
  errorShareLocation: boolean = false;

  constructor(public dataservice :DataService,public sanitizer: DomSanitizer, private route: Router) {
  }
  ngOnInit(){
    if(this.texte == 'pop_erreur_case_location')
      this.errorShareLocation = true;
  }

  triggerEvent() {
    this.event.emit({data: 12345});
  }
  public openvideo()
  {
    if ( this.type === "alert")
    {
    this.datamodel.loading=true;
    this.datamodel.chatvideo=true;
    //this.event.emit( this.datamodel);

    }
    this.event.emit( this.datamodel);

  }
  public closemodel()
  {
    this.event.emit( this.datamodel);

  }

}

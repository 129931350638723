import { NgbdPopupmodelDocContent } from 'src/app/Pages/listmodal/popupmodeldoc/popupmodeldoc.component';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UpdateFinderInformation } from './../../models/chatbotmessage';
import { DataService } from 'src/app/services/chat/data.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  currentUser : User ;
  name : string ;
  tel : string ;
  email : string ;
  message: string ;
  loading: boolean = false;
  checked = false;
  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;
  freeForm: FormGroup;
  constructor(private authService :AuthService, private dataservice: DataService, private _formBuilder: FormBuilder, private router: Router, private modalService: NgbModal) {
    this.currentUser = this.authService.currentUserValue;
    this.name =   this.currentUser?.tag_creator?.tag_creator_first_name;
    this.tel =   this.currentUser?.tag_creator?.tag_creator_mobile;
    this.email =  this.currentUser?.tag_creator?.email;
    this.message=this.currentUser?.tag_info.tag_info?.tag_custum_message;
   }

  ngOnInit(): void {
    this.freeForm = this._formBuilder.group({
      email: new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl(''),
      phone: new FormControl(''),
      note: new FormControl(''),
      reward: new FormControl(false),
    })
  }

  navigate() {
    this.loading = true;
    let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
    updateFinderInformation.email = this.freeForm.value.email;
    updateFinderInformation.first_name = this.freeForm.value.firstName;
    updateFinderInformation.last_name = this.freeForm.value.lastName;
    updateFinderInformation.phone = this.freeForm.value.phone;
    updateFinderInformation.note = this.freeForm.value.note;
    if(this.freeForm.value.reward == true)
      updateFinderInformation.reward_request = 1;
    else
      updateFinderInformation.reward_request = 0;
    this.dataservice.updateUser(this.currentUser?.finder_info?.id, updateFinderInformation).pipe()
      .subscribe((data)=>{
        this.dataservice.sendFinderInfoEmail(this.currentUser?.finder_info?.id)
        .subscribe((data)=>{
            this.openSuccess('');
            this.loading = false;
        },()=>{
          this.loading = false;
        });
      },error=>{
        this.loading = true;
        this.openError('Error when submit the form')
      });
    }

    openError(content) {
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
       windowClass : "myCustomModalClass",

      };

      const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
      modeldoc.componentInstance.chatinfo = true;
      modeldoc.componentInstance.number = 1;
      modeldoc.componentInstance.title =content;
      modeldoc.componentInstance.texte="finder_response_titleerror";
      modeldoc.componentInstance.color="#F4C809";
      modeldoc.componentInstance.type="error";
      modeldoc.componentInstance.event.subscribe((data:any) => {

        modeldoc.close();
        this.router.navigate(['']);
      });
    }

    expandEmail(name: string): string {
      if (name?.length < 40) {
        return name;
      } else {
        return name.substring(0, 35) + '...';
      }
    }

    openSuccess(content) {
      let options: NgbModalOptions = {
        ariaLabelledBy: 'myBasicModalLabel',
        size: 'sm',
        centered :true,
        animation :true,
        backdrop: true,
        keyboard: true,
       windowClass : "myCustomModalClass",

      };

      const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
      modeldoc.componentInstance.chatinfo = true;
      modeldoc.componentInstance.number = 4;
      modeldoc.componentInstance.title ='finder_info_sent_body';
      modeldoc.componentInstance.texte="finder_info_sent_title";
      modeldoc.componentInstance.buttonText= "OK"
      modeldoc.componentInstance.color="#04a6bc";
      modeldoc.componentInstance.type="error";
      modeldoc.componentInstance.event.subscribe((data:any) => {
        modeldoc.close();
        this.router.navigate(['']);
      });
    }


    get getEmail(){
      return this.freeForm.get('email')
    }

    get getFirstName(){
      return this.freeForm.get('firstName')
    }
}

import { TranslateService } from '@ngx-translate/core';
import { ParamApplication } from './../../models/ParamApp';

import { BehaviorSubject, Observable, throwError,Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable, Output, ChangeDetectorRef } from '@angular/core';
import { User } from '../../models/user.model'
import { MedicalInfo } from '../../models/MedicalInformation';
import { Router } from '@angular/router';
@Injectable()

export class AuthService {
  private router: Router;
  private _listnersMedical$ = new Subject<any>();
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public paramApp: Observable<ParamApplication>;
  public paramAppSubject: BehaviorSubject<ParamApplication>;
  public medicalUserSubject: BehaviorSubject<MedicalInfo>;
  public medicalUser: Observable<MedicalInfo>;
  private currentLanguagesSubject: BehaviorSubject<any>;
  public currentLanguages: Observable<any>;
  private headers = new HttpHeaders({"Content-Type": "application/json","Access-Control-Allow-Origin":"*"});
  private options = { headers: this.headers };
  constructor(private http: HttpClient, private translateService: TranslateService) {

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.medicalUserSubject = new BehaviorSubject<MedicalInfo>(JSON.parse(localStorage.getItem('currentMedicalUser')));
    this.medicalUser = this.medicalUserSubject.asObservable();
    this.currentLanguagesSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentLanguage')));
    this.currentLanguages = this.currentLanguagesSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get medicalUserValue(): MedicalInfo {

    return this.medicalUserSubject.value;

  }

  public get paramAppValue(): ParamApplication {
    return this.paramAppSubject.value;
  }

  public get currentLanguagesValue(): any {
    return this.currentLanguagesSubject.value;
  }

  listenMedicalInfo(): Observable<any> {
    return this._listnersMedical$.asObservable();
  }

  pushMedicalInfo(filterBy: any) {
    this._listnersMedical$.next(filterBy);
  }

  login(SerialNumberInput: string, idDevice: string, userId?: string) {
    let idUser='';
    if (userId !== null)
    idUser = '&id_user='+userId

    return this.http.get<any>(`${environment.apiUrl}/finder_scan_tag?sn=` + SerialNumberInput.replace('#','')+'&id_device='+idDevice+ idUser)
      .pipe(map(user => {
        if (user && !user?.error) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }
        return user;
      }));
  }
  sendsmsmail(sn:string, choice?: string){
    let isMedical = 0;
    if(localStorage.getItem('currentChatbot') === 'medical')
    isMedical = 1;
    else
    isMedical = 0;

    let choiceChat = "";
    if(choice) choiceChat = "&choice="+choice;

    return this.http.get<any>(`${environment.apiUrl}/send_sms_mail_to_allowed_emergency?is_medical=`+ isMedical+"&sn=" + sn+choiceChat)
    .pipe(map(data => {
      return data;
    }));
  }

  Getemergency(SerialNumberInput: string, pincode:string) {
    return this.http.get<any>(`${environment.apiUrl}/get_medical_info_when_object_scaned?sn=`+SerialNumberInput +"&pincode="+pincode)
      .pipe(map(
        emergencydata => {
        localStorage.setItem('currentMedicalUser', JSON.stringify(emergencydata));
        this.medicalUserSubject.next(emergencydata);
        //this.changeDetector.detectChanges();
        this.medicalUser = this.medicalUserSubject.asObservable();
        this.pushMedicalInfo( this.medicalUserSubject.value);
        return emergencydata;

      }),
      catchError(err => {
        const error = err.error.message || err.statusText;
        if(err.status === 500)
        {
           //window.alert(error)
        }

        return error;
      })
     );
    }
 ApprouveEmereg(SerialNumber:string)
  {
    return this.http.get<any>(`${environment.apiUrl}/validate_generate_pin_code?sn=` + SerialNumber)
  }
  getTranslationId(lang: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/get_id_language_from_abr?abr=${lang}`)
    .pipe(map(data => {
      return data;
    }));
    }

  translationMessage(msg: string): Observable<any> {
      // let username = 'a88d44f4-92a8-44ff-a084-9f99c3811905';
      //  let password =
      //      'sZu0ZIX1QOKdO6QLxKsxJUJGJpAJF2vOS1AZu79VH8zOrFhf8n47pi7vDa5nTICg';

      let headers= new HttpHeaders();
      headers = headers.append('apiKey', environment.translateApiKey);

      // ({ 'Content-Type': 'application/json',
      const browserlang = this.translateService.getBrowserLang();

      var userLang = browserlang !== null ? browserlang : 'fr'
      // 'Authorization':"Basic " + btoa(username + ':' + password)}) ;
      var raw = {
        "text":msg,
        "translate_to":userLang
      };

      return this.http.request<any>('post', `${environment.apiUrl}/chatbot_translation`,
      {
        headers: headers,
        body:raw,
      });

      }

    public getIPAddress()
    {
          return this.http.get("http://api.ipify.org/?format=json");
    }

    public blocip(ip : string){
      return this.http.get(`${environment.apiUrl}/block_ip?ip=${ip}`)
    }

    logout() {
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      this.router.navigate(['']);
    }

    getParamApp(){
      return this.http.get<any>(`${environment.apiUrl}/get_param_app` )
      .pipe(map(
        data => {
        this.paramAppSubject= new BehaviorSubject<ParamApplication>(data);
        this.paramApp = this.paramAppSubject.asObservable();
        return data;

      }),
     );
    }

    getViewUser(idUser: string) {
      let queryParameters = new HttpParams();
      queryParameters = queryParameters.append('id_user', idUser)
      return this.http.get(`${environment.apiUrl}/view_user?sn=` + idUser,{
        params: queryParameters
      });
    }

}

<mat-card class="p-0" (countChanged)="childFunc($event)" [ngClass]="{'heightchat' : !chatview, 'heightchathidden': chatview}">
    <ng-container *ngIf="loading" style="margin: 40px;">
        <mat-spinner style="stroke: #04A6BC;" [diameter]="25"></mat-spinner>
    </ng-container>
    <ng-container [ngTemplateOutlet]="template">

        <!--, 'custom-emergency-window':!isMedicaleReleased && chatview-->
        <mat-card-content class="emergency-bg-image" [ngClass]="{'msgArea' : !chatview, 'msgAreahidden': chatview}" #msgArea id="scrollbar-window" #scrollframe (scroll)="onScroll($event)">

            <ng-container *ngFor="let msg of allMessages | async; let index = index;" [ngTemplateOutlet]="defaultMsgTemplate" [ngTemplateOutletContext]="{  sendBy: msg.sendBy,
                                       text: msg.content,
                                       image : msg.source,
                                       data  : msg.data,
                                       option : msg.option,
                                       url : msg.url,
                                       location : msg.location,
                                       video : msg.video,
                                       num : msg.num,
                                       time : msg.time,
                                       index: index,
                                       nbMessages: nbMessages,
                                       typeUser: msg.typeUser
                                      }">


            </ng-container>


        </mat-card-content>
        <mat-card-footer class="input-area align-items-center">
            <ng-container class="input-area" [ngTemplateOutlet]="inputTemplate">
            </ng-container>
        </mat-card-footer>
    </ng-container>

    <!-- <p classe ="loading" *ngIf ="collapsed">{{sender}}</p> -->

    <ng-template #defaultMsgTemplate let-text="text" let-object="object" let-url="url" let-location="location" let-data="data" let-option="option" let-image="image" let-sendBy="sendBy" let-video="video" let-num="num" let-time="time" let-index="index" let-nbMessages="nbMessages"
        let-typeUser="typeUser">
        <chat-msgemer [msg]="{
      sendBy: sendBy,text: text , image :image , data:data, option:option , url:url , location:location , video :video , num :num , time:time, index:index, nbMessages:nbMessages, typeUser:typeUser}" [sender]="sender"></chat-msgemer>
    </ng-template>
    <ng-template #defaultInputTemplate>
        <chat-inputemer (change)="onChange($event.target);"></chat-inputemer>
    </ng-template>
</mat-card>
export class Iframe{
  cmd_item_picture_input                          : boolean  = false;
  cmd_share_position                              : boolean  = false;
  item_picture_input                              : string   = 'item_picture_input';
  share_position                                  : string   = 'share_localisation';
  option_chat                                     : string   = 'option_chat';
  cmd_count_finder_chattimer_select_finder_option : boolean  = false;
  finder_chattimer_skip_user_input                : boolean  = false;
  chat_skip_user_input                            : string   = 'chat_skip_user_input';
  finder_chattimer_skip_video_audio               : boolean  = false;
  skip_video_audio                                : string   = 'skip_video_audio';
  cmd_video_end                                   : boolean  = false;
  chat_cmd_video_end                              : string  = 'cmd_video_end';

}

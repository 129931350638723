import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/chat/data.service";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
class data {
  showMe;
  loading;
  chatvideo ; 
  urlSafe;
}
class Videocall{
  meeting_url ;
}
@Component({
  selector: 'ngbd-popupconfid-content',
  styleUrls: ['./popupconfid.component.css'],
  templateUrl: './popupconfid.component.html',
 
})
export class NgbdPopupconfidContent {
  public color="#04A6BC";
 
  showMe :boolean;
  chatvideo  :boolean;
  public event: EventEmitter<any> = new EventEmitter();
  loading :boolean;
  datamodel = new  data ; 
  constructor(public dataservice :DataService,public sanitizer: DomSanitizer) {
  }
  ngOnInit(){

   
  }
 
 
  public closemodel()
  { this.event.emit( this.datamodel);

  }
 
}
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  landingpagePath: string;
  toggleLearnMore: boolean= false;
  toggleHaveApp: boolean= false;
  toggleDontHaveApp: boolean= false;

  constructor(private router: Router, private activatedRoute:ActivatedRoute) { }

  ngOnInit(): void {

    this.landingpagePath = localStorage.getItem('landing_page')

    if(localStorage.getItem('landing_page') == null || localStorage.getItem('landing_page') == '') this.router.navigate(['']);
  }

  getEmitFormValue(value: boolean):void{
    if(value) {
      this.toggleHaveApp  = false;
      this.toggleLearnMore = true;
      this.scrollToMoreInfo()
    }
  }

  scrollToMoreInfo() {
    /* const element = document.getElementById('learnMore');
    element?.scrollIntoView()
    const offset = 160;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset; */

    window.scrollTo({
      top: 320,
      behavior: 'smooth',
    });
  }

}

import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UpdateFinderInformation } from './../../models/chatbotmessage';
import { OneSignal } from 'onesignal-ngx';
import { JoinRoomMessage, SocketService} from './../../services/socket/socket.service';
import { User, FinderInfo } from './../../models/user.model';
import { Component, OnInit, ViewChild, ElementRef, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import icflech from '@iconify/icons-ic/keyboard-arrow-right';
import arrowDown from '@iconify/icons-ic/expand-more';
import { AuthService } from '../../services/auth/auth.service';
import {  map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RouteStateService } from 'src/app/services/RouteStateService';
import { Languages } from 'src/app/models/languages';
import { SharedData } from 'src/app/models/sharedData.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/chat/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Pet } from 'src/app/models/user.model';
import { NgbdPopupmodelDocContent } from '../listmodal/popupmodeldoc/popupmodeldoc.component';
import { ReCaptcha2Component, ReCaptchaV3Service } from 'ngx-captcha';

class Features {
  medical_ai_chat: number;
  object_ai_chat: number;
  pet_ai_chat: number;
}

class Redirect {
  is_sfp: boolean;
  to:     string;
}

class Login{
  error;
  redirect: Redirect = new Redirect();
  message;
  chat_members :[];
   pet : Pet ;
  tag_info:{tag_info:{active_emergency : number;emergency:number ;id_tag:number ;}
            features: Features;}
  tag_member:{tag_creator_first_name:string;}
  tag_creator:{tag_creator_id:string;}
  finder_info: FinderInfo;
  mkg_url: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('clickOpenUrl') clickOpenUrl: ElementRef;
  icflech= icflech;
  loader_continue : boolean =false ;
  arrowDown= arrowDown;
  inputType = 'text';
  siteKey ="6Lc01mEeAAAAAD8warxraDxXlMjhYI7FIfGtCRBI" ;
  form!: FormGroup;
  captchaid:string ='' ;
  private destroy = new Subject<void>();
  login = new Login();
  SerialNumberInput: string ;
  languages = new Languages;
  langnum : string ;
  lang: string ;
  showcaptcha :boolean = true ;
  nvbessai : number =0;
  @ViewChild('alert', { static: true }) alert: ElementRef;
  captcha : string = '';
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;room: string;
  message: string;
  idDevice: string = null;
  pathname: string;
  tagsList = ["/HMRKEKG", "/MPTJTNP"]

  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    private sharedData : SharedData,
    private translate : TranslateService,
    private socketService: SocketService,
    private routeStateService :RouteStateService,
    private activatedRoute: ActivatedRoute,
    private authService :AuthService,private dataservice :DataService,
    private router :Router,
    private oneSignal: OneSignal,
    private sanitizer: DomSanitizer,
    private httpClient: HttpClient,
    private fb: FormBuilder,private modalService: NgbModal)
    {
      this.pathname = document.location.pathname;
     this.activatedRoute.fragment.subscribe(
        value =>{
          this.SerialNumberInput= value
        } ) ;
      if (!this.SerialNumberInput)
      this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.SerialNumberInput = params.get('serialnumber');

    });
    if(this.SerialNumberInput && this.SerialNumberInput != 'pj' && this.SerialNumberInput != 'sj' && !this.tagsList.includes(this.pathname)) this.navigate()
    else this.SerialNumberInput = null;
    this.sharedData.initSharedData();
   }

   closeAlert() {
    this.alert.nativeElement.classList.remove('show');
  }
   ngOnInit() {
    this.form = this.fb.group({
      SerialNumberInput:this.SerialNumberInput? this.SerialNumberInput:['', Validators.required],
      recaptcha: ['', Validators.required]

    });
    this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
    }, {
        useGlobalDomain: false
    });
    this.activatedRoute.paramMap.pipe(map(paramaMap=> paramaMap.get('serialnumber')),
         takeUntil(this.destroy)).subscribe(routePathParam => this.routeStateService.updatePathParamState(routePathParam));
         this.langnum =localStorage.getItem('browserlang');
  }

  ngOnDestroy(){
    this.destroy.next();
    this.destroy.complete();
    this.routeStateService.updatePathParamState(null);
  }

  goToHomePage() {
      this.router.navigate(['lost_or_found']);
  }





  handleSuccess(event){
    this.captchaid=event ;
  }


  navigate() {
    localStorage.setItem('ischatUrl', '');
   /*  if(this.SerialNumberInput == 'pj'){
      //window.open(this.pjUrl, '_blank');

      this.SerialNumberInput = '';
    }else if(this.SerialNumberInput == 'sj'){
     // window.open(this.sjUrl, '_blank');
      this.SerialNumberInput = '';
    }else{ */
      if(localStorage.getItem('idDevice')){
        this.idDevice = localStorage.getItem('idDevice');
      }


      let serialNumberLocal: string = localStorage.getItem("SerialNumber");
      const oldChatbot = localStorage.getItem("oldChatbot");
      if(serialNumberLocal !== null){
        if(this.SerialNumberInput.toUpperCase() !== serialNumberLocal.toUpperCase()){
          localStorage.clear();
          localStorage.setItem("oldChatbot", oldChatbot);
          localStorage.setItem("sessionid","undefined");
        }
      }

      localStorage.setItem('idDevice', this.idDevice);

      localStorage.removeItem('jobpage');
      localStorage.setItem('isOwnerReply',"false")

      this.nvbessai= this.nvbessai +1;
      localStorage.setItem("SerialNumber",this.SerialNumberInput);
      this.loader_continue = true ;
      if (this.SerialNumberInput)
      {
        const currentUser: User = JSON.parse( localStorage.getItem('currentUser'));
        let user_id = null;
        if (currentUser !== null && currentUser?.finder_info?.id !== null){
          user_id = currentUser?.finder_info?.id;
          localStorage.setItem('oldUser', currentUser?.finder_info?.id)
        }

          this.authService.login(this.SerialNumberInput.toUpperCase().trim(), this.idDevice, user_id, ).
          subscribe((data :Login)=>{
            if(data?.error == true && data?.mkg_url){
              //window.open(data?.mkg_url,'_blank')
              var form = document.createElement("form");
              form.target = "_self";
              form.method = "POST";
              form.action = data?.mkg_url;
              form.style.display = "none";

              for (var key in data) {
                  var input = document.createElement("input");
                  input.type = "hidden";
                  input.name = key;
                  input.value = data[key];
                  form.appendChild(input);
              }

              document.body.appendChild(form);
              form.submit();
              document.body.removeChild(form);

              this.loader_continue = false
              this.SerialNumberInput = '';
            }else{
              if(data?.error == false && data?.redirect?.is_sfp == true){
                localStorage.setItem('landing_page', data?.redirect?.to);
                this.router.navigate(['/landing-page']);
              }
              else if(data?.error == false){
                let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
                updateFinderInformation.id_device = this.idDevice;
                this.dataservice.updateUser(data?.finder_info?.id, updateFinderInformation).pipe()
                  .subscribe((data)=>{

                  },error=>{
                  });
              }

                  localStorage.setItem('oldUser', data?.finder_info?.id)


                  if (  this.nvbessai >= 4 && this.nvbessai < 10)
                  {


                  this.showcaptcha = false ;
                  }
                  else  if (  this.nvbessai === 10 )

                    this.authService.getIPAddress().subscribe((res:any)=>{
                      this.authService.blocip(res.ip).subscribe((res:any)=>{
                        //if ( res.error === false )
                        this.open("votre adress ip va etre blocker ")
                      });

                    });

                  this.login = data;
                  this.loader_continue = false ;
                  if (!this.showcaptcha && this.captchaid === '')
                  {
                    this.open("finder_response_checkbox")
                    return ;
                  }
                  if(this.login.error=== true)
                  {
                    this.open("finder_response_wrongSN")

                  }
                  else if ( data.tag_info!= null && data.tag_info!= undefined)
                  {
                    if (data.tag_info?.tag_info != null )
                    for (let j = 0; j < data.chat_members?.length; j++)
                          if (data.tag_info?.tag_info.active_emergency=== 1 && data.tag_info?.tag_info?.emergency=== 1)
                          {
                            if(data.tag_info.features?.object_ai_chat ===1)
                            this.router.navigate(['/' +this.SerialNumberInput +'/lost_or_found']);
                            else if(data.tag_info.features?.object_ai_chat ===0)
                            this.router.navigate(['/' + this.SerialNumberInput +'/homepage']);
                          }
                          else
                          {
                            if ( data.pet.general_info != undefined)
                            localStorage.setItem('objectjob','pet')
                            else
                            localStorage.setItem('objectjob','object')
                            if ( data.tag_info.features?.object_ai_chat ===1){
                              if ( data?.pet?.general_info){
                                localStorage.setItem('oldChatbot','pet')
                                localStorage.setItem('currentChatbot','pet')
                              }else{
                                localStorage.setItem('oldChatbot','object')
                                localStorage.setItem('currentChatbot','object')
                              }

                              this.router.navigate(['/' + this.SerialNumberInput +'/chathome']);
                              this.authService.sendsmsmail(this.SerialNumberInput)
                              .subscribe();
                              break;
                            }
                            else
                            this.router.navigate(['/' + this.SerialNumberInput +'/homepage']);
                          }

                         // this.joinRoom(data.finder_info.id, 'finder',this.SerialNumberInput)
                  }
            }
          }, error=>
          {
                   this.loader_continue = false ;
                  this.open("finder_response_servererror")
         }
        ) , error=>
        { this.loader_continue = false ;
          this.open("finder_response_servererror")
        }
     }
    //}
  }

   generateLandingpageAccess(length: number) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters?.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    }

  open(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'sm',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "myCustomModalClass",

    };

    const modeldoc =this.modalService.open(NgbdPopupmodelDocContent,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.texte="finder_response_titleerror";
    modeldoc.componentInstance.color="#F4C809";
    modeldoc.componentInstance.type="error";
    modeldoc.componentInstance.event.subscribe((data:any) => {

      modeldoc.close();
    });
  }

  joinRoom(user_id?, user_type?, room?) {
    const joinMessage: JoinRoomMessage = {
      id_user: user_id,
      user_type: user_type,
      username: 'Finder',
      room: room,
    };
   // localStorage.setItem('current_chat_type', 'finder')
    this.socketService.joinRoom(joinMessage)
  }

}

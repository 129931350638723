import { User } from 'src/app/models/user.model';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { Iframe } from './../../../models/Iframe';
import { TranslateService } from '@ngx-translate/core';
import { SocketService, OutGoingMessage, JoinRoomMessage, LeaveRoomMessage } from './../../../services/socket/socket.service';
import { UpdateFinderInformation } from './../../../models/chatbotmessage';
import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild,Renderer2} from '@angular/core';
import { ChathomeComponent } from '../chathome/chathome.component';
import {DataService} from '../../../services/chat/data.service';
import iclocation from '@iconify/icons-ic/outline-place';
import { ChatService } from '../../../services/chat/chat.service';
import { ClipboardService } from 'ngx-clipboard';
import { MapsAPILoader} from '@agm/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Message } from 'src/app/models/message';
import {  Generic } from '../../../models/chatbotmessage';
import { Observable, interval } from 'rxjs';
import { DOCUMENT, DatePipe } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ESendBy } from 'src/app/models/EsendBy';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ContactInfoDialog } from '../../listmodal/contact-info-dialog/contact-info-dialogcomponent';
import { LoaderService } from 'src/app/services/loader/loader.service';

declare const L: any;
class Position{
  coords :Coords
}
class Coords{
  longitude ;
  latitude ;
}

@Component({
  selector: 'chat-msg',
  templateUrl: './chat-msg.component.html',
  styleUrls: ['./chat-msg.component.scss'],
})
export class ChatMsgComponent implements OnInit {
  @Input() msg: Message;
  @Input() sender:string;
  @Input() collapsed: any;
  collapsedimage : boolean=false;
  collapsedmap = false;
  @Output() onMsgReceive = new EventEmitter();
  @Input() collapsedUser: boolean = false ;
  lat :string ='';
  lng:string ='';
  location :Object;
  iclocation =iclocation;

  @Output() target: any =Event;
  @ViewChild('notbot') notbot:ElementRef;
  @ViewChild('notbotoption0') notbotoption0:ElementRef;
  @ViewChild('notbotoption1') notbotoption1:ElementRef;
  @ViewChild('notbotoption2') notbotoption2:ElementRef;
  @ViewChild('notbotoption3') notbotoption3:ElementRef;

  @Output() change = new EventEmitter();
  sendlocation:boolean = false ;
  hidden:boolean = false ;
  public gmap: ElementRef;
  clickedButton: boolean = false;
  @ViewChild('gmap', { static: false }) set content(content: ElementRef) {
    if(content) {
        this.gmap = content;
    }
  }
  locationmap  :boolean=false ;
  currentlanguage : any ;
   map:any;
   currentLong: any;
   loader: boolean = true;
   marker: google.maps.Marker;
   mesg :Message ;
   position : Position ;
   srcvideo : SafeUrl ;
   public messagedata:Generic ;
   Currentposition : boolean=false ;
   center: google.maps.LatLngLiteral;
   public isVisible = true;
   originalmsg:boolean = true ;
   translatedmsg:boolean = false ;
  message : string ;
  phoneNumber: string = '';
  phoneName: string = '';
  currentUser: User = JSON.parse(localStorage.getItem('currentUser'));
  permissionLocationNotAllowed:boolean = false;


  constructor(@Inject(DOCUMENT) private document: Document,private clipboardService: ClipboardService,private authService :AuthService , private mapsAPILoader: MapsAPILoader,public dataService: DataService
  ,public chatservice :ChatService,private sanitizer: DomSanitizer,private renderer:Renderer2 ,public ChathomeComponent :ChathomeComponent, private router: Router, private loaderService: LoaderService,
  private socketService: SocketService, private translateService: TranslateService, private datePipe: DatePipe, private _sharedService: SharedService,private _modalService: NgbModal
  ) {
  }

  convertEmbededVideo ( video)
  {
    let source: string = video.replace('https://www.youtube.com/watch?v=' , '')
    if(source.includes('&'))
    source = source.replace(source.substring(source.indexOf('&')),'')
    this.srcvideo =this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+source) ;
  }
  ngOnInit() {
    //if(localStorage.getItem('lastMessageSent') == this.msg?.text) this.msg = null;
   /*  navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("longitude", position.coords.longitude?.toString());
      localStorage.setItem("latitude",position.coords.latitude?.toString());
    }); */

    // Clear the longitude and latitude every minute
    if(this.msg?.sendBy == 'server'){
      this.clickedButton = true;
    }
    //console.log(this.msg)
    if(this.msg?.data?.text?.includes('cmd_call_owner')){
      this.phoneNumber = this.msg?.data?.text.substring(this.msg?.data?.text.indexOf(']')+1).trim();
      this.phoneName = this.msg?.data?.text.substring(0,this.msg?.data?.text.indexOf('[')).trim();
    }
      setTimeout(() => {this.isVisible = true}, 0);
      this.currentlanguage= this.authService.currentLanguagesValue ;
      if (this.msg?.data?.response_type != null && this.msg?.data?.response_type.includes("video" )){
        this.convertEmbededVideo(this.msg?.data.source)
      }else if ((this.msg?.video != null)){
        this.convertEmbededVideo(this.msg?.video)
      }
      if ( this.msg.location != null){
        if(this.msg?.sendBy != 'user'){
          let position = new Position ;
          position.coords = new Coords ;
          position.coords.longitude = this.msg.location.split(',')[1];
          position.coords.latitude =this.msg.location.split(',')[0];
            this.initmapOwner(position.coords.longitude, position.coords.latitude)
        }
        else if( !localStorage.getItem("longitude") && !localStorage.getItem("latitude"))
        navigator.geolocation.getCurrentPosition((position) => {
          localStorage.setItem("longitude", position.coords.longitude?.toString());
          localStorage.setItem("latitude",position.coords.latitude?.toString());
          this.initmap();
        },(error)=>{
          this.permissionLocationNotAllowed = true;
        });
        else this.initmap();
      }
      setTimeout(async () => {

        let a = this.document.getElementById('msgArea');
        if(a)
        {
          a.scrollTop = a.scrollHeight;
        }

      },4000);


      this.collapsed = this.dataService.collapsedUser ;
      var userLang: string = navigator.language;
      if(userLang.toLowerCase() != 'en'){
        this.loaderService.hide();
        this.translationmessage(this.msg, this.msg?.data?.options)
      }

  }

  getPhoneTrusted(phone: string): SafeUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(phone) ;
  }

  trustUrl(url: string): SafeUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.removeHtmlCodeFromText(url)) ;
  }

  firstRemoveClass: boolean = true;
  translationmessage(msg : any, option?: any){
    let text = '';
    if(msg?.text){
      if(msg?.text.includes('[pincode]:')){
        this.translateService.get('finder_emergencycontact_release_msginfo').subscribe((message: string) => { text = message })
      }
      else
      text = msg?.text
    }else if(msg?.data?.title || msg?.data?.text){
      text = msg?.data?.title  || msg?.data?.text
    }
    if(text){
      if(text.includes('[picture_icon]')) text = text.replace('[picture_icon]', '')
      if(text.includes('[location_icon]')) text = text.replace('[location_icon]', '')
      if(text.includes('[reward_icon]')) text = text.replace('[reward_icon]', '')
      this.authService.translationMessage(text).subscribe((res:any)=>{
        this.loaderService.hide();
        if(!res?.translation?.translations || res?.translation?.translations?.length > 0){
          if (!res?.translation?.translations || !res?.translation?.translations[0]?.translation ){
            this.originalmsg = false ;
            this.translatedmsg=true;
            return;
          }
        }
       this.notbot.nativeElement.classList.add('translateMsg');
       const span = this.renderer.createElement('span');
       span.setAttribute("id", "idTraduction");

     if(this.firstRemoveClass){
       if(this.notbot?.nativeElement?.firstChild?.id == 'originalMessage'){
        this.notbot.nativeElement.firstChild.style.display = 'none';
        this.firstRemoveClass = false;
       } else if(this.notbot?.nativeElement?.firstElementChild?.id == 'originalMessage' ){
          this.notbot.nativeElement.firstElementChild.style.display = 'none';
          this.firstRemoveClass = false;
       }

     }
     let translatedText: string = res.translation?.translations[0]?.translation
     /* res.response.result.data.forEach(element => {
      translatedText += " " + element
     }); */
     const text = this.renderer.createText(translatedText);
     this.renderer.appendChild(span, text);
     //this.renderer.appendChild(this.notbot.nativeElement, span);
     this.renderer.insertBefore(this.notbot.nativeElement, span, this.notbot.nativeElement.firstChild);
     this.originalmsg = false ;
     this.translatedmsg=true;
     });
    }
    if(option?.length>0){
      this.translationmessageOption(option);
    }
  }

  Seeoriginal(msg: any, options?: any){
    const childElements = this.notbot.nativeElement.children;
    for (let child of childElements) {
      if(child.id  == 'idTraduction' || child.id  == 'originalMessage')
      this.renderer.removeChild(this.notbot.nativeElement, child);
    }
    let textMessage = "";
    this.notbot.nativeElement.classList.remove('translateMsg');
    let text;
    const balisep = this.renderer.createElement('p');
    balisep.setAttribute("id", "idTraduction");

    textMessage = msg?.text || msg?.data?.title;
    if(textMessage.includes('[picture_icon]')) textMessage = textMessage.replace('[picture_icon]', '')
    if(textMessage.includes('[location_icon]')) textMessage = textMessage.replace('[location_icon]', '')
    if(textMessage.includes('[reward_icon]')) textMessage = textMessage.replace('[reward_icon]', '')

    if(msg.text != null){
      if(msg?.text.includes('[pincode]:')){
        this.translateService.get('finder_emergencycontact_release_msginfo').subscribe((message: string) => {text = this.renderer.createText(message)})
      }
      else
      text = this.renderer.createText(textMessage)
    }
    else if(msg?.data?.title != null)
    text = this.renderer.createText(textMessage)



    this.renderer.appendChild(balisep,text);
    //this.renderer.appendChild(this.notbot.nativeElement, balisep);
    this.renderer.insertBefore(this.notbot.nativeElement, balisep, this.notbot.nativeElement.firstChild);

    this.originalmsg = true ;
    this.translatedmsg=false;

    if(options?.length>0){
      this.SeeoriginalOption(msg,options);
    }
  }

  firstRemoveClassOption: boolean = true;
  translationmessageOption(options?: any){
    for (let index = 0; index < options.length; index++) {
      const option = options[index];

      let text = option.label;

      let notbotoption;
      setTimeout(() => {
        switch (index) {
          case 0 : notbotoption = this.notbotoption0;break;
          case 1 : notbotoption = this.notbotoption1;break;
          case 2 : notbotoption = this.notbotoption2;break;
          case 3 : notbotoption = this.notbotoption3;break;

          default:
            break;
        }
      });

      //if(text == 'No') return;

      if(text){
        if(text.includes("FOUND ME")) text = text.replace("FOUND ME", "");
        this.authService.translationMessage(text).subscribe((res:any)=>{
        if(!res?.translation?.translations || res?.translation?.translations?.length > 0){
          if (!res?.translation?.translations || !res?.translation?.translations[0]?.translation ){
            this.originalmsg = false ;
            this.translatedmsg=true;
            return;
          }
        }

      notbotoption?.nativeElement.classList.add('translateMsgOption'+index.toString());
      const span = this.renderer.createElement('span');
      span.setAttribute("id", "idTraduction"+index.toString());

      if(this.firstRemoveClassOption){
        if(notbotoption?.nativeElement?.firstChild?.id == 'originalMessageOption'+index.toString()){
          notbotoption.nativeElement.firstChild.style.display = 'none';
        }
        //if(notbotoption?.nativeElement?.children?.length>0 && notbotoption?.nativeElement?.children[1]?.id == 'originalMessageOption'+index.toString() && notbotoption?.nativeElement?.children[1]?.localName == 'span'){
          let stl = notbotoption?.nativeElement.querySelector('#originalMessageOption'+index.toString());
          if(stl)
          stl.style.display = 'none';
        //}

      }
      let translatedText: string = res.translation?.translations[0]?.translation
      if(option.label.includes("FOUND ME")) translatedText = translatedText + " FOUND ME"
      /* res.response.result.data.forEach(element => {
        translatedText += " " + element
      }); */
      const text = this.renderer.createText(translatedText);
      this.renderer.appendChild(span, text);
      //this.renderer.appendChild(this.notbot.nativeElement, span);
      if(notbotoption?.nativeElement)
      this.renderer.insertBefore(notbotoption.nativeElement, span, notbotoption.nativeElement.children[1]);
      this.originalmsg = false ;
      this.translatedmsg=true;
      },(error)=>{
        console.log(error);

      });
      }
    }
  }

  SeeoriginalOption(msg: any, options?: any){
    for (let index = 0; index < options.length; index++) {
      const option = options[index]
      let notbotoption;

      switch (index) {
        case 0 : notbotoption = this.notbotoption0;break;
        case 1 : notbotoption = this.notbotoption1;break;
        case 2 : notbotoption = this.notbotoption2;break;
        case 3 : notbotoption = this.notbotoption3;break;

        default:
          break;
      }

      let isFound: boolean = false;
      const childElements = notbotoption.nativeElement.children;
      for (let child of childElements) {
        if(child.id  == 'idTraduction'+index.toString() ){
          this.renderer.removeChild(notbotoption.nativeElement, child);
          isFound = true;
        }
      }

      if(!isFound)
      return;

      notbotoption.nativeElement.classList.remove('translateMsgOption'+index.toString());
      let text;
      const balisep = this.renderer.createElement('p');
      balisep.setAttribute("id", "idTraduction"+index.toString());

      text = this.renderer.createText(option.label)
      this.renderer.appendChild(balisep,text);
      //this.renderer.appendChild(this.notbot.nativeElement, balisep);
      this.renderer.insertBefore(notbotoption.nativeElement, balisep, notbotoption.nativeElement.children[1]);

      this.originalmsg = true ;
      this.translatedmsg=false;
    }
  }


  @HostListener('window:beforeunload', ['$event'])
  canLeavePage($event: any): Observable<void> {
    return null;
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

   delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  initmap(){
    const latitude: number = parseFloat( localStorage.getItem('latitude'));
    const longitude: number = parseFloat( localStorage.getItem('longitude'));
      this.mapsAPILoader.load().then(() => {
        var mapProp = {
          center: new google.maps.LatLng(latitude, longitude, false),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
          if (this.gmap!= null)
          {
            this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
            if( this.msg.sendBy === ESendBy.user)
            this.showPosition(latitude, longitude);
            else
            {
            this.position = new Position ;
            this.position.coords = new Coords ;
            this.position.coords.longitude = this.msg.location.split(',')[1];
            this.position.coords.latitude =this.msg.location.split(',')[0];

            this.trackposition( this.position.coords.longitude, this.position.coords.latitude);
            }
          }
      });
  }

  initmapOwner(longitude, latitude){
      this.mapsAPILoader.load().then(() => {
        var mapProp = {
          center: new google.maps.LatLng(latitude, longitude, false),
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
          if (this.gmap!= null)
          {
            this.map = new google.maps.Map(this.gmap.nativeElement, mapProp);
            if( this.msg.sendBy === ESendBy.user)
            this.showPosition(latitude, longitude);
            else
            {
            this.position = new Position ;
            this.position.coords = new Coords ;
            this.position.coords.longitude = this.msg.location.split(',')[1];
            this.position.coords.latitude =this.msg.location.split(',')[0];

            this.trackposition( this.position.coords.longitude, this.position.coords.latitude);
            }
          }
      });
  }

ngOnDestroy(){
}

isNumber(value) {
  if (Number(value))
  return true;
  return false ;
}

copyContent(val) {
     this.clipboardService.copyFromContent(val.value)
}

selectOption(val: any){

    let label = val.label;
    let message =val.value?.input?.text;
    if(message == "option_share_contact" /* || message == "option_leave_item"*/){
      this.openContactInfoDialog("")
      return;
    }
    this.clickedButton = true;

    this.target.type = "text";
    if ( message === "share_localisation"){
      if( !localStorage.getItem("longitude") && !localStorage.getItem("latitude"))
      navigator.geolocation.getCurrentPosition((position) => {
        localStorage.setItem("longitude", position.coords.longitude?.toString());
        localStorage.setItem("latitude",position.coords.latitude?.toString());
        this.msg.location = 'map';
        localStorage.setItem("location","true");
        this.target.type = 'location';
        this.target.label ='yes';
        this.ChathomeComponent.onChange(this.target);
      })
      else{
        this.msg.location = 'map';
        localStorage.setItem("location","true");
        this.target.type = 'location';
        this.target.label ='yes';
        this.ChathomeComponent.onChange(this.target);
      }
    }
    else if(message === "cmd_video_call"){
      this.startVideoCall();
    }
    else{
    this.target.label =label;
    }
    this.target.value=message;
    if (message !== "share_localisation")
    this.ChathomeComponent.onChange(this.target);
  }

  openContactInfoDialog(content) {
    let options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      centered :true,
      animation :true,
      backdrop: true,
      keyboard: true,
     windowClass : "contact-info-modal",

    };

    const modeldoc =this._modalService.open(ContactInfoDialog,options);
    modeldoc.componentInstance.chatinfo = true;
    modeldoc.componentInstance.number = 1;
    modeldoc.componentInstance.title =content;
    modeldoc.componentInstance.texte="finder_response_titleerror";
    modeldoc.componentInstance.color="#04a6bc";
    modeldoc.componentInstance.type="error";

    modeldoc.componentInstance.event.subscribe((data?:UpdateFinderInformation) => {
      const email = data?.email;
      const firstName = data?.first_name;
      const lastName = data?.last_name;
      const phone = data?.phone;
      const note = data?.note;
      const messageContent =  `Finder has shared his informations:\n

      ${email ? `Email: ${email}. \n` : ''}
      ${firstName ? `First name: ${firstName}. \n` : ''}
      ${lastName ? `Last name: ${lastName}. \n` : ''}
      ${phone ? `Phone number: ${phone}. \n` : ''}
      ${note ? `Note: ${note}.` : ''}`
      this.onSendMessage(messageContent);

      let newMessage: Message = new Message(messageContent, 'user',null,null, null, null, null, null, null)
      this.dataService.update(newMessage);
      this.dataService.Sendmessage(messageContent).subscribe(()=>{});

      modeldoc.close();
    });
  }

public changeinput(target: any) {
    this.target.type = "text";
}


findMe() {
  this.hidden = true ;
    localStorage.setItem("location","false");
    /* if (navigator.geolocation ) {

      navigator.geolocation.getCurrentPosition((position) => {
        this.hidden = false ;
        this.showPosition(position.coords.latitude, position.coords.longitude);
      },
      error => {

        this.msg.location =null ;
      }
       );
    }
    else
    {
      this.hidden = false ;
      // alert("Geolocation is not supported by this browser.");
    } */



  }
  trackposition(longitude, latitude) {
    this.locationmap = true ;
    //this.Currentposition=false;
    this.moveToLocation(latitude, longitude);
}

onSendMessage(text){
  const currentUser = this.authService.currentUserValue;

  const message: OutGoingMessage = {
    room: currentUser?.tag_info?.tag_info?.serial_number,
    message: text,
  };
  this.socketService.sendMessage(message);
}

 showPosition(lat: number, long: number) {
    this.locationmap = true ;

    let dateString = this.datePipe.transform(new Date(), 'hh:mm a');
    this.moveToLocation(lat, long);

    if ( this.msg.time=== dateString)
    {
      let updateFinderInformation: UpdateFinderInformation = new UpdateFinderInformation();
      updateFinderInformation.lng = long;
      updateFinderInformation.lat = lat;
      const currentUser = this.authService.currentUserValue;
      /* this.dataService.updateUser(
        currentUser.finder_info.id,
        updateFinderInformation
        ).pipe()
      .subscribe(); */
      //if (!this.Currentposition){
       // this.onSendMessage("[position]:"+lat+","+long)
       /*  this.dataService.Sendmessage("[position]:"+lat+","+long).subscribe(
        (data :any)=>{}); */
      //}
    }
    //this.Currentposition=false;

  }


    moveToLocation(latitude , longitude){
    let location = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude) );
    this.mapsAPILoader.load().then(() => {
     if (this.map!= undefined )
      this.map.panTo(location);
    });
    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
   }
   else {
    this.marker.setPosition(location);
   }
  }

  trackPosition(position) {
      let liststring = position?.toString().split(",");
      this.moveToLocation(liststring[0],liststring[1]);;

  }

  onClick(event) {
  }

  onChangesTextColor(text: string): string{
    let foundcom: string = 'FOUND ME.com';
    let confidentialchat: string = 'confidential chat';
    let email: string = 'email';
    let name: string = 'name';
    let phone: string = 'phone';
    let nameofestablishment: string = 'name of establishment';
    let nameofperson: string = 'name of person';
    let location: string = 'share location';
    let confidential: string = 'confidential';
    let chat: string = 'chat';

    if(text?.includes(confidential) && text?.includes(chat)){
      let newText: string = '';
      newText = text.replace(new RegExp(confidential, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });

      newText = newText.replace(new RegExp(chat, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
      return newText
    }

    else if(text?.includes(chat)){
      return text.replace(new RegExp(chat, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
    });
    }

    else if(text?.includes(confidential)){
      return text.replace(new RegExp(confidential, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
    });
    }

    if(text?.includes(foundcom)){
      return text.replace(new RegExp(foundcom, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
    });
    }
    else if(text?.includes(confidentialchat)){
      return text?.replace(new RegExp(foundcom, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(email)){
      return text?.replace(new RegExp(email, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(name)){
      return text?.replace(new RegExp(name?.toLowerCase(), "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(phone)){
      return text?.replace(new RegExp(phone, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(nameofestablishment)){
      return text?.replace(new RegExp(nameofestablishment, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(nameofperson)){
      return text?.replace(new RegExp(nameofperson, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else if(text?.includes(location)){
      return text?.replace(new RegExp(location, "gi"), match => {
        return '<span class="color-change"><strong>' + match + '</strong></span>';
      });
    }
    else{
      return text;
    }
  }

  removeHtmlCodeFromText(text: string): string{
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  }

  convertingCommandsToText(commands): string{
    if(commands?.includes('<a href="http://www.foundme.com">www.foundme.com</a>'))
    return commands;
    let returnedText: string = '';
    let splitedList: any[] = commands?.split(' ');
    for (let index = 0; index < splitedList?.length; index++) {
      const element:string = splitedList[index];

      if(element.includes('chatbot_script')){
        returnedText += ' ';
        this.translateService.get(element).subscribe((text: string) => {returnedText += this.onChangesTextColor(text)})
      }else{
        returnedText += ' ';
        returnedText += element
      }
    }

    return this.removeHtmlCodeFromText(returnedText.trim());

  }

  startVideoCall(): void{
    let message: any;
    this._sharedService.startVideoCall('finder')
    .subscribe((response)=>{
       message = {
        'value': response?.data?.roomUrl,
        'type': 'url'
      };
      this.dataService.converse(message, 'finder');

    }, error=>{
       message = {
        'value': error?.error?.data?.error,
        'type': 'text'
      };
      this.dataService.converse(message, 'finder');
    })
  }
}








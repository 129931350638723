import { InfosComponent } from './Pages/success/infos/infos.component';
import { ResetPasswordComponent } from './Pages/reset-password/reset-password.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from "./Pages/home/home.component";
import {LostOrFoundComponent } from "./Pages/lost_or_found/lost-or-found.component";
import { ChathomeComponent } from "./Pages/normalchat/chathome/chathome.component";
import { ChatUrlComponent } from "./Pages/emegencychat/chatUrl/chatUrl.component";
import { ChatviewComponent } from "./Pages/normalchat/chatview/chatview.component";
import { HomepageComponent } from './Pages/freemiumhomepage/homepage.component';
import { SuccessComponent } from './Pages/success/success/success.component';
import { LandingPageComponent } from './Pages/landing-page/landing-page.component';
import { MoreInformationsComponent } from './Pages/more-informations/more-informations.component';
import { PetLandingpageComponent } from './Pages/pet-landingpage/pet-landingpagecomponent';
import { ObjectScannedComponent } from './Pages/object-scanned/object-scanned.component';
import { MedicaleObjectComponent } from './Pages/medicale-object/medicale-object.component';

const routes: Routes = [
    {
    path: '',
    component:HomeComponent,
  },
  {
    path:"landing-page",
    component:LandingPageComponent
  },
/*   {path:"object_landing_page",component:ObjectScannedComponent},
  {path:"medical_landing_page",component:MedicaleObjectComponent},
{path:'more_information',
component:MoreInformationsComponent},
{path:'pet_landing_page',
component:PetLandingpageComponent}, */
  {
    path: 'home',
    component:HomeComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent
  },
  {
    path: 'chatUrl/:idParam',
    component:ChatUrlComponent,
  },
  {
    path: 'infos/:code/:name/:email',
    component:InfosComponent,
  },
  {
    path: 'success/:name/:email',
    component:SuccessComponent,
  },
  {
    path:  '#:serialnumber',
    component: HomeComponent,
  },
  {
    path:  ':serialnumber',
    component: HomeComponent,
  },
  {
    path: ':serialnumber/chathome',
    component: ChathomeComponent,
  },
  {
    path: ':serialnumber/chatview',
    component: ChatviewComponent,
  },
  {
    path: ':serialnumber/lost_or_found',
    component: LostOrFoundComponent,
  },
  {
    path: ':serialnumber/homepage',
    component: HomepageComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,
   { initialNavigation: 'enabled',
    paramsInheritanceStrategy: 'always',scrollPositionRestoration: 'enabled'}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }

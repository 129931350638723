export class ObjectInformation {
  currency: Currency = new Currency();
  emergency_contact_user: EmergencyContactUser[] = [];
  other_info: any[] = [];
  preference_user: { [key: string]: PreferenceUser };
  tag_info: TagInfo = new TagInfo();
  tag_user_info: TagUserInfo = new TagUserInfo();
 
}

export class Currency {
  active: any;
  allow_custum_field: any;
  allow_value: any;
  custum_field: any;
  id: any;
  id_currency_label: any;
  value: any;
}

export class EmergencyContactUser {
  active: number;
  allow__chat: any;
  allow_mail1: number;
  group: any;
  is_downgraded: number;
  allow_mail2: number;
  allow_mobile: number;
  code_phone: any;
  code_phone_country: any;
  first_name: string;
  id: number;
  last_name: string;
  mail: string;
  mail2: string;
  mobile: string;
  tel: string;
}

export class PreferenceUser {
  allow_live_chat: AllowLiveChat;
  include_child_picture: AllowLiveChat;
  allow_share_emails: AllowLiveChat;
  allow_share_name: AllowLiveChat;
  allow_share_phone: AllowLiveChat;
  allow_share_picture: AllowLiveChat;
  include_mail1: AllowLiveChat;
  include_mail2: AllowLiveChat;
  include_mobile: AllowLiveChat;
  include_child_name: AllowLiveChat;
}

export class AllowLiveChat {
  acces_label_txt: string;
  active: number;
  class_android: any;
  class_ios: any;
  class_web: any;
  id_field: number;
  id_field_app: string;
  id_field_model: any;
  id_field_type: any;
  id_form: any;
  serialization: any;
  type_field: any;
  value: string;
}

export class TagInfo {
  active: number;
  archive: number;
  emergency: number;
  id_finder_website: number;
  id_member: string;
  is_premium_limitted: any;
  id_picture: number;
  id_tag: number;
  id_tag_categorie: number;
  id_type: number;
  picture_name: string;
  picture_url: any = '';
  serial_number: string;
  tag_custum_message: string;
  tag_description: any;
  tag_label: string="";
  id_pet: any;
  active_emergency: number;
  vtt: any;
  exp_date: any;
  pin_password: any;
  features: { [key: string]: number | null };

}

export class TagUserInfo {
  first_name: string;
  id_user: string;
  last_name: string;
  mail: string;
  mail2: string;
  mobile: string;
  phone_code: string;
}
